import { FormControlLabel, Checkbox } from '@mui/material';

// used in setting driver availability

type CheckBoxTimeSlotProps = {
  timeSlot: string;
  checkValue: boolean;
  handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckBoxTimeSlot = ({
  timeSlot,
  checkValue,
  handleCheck,
}: CheckBoxTimeSlotProps) => {
  let split = timeSlot.split('');
  let label = split[0].toUpperCase() + split.slice(1).join('');

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkValue}
          onChange={handleCheck}
          value={timeSlot}
        />
      }
      label={label}
      key={label}
    />
  );
};
