import { IRoute, IStudent } from '../ts/interfaces';

import { supabase } from '../db/client';

export const getStudentInfoFromRoute = async (route: IRoute, tableName: string) => {
  let stops = route.data.slice(0, -1);
  let arrayOfStudentIDs = stops.map((student: any) => {
    return student.student_id;
  });
  let studentQueryString = '';
  for (let i = 0; i < arrayOfStudentIDs.length; i++) {
    studentQueryString += `id.eq.${arrayOfStudentIDs[i]}`;
    if (i < arrayOfStudentIDs.length - 1) studentQueryString += ',';
  }

  let { data: studentInfo, error: error2 } = await supabase
    .from(tableName)
    .select()
    .or(studentQueryString);

  if (error2)
    console.log('error in response from student info request', error2.message);

  if (studentInfo) return studentInfo;
};

export const createWaypointsArray = (route: any, position: any) => {
  let stops = route.data.slice(0, -1);

  // convert to string to check for duplicates - lat_long arrays are unique in memory even if identical
  let allWaypointsStrings = stops.map((stop: any) => {
    return JSON.stringify(stop.lat_long);
  });

  // filter duplicates
  let filteredStringArray = allWaypointsStrings.filter(
    (waypoint: string, index: number) => {
      return allWaypointsStrings.indexOf(waypoint) === index;
    }
  );

  // parse
  let uniqueWaypointsArray = filteredStringArray.map((waypoint: any) => {
    return JSON.parse(waypoint);
  });

  // start and end from last element of route.data array
  let depots = route.data[route.data.length - 1];

  uniqueWaypointsArray.push(depots.end.lat_long);
  uniqueWaypointsArray.unshift(position);
  return uniqueWaypointsArray;
};

export const getMapMarkerInfo = (route: any, studentInfoArray: IStudent[]) => {
  let stops = route.data.slice(0, -1);
  let markerInfoArray: any[] = [];
  
  for (let i = 0; i < stops.length; i++) {
    let studentInfo = studentInfoArray.filter(
      (student: IStudent) => student.id === stops[i].student_id
    )[0];
    if (studentInfo) {
      let stopInfo = {
        latLong: stops[i].lat_long,
        studentName: `${studentInfo.fname} ${studentInfo.lname}`,
        streetAddress: stops[i].street_address,
        studentLoad: 1,
      };

      let dupe = false;
      for (let j = 0; j < markerInfoArray.length; j++) {
        if (stopInfo.streetAddress === markerInfoArray[j].streetAddress) {
          markerInfoArray[j].studentLoad++;
          markerInfoArray[j].studentName += `, ${stopInfo.studentName}`;
          dupe = true;
        }
      }
      if (!dupe) {
        markerInfoArray.push(stopInfo);
      }
    }
  }

  return markerInfoArray;
};

export const getEventMapMarker = (route: any, event: any) => {
  let stop = route.data[0];
  let stopInfo = {
    latLong: stop.lat_long,
    title: event.title,
    streetAddress: stop.street_address,
  }
  return [stopInfo];
}

export const getRouteName = (route: IRoute) => {
  let stops: any = route.data.slice(0, -1);
  let townArray: string[] = [];

  for (let i = 0; i < stops.length; i++) {
    if (townArray.length < 3) {
      let addressSplit = stops[i].street_address.split(',');
      if (addressSplit.length === 1) return addressSplit[0];
      let townName = addressSplit[addressSplit.length - 2].trim();
      if (!townArray.includes(townName)) {
        if (townArray.length === 2 && stops[i + 1]) townName += '...';
        townArray.push(townName);
      }
    }
  }

  return townArray.join(', ');
};
