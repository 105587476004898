// capitalizes a word
export const capitalize = (string: string) => {
  let splitStr = string.split('');
  splitStr[0] = splitStr[0].toUpperCase();
  return splitStr.join('');
}

// takes phone number and returns well formatted string for display
export const phoneNumberToString = (phoneNumber: number | null) => {
  if (phoneNumber) {
    let phoneStringSplit = String(phoneNumber).split('');
    let phoneString = `1 (${phoneStringSplit[0]}${phoneStringSplit[1]}${phoneStringSplit[2]}) ${phoneStringSplit[3]}${phoneStringSplit[4]}${phoneStringSplit[5]}-${phoneStringSplit[6]}${phoneStringSplit[7]}${phoneStringSplit[8]}${phoneStringSplit[9]}`;
    return phoneString;
  } else {
    return 'None';
  }
};

// takes phone number string and converts to number for storage
export const phoneStringToNumber = (phoneNumber: string) => {
  let phoneSplit = phoneNumber.split(' ');
  return Number(`${phoneSplit[1]}${phoneSplit[2]}${phoneSplit[3]}`);
};