import { FormControl, InputLabel, Input } from '@mui/material';

type TextInputProps = {
  changeHandler: (event: any) => void;
  value: string;
  label: string;
};

// reusable text input, takes method to handle change, value and label
const TextInput = ({changeHandler, value, label}: TextInputProps) => {
  return (
    <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
      <InputLabel>{label}</InputLabel>
      <Input
        id={`${label}-input`}
        value={value}
        onChange={changeHandler}
      />
    </FormControl>
  );
};

export default TextInput;
