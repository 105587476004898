import { useState } from 'react';

import { supabase } from '../../db/client';

import { Box, TextField, Button } from '@mui/material';

export default function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email) {
      // send request to reset password for input email to supabase, include address to redirect to
      // check rest of this process in Supabase for more information - when user clicks link in email they will be taken to reset password page
      // where they can enter new password
      const { data, error } = await supabase.auth.api.resetPasswordForEmail(
        email,
        {
          redirectTo: 'https://app.fleet-lab.com',
        }
      );
      console.log('error response from sb', error);
      if (data) {
        console.log('response from sb', data);
        setSuccess(true);
      }
      if (error) {
        setErrorText(error.message);
      }
    } else {
      setErrorText('Please enter your email address');
    }
  };

  return (
    <div style={{ marginTop: '5vh' }} className='driver-app-parent'>
      <img
        src="/logo512.png"
        alt=""
        style={{ width: '100px', marginTop: '5vh' }}
      ></img>
      {success ? (
        <p style={{ marginTop: '5em' }}>
          Check your email for the recovery link.
        </p>
      ) : (
        <>
          <h1 style={{ marginTop: '2em' }}>Enter your email address</h1>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '25ch' },
              marginTop: '2em',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              id="email"
              label="Email Address"
              name="email"
              variant="outlined"
              color="primary"
              value={email}
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value.trim());
                setErrorText('');
              }}
            />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ margin: '3em auto' }}
            >
              Submit
            </Button>
          </Box>
          {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
        </>
      )}
    </div>
  );
}
