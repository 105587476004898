import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// OEInterface page currently links only to field trip page, in progress
const OEInterface = () => {
  const navigate = useNavigate();

  return (
    <div className="admin-app-parent">
      <h1 style={{ marginTop: '3em', textAlign: 'center' }}>
        Optimization Engine Interface
      </h1>
      <Button onClick={() => navigate('/admin/field-trips')}>Field Trips</Button>
    </div>
  );
};

export default OEInterface;
