import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../db/client';

// AuthContext written in js and not typescript, could not get types needed to function
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(supabase.auth.user());
  const [watchID, setWatchID] = useState([]);
  const [driverLocation, setDriverLocation] = useState();

  useEffect(() => {
    const getUserProfile = async () => {
      // method to get user session data
      const sessionUser = supabase.auth.user();

      if (sessionUser) {
        // gets user profile
        let { data: profile, error } = await supabase
          .from('profiles')
          .select()
          .eq('email', sessionUser.email)
          .single();

          // will return error if user has not created profile - set newUser value on user so pages can check that value
        if (error) {
          console.log(error.message);
          setUser({ ...sessionUser, newUser: true });
        }

        // if they have a profile - check role - if driver, pull driver info and routes assigned, set on User in context
        if (profile) {
          if (profile.role === 'driver') {
            let { data: driver, error } = await supabase
              .from('drivers')
              .select()
              .eq('email', sessionUser.email)
              .single();

            let { data: routes } = await supabase
              .from('routes')
              .select('*, vehicles (*)')
              .eq('driver_id', driver.id)
              .eq('complete', false);
            setUser({
              ...sessionUser,
              ...driver,
              role: profile.role,
              routes: routes,
              district_id: profile.district_id,
            });
            // if admin - pull all drivers, vehicles, routes for their district
          } else if (profile.role === 'admin') {
            let { data: routes } = await supabase
              .from('routes')
              .select('*, vehicles (*)')
              .eq('district_id', profile.district_id)
              .eq('complete', false);

            let { data: driverInfo, error } = await supabase
              .from('drivers')
              .select()
              .eq('email', sessionUser.email)
              .single();

            if (driverInfo) {
              setUser({
                ...sessionUser,
                ...driverInfo,
                routes: routes,
                role: profile.role,
                district_id: profile.district_id,
              });
            } else {
              setUser({
                ...sessionUser,
                routes: routes,
                role: profile.role,
                district_id: profile.district_id,
              });
            }
          }
        }
      }
    };

    getUserProfile();

    // if auth state changes (log in, log out or session expired), get user info again
    supabase.auth.onAuthStateChange(() => {
      getUserProfile();
    });
  }, []);

  // login takes credentials and attemps to sign in with supabase
  const logIn = async (data) => {
    let { user, session, error } = await supabase.auth.signIn(data);

    // user will be returned if sign in successful
    if (user) {
      let { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select()
        .eq('email', data.email)
        .single();

        // fetch profile, redirect to admin home if admin, root (driver home) if driver, if no profile assign to home anyway -
        // if user visits root and is not logged in they will redirect to login page
      if (profile) {
        if (profile.role === 'admin') window.location.assign('/admin/home');
        if (profile.role === 'driver') window.location.assign('/');
      } else {
        window.location.assign('/');
      }
    }

    return { user, session, error };
  };

  const logOut = async () => {
    // signout user, set user in context to null and redirect to root (which will take to login)
    await supabase.auth.signOut();
    setUser(null);
    window.location.assign('/');
  };

  // get location method in auth context to continue to track driver location while they are navigating through pages of driver app
  function getLocation() {
    // check if browser allows geolocation
    if (navigator.geolocation) {
      // run watch position, get and store watch id in the event this is run multiple times there will be multiple watch ids
        let newWatchID = navigator.geolocation.watchPosition(setDriverPosition, fallBackPosition, {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        });
        if (newWatchID) {
          setWatchID([...watchID, newWatchID]);
        }
      // }
    }
  }

  // these are callback functions for geolocaion.watchPosition

  // If a user allows geolocation, setDriverPositionRuns with their returned location.
  function setDriverPosition(position) {
    console.log(position);
    setDriverLocation(position);
  }

  // If a user blocks geolocation, the fallbackPosition is set and is used in rendering the map
  function fallBackPosition(error) {
    //   setPosition(fallBackDriverPosition);
    console.log(error);
  }

  // driver location
  const watchUserLocation = () => {
    console.log('watchLocation runs');
    getLocation();
  }

  // method to send last driver location to supabase with timestamp
  const sendLocation = async () => {
    let lastLocation = {
      coords: {
        latitude: driverLocation.coords.latitude,
        longitude: driverLocation.coords.longitude,
      },
      timestamp: driverLocation.timestamp,
    };
    const { data, error } = await supabase
      .from('drivers')
      .update({ last_location: lastLocation })
      .eq('email', user.email);

    console.log('resposne from sendLocation', data || error);
  };

  // cancel watch location will stop browser from updating user location. loops through watch id array runs clearWatch for each
  const cancelWatchLocation = () => {
    watchID.forEach( id => navigator.geolocation.clearWatch(id))
    // set watch id array to empty array
    setWatchID([]);
  }

  // useEffect sends driver location to supabase every time driver location changes
  useEffect(() => {
    if (driverLocation) {
      console.log(watchID);
      sendLocation();
    }
  }, [driverLocation]) 


  // method to update user routes stored in context used in multiple places in front end - if route data changes, change routes in context to match
  const updateUserRoutes = async (userInfo) => {
    if (userInfo.role === 'admin') {
      let { data: routes, error } = await supabase
        .from('routes')
        .select('*, vehicles (*)')
        .eq('district_id', userInfo.district_id)
        .eq('complete', false);

      console.log('response from inside updateUserRoutes', routes || error);
      if (routes) {
        setUser({
          ...userInfo,
          routes: routes,
        });
      }
    } else if (userInfo.role === 'driver') {
      let { data: routes, error } = await supabase
        .from('routes')
        .select('*, vehicles (*)')
        .eq('driver_id', userInfo.id)
        .eq('complete', false);

      console.log('response from inside updateUserRoutes', routes || error);

      if (routes) {
        setUser({
          ...userInfo,
          routes: routes,
        });
      }
    }
  };

  // pass methods and user to children
  const value = {
    logIn,
    logOut,
    updateUserRoutes,
    watchUserLocation,
    cancelWatchLocation,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
