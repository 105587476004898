import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression, icon } from 'leaflet';

import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

import './StaticMap.css';

import { IStop } from '../../ts/interfaces';

// icon options for markers
const busIcon = icon({
  iconUrl: '/bus.svg',
  iconSize: [30, 30]
});

const vanIcon = icon({
  iconUrl: '/van.svg',
  iconSize: [30, 30],
});

const stopIcon = icon({
  iconUrl: '/house-solid.svg',
  iconSize: [30, 30],
});

type StopComponentProps = {
  latLong: LatLngExpression;
  popupText?: string;
  icon?: typeof busIcon;
  stopInfo?: any;
  stopNumber?: number;
};

// component for each stop
const StopComponent = ({
  latLong,
  popupText,
  icon,
  stopInfo,
  stopNumber
}: StopComponentProps) => {
  return (
    <>
      <Marker position={latLong} icon={icon}>
        {stopInfo && (
          <Tooltip
            direction="center"
            opacity={1}
            className={'tooltip'}
            permanent
          >
            {stopNumber && (
              <>{stopNumber}</>
            )}
          </Tooltip>
        )}

        <Popup>
          {stopInfo ? (
            <p>
              {stopInfo.title}
              {stopInfo.studentName}<br />
              {stopInfo.streetAddress}<br />
            </p>
          ) : (
            <>{popupText}</>
          )}
        </Popup>
      </Marker>
    </>
  );
};

type StaticMapProps = {
  driverLocation: number[];
  waypointsArray: number[][];
  markerArray: IStop[];
  destination: number[];
  vehicleType: string;
};

const StaticMap = ({
  driverLocation,
  waypointsArray,
  markerArray,
  destination,
  vehicleType
}: StaticMapProps) => {


  const formatWaypointsArray = (arr: number[][]) => {
    let wayPointsArray = arr.map((stop: number[]) => {
      return L.latLng(stop[0], stop[1]);
    });

    return wayPointsArray;
  };

  const createRoutingMachineLayer = () => {
    let formattedWaypoints = formatWaypointsArray(waypointsArray);

    const planOptions = {
      waypoints: formattedWaypoints,
      addWaypoints: false,
      draggableWaypoints: false,
      show: false,
      routeWhileDragging: false,
      fitSelectedRoutes: true,
      showAlternatives: false,
      lineOptions: {
        styles: [{ color: '#004812', weight: 6 }],
        extendToWaypoints: true,
        missingRouteTolerance: 0,
        addWaypoints: false,
      },
      units: 'imperial',
      createMarker: function () {
        return null;
      },
    };

    return L.Routing.control(planOptions);
  };

  const RoutingMachine = createControlComponent(createRoutingMachineLayer);

  return (
    <>
      {waypointsArray && (
        <div>
          <MapContainer
            center={driverLocation as LatLngExpression}
            zoom={10}
            scrollWheelZoom={false}
            style={{ height: '70vh' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <StopComponent
              latLong={driverLocation as LatLngExpression}
              popupText={'Your Location'}
              icon={vehicleType === 'bus' ? busIcon : vanIcon}
            />
            {markerArray.map((stop: IStop, index: number) => {
              return (
                <StopComponent
                  latLong={stop.latLong}
                  key={stop.streetAddress}
                  icon={stopIcon}
                  stopInfo={stop}
                  stopNumber={index + 1}
                />
              );
            })}
            <StopComponent
              latLong={destination as LatLngExpression}
              popupText={'Destination'}
              icon={vehicleType === 'bus' ? busIcon : vanIcon}
            />
            <RoutingMachine />
          </MapContainer>
        </div>
      )}
    </>
  );
};

export default StaticMap;
