import { useState, useEffect } from 'react';
import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import { format, add, startOfWeek, endOfWeek } from 'date-fns';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';

import './Register.css';

import { CheckBoxTimeSlot } from '../../components';

import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input';

import {
  Box,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  FormGroup,
  Typography,
} from '@mui/material';

import { TWeeklyAvailabilityArray } from '../../ts/types';

const newBaseline = [
  {
    day: 'monday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'tuesday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'wednesday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'thursday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'friday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'saturday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
  {
    day: 'sunday',
    midday: false,
    evening: false,
    morning: false,
    afternoon: false,
  },
];

// registration page is currently built only to register new drivers for SMCCs district - new system needs to be developed
// to allow us not to hard code those values but also prevent any random person from registering to use the app

export default function Register() {
  const [fName, setFName] = useState<string>();
  const [lName, setLName] = useState<string>();
  const [homeAddress, setHomeAddress] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [license, setLicense] = useState<string>('');

  const [errorText, setErrorText] = useState<string>();
  const [success, setSuccess] = useState(false);

  const [fullScheduleArray, setFullScheduleArray] = useState<Date[]>();
  const [indexOfDaySelected, setIndexOfDaySelected] = useState<number>(0);
  const [fullWeeklyAvailability, setFullWeeklyAvailability] = useState<TWeeklyAvailabilityArray>([]);
  const [startDay, setStartDay] = useState(0);
  const [loading, setLoading] = useState(true);

  const [phoneInput, setPhoneInput] = useState<string>('1');
  const [isValid, setIsValid] = useState<boolean>();

  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const { user } = useAuth();

  const handleChange = (event: SelectChangeEvent) => {
    setLicense(event.target.value);
  };

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (fName && lName && phoneInput && license) {
      if (!isValid) {
        setErrorText('Not a valid phone number')
      } else{
        // create new profile, hard coded role for driver and district id for SMCC
        const { data, error } = await supabase
          .from('profiles')
          .insert([{ role: 'driver', user_id: user.id, email: user.email, district_id: 2 }]);
        if (data) console.log('returned from sb', data);
        if (error) {
          console.log('error returned from sb', error);
          setErrorText('Database error: ' + error.message)
          return;
        }

        let phoneTrim = phoneInput.slice(2);
        let phoneDigits = Number(phoneTrim.replace(/\s+/g, ''));

        let driverObject = {
          license: license,
          fname: fName,
          lname: lName,
          home_address: homeAddress,
          phone: phoneDigits,
          user_id: user.id,
          email: user.email,
          baseline_availability: newBaseline,
          weekly_availability: fullWeeklyAvailability,
          district_id: 2
        };

        // create new row in drivers table, hard code value for SMCC district
      const { data: driver, error: driverError } = await supabase
        .from('drivers')
        .insert([driverObject]);
      if (driver) console.log('reponse from sb', driver);
      if (driverError) console.log('error from sb', driverError);

      if (driver) {
        setSuccess(true);
      } else {
        setErrorText('Something went wrong');
        return;
      }
      }
    } else {
      setErrorText("Please check you've entered all required information");
    }
  };

  const getDaysToShow = () => {
    let firstDay = startOfWeek(Date.now(), { weekStartsOn: 1 });
    let lastDayOfWeek = endOfWeek(Date.now(), { weekStartsOn: 1 });
    
    let fullScheduleArray = [firstDay];
    for (let i = 1; i < 14; i++) {
      let newDate = add(fullScheduleArray[0], {
        days: i,
      });
      fullScheduleArray.push(newDate);
    }

    setFullScheduleArray(fullScheduleArray);

    let fullWeeklyAvailability: TWeeklyAvailabilityArray = [];
    for (let i = 0; i < fullScheduleArray.length; i++) {
      let newDate = {
        date: format(fullScheduleArray[i], 'yyyy-M-d'),
        morning: false,
        midday: false,
        afternoon: false,
        evening: false,
      };
      fullWeeklyAvailability.push(newDate);
    }

    setFullWeeklyAvailability(fullWeeklyAvailability);

    let indexOfToday = fullScheduleArray.findIndex(
      (date) => date.getDate() === new Date(Date.now()).getDate()
    );

    setStartDay(indexOfToday);
    setIndexOfDaySelected(indexOfToday);
    setLoading(false);
  };

  // check session info to see if user is already registered, alert user to prevent registering twice
  useEffect(() => {
    console.log(user);
    if (user.role === 'driver' || user.role === 'admin') {
      setAlreadyRegistered(true);
    }
    getDaysToShow();
  }, [user]);

  const backOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected - 1;
    if (newIndex >= startDay) {
      setIndexOfDaySelected(newIndex);
    }
  };

  const aheadOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected + 1;
    if (newIndex < fullWeeklyAvailability.length) {
      setIndexOfDaySelected(newIndex);
    }
  };

  const checkOptions = ['morning', 'midday', 'afternoon', 'evening'];

  const checkValue = (timeslot: string): boolean => {
    if (fullScheduleArray) {
      let currentDay = fullWeeklyAvailability[indexOfDaySelected];

      let value = currentDay[timeslot as keyof typeof currentDay];
      return value as boolean;
    }

    return false;
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fullScheduleArray) {
      let slot = event.target.value;
      let dayToChange = fullWeeklyAvailability[indexOfDaySelected];
      let newAvailability = [...fullWeeklyAvailability];
      let index = indexOfDaySelected;

      let newDay = {
        date: dayToChange.date,
        morning: newAvailability[index]['morning'],
        midday: newAvailability[index]['midday'],
        afternoon: newAvailability[index]['afternoon'],
        evening: newAvailability[index]['evening'],
      };

      if (slot === 'morning') {
        newDay.morning = !newAvailability[index].morning;
      } else if (slot === 'midday') {
        newDay.midday = !newAvailability[index].midday;
      } else if (slot === 'afternoon') {
        newDay.afternoon = !newAvailability[index].afternoon;
      } else if (slot === 'evening') {
        newDay.evening = !newAvailability[index].evening;
      }

      newAvailability[index] = newDay;
      setFullWeeklyAvailability(newAvailability);
    }
  };

  const phoneChange = (value: string) => {
    setIsValid(isValidPhoneNumber(value));
    setPhoneInput(value);
  };

  return (
    <div className='driver-app-parent'>
      <img
        src="/logo512.png"
        alt=""
        style={{ width: '100px', marginTop: '5vh' }}
      ></img>
      <h1>Registration</h1>
      {success ? (
        <p style={{ margin: '0 auto' }}>
          Thank you for registering. Continue to the <br />
          <a href="/" style={{ color: 'blue' }}>
            Home Page
          </a>
        </p>
      ) : (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleRegister}
        >
          {alreadyRegistered && <Typography sx={{margin: '0 auto', color: 'red'}}>You are already registered</Typography>}
          <TextField
            id="fName"
            label="First Name"
            name="fName"
            variant="outlined"
            color="primary"
            value={fName}
            autoFocus
            onChange={(event) => {
              setFName(event.target.value);
              setErrorText('');
            }}
            sx={{ margin: '1em' }}
          />
          <br />
          <TextField
            id="lName"
            name="lName"
            label="Last Name"
            variant="outlined"
            color="primary"
            value={lName}
            type="text"
            onChange={(event) => {
              setLName(event.target.value);
              setErrorText('');
            }}
            sx={{
              margin: '1em',
            }}
          />
          <br />
          <TextField
            id="homeAddress"
            name="homeAddress"
            label="Home Address (optional)"
            variant="outlined"
            color="primary"
            value={homeAddress}
            rows={5}
            type="text"
            onChange={(event) => {
              setHomeAddress(event.target.value);
              setErrorText('');
            }}
            sx={{
              margin: '1em',
            }}
          />
          <br />
          <div style={{ margin: '1em auto' }}>
            <Typography color="primary" sx={{ fontSize: '1em' }}>
              Phone Number (w/ area code)
            </Typography>
            <MuiTelInput
              value={phoneInput}
              onChange={phoneChange}
              onlyCountries={['US']}
              disableDropdown={true}
            />
          </div>

          <br />
          <FormControl fullWidth sx={{ margin: '0' }}>
            <InputLabel id="license-select">License</InputLabel>
            <Select
              labelId="license-select"
              id="license-select-input"
              value={license}
              label="License"
              onChange={handleChange}
              sx={{ marginBottom: '2em' }}
            >
              <MenuItem value={'standard'}>Standard</MenuItem>
              <MenuItem value={'chauffer'}>Chauffer</MenuItem>
              <MenuItem value={'cdl'}>CDL</MenuItem>
            </Select>
          </FormControl>
          {fullScheduleArray && (
            <>
              <h2 style={{ margin: '1em auto' }}>
                Set your availability for the rest of this week and next week:
              </h2>
              <footer className="registrationDates">
                <div>
                  {indexOfDaySelected > startDay && (
                    <ArrowBackIosNew onClick={backOneDay} />
                  )}
                </div>
                <p style={{ fontSize: '1.2em', lineHeight: 1 }}>
                  {format(
                    fullScheduleArray[indexOfDaySelected],
                    'iiii, MMMM dd'
                  )}
                </p>
                <div>
                  {indexOfDaySelected !== fullScheduleArray.length - 1 && (
                    <ArrowForwardIos onClick={aheadOneDay} />
                  )}
                </div>
              </footer>

              <FormGroup
                sx={{
                  margin: '0 auto',
                }}
              >
                {checkOptions.map((timeSlot) => (
                  <CheckBoxTimeSlot
                    timeSlot={timeSlot}
                    checkValue={checkValue(timeSlot)}
                    handleCheck={handleCheck}
                    key={timeSlot}
                  />
                ))}
              </FormGroup>
            </>
          )}

          <br />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ margin: '2em auto' }}
            disabled={alreadyRegistered}
          >
            Register
          </Button>
          {errorText && (
            <p style={{ margin: '1em auto', color: 'red' }}>{errorText}</p>
          )}
        </Box>
      )}
    </div>
  );
}
