import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { supabase } from '../../db/client';

import './AdminHome.css';
import { Paper, Button, Modal, Box } from '@mui/material';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';
import MultiMap from '../../components/MultiMap';

import { IRoute, IDriver, IRouteBlocks } from '../../ts/interfaces';

import { getExactStart, formatTime } from '../../utils/dateTimeHelpers';
import {
  getRouteName,
  getMapMarkerInfo,
  getEventMapMarker,
  getStudentInfoFromRoute,
} from '../../utils/routeDataHandlers';

import {
  format,
  add,
  sub,
  startOfWeek,
  isBefore,
  isEqual,
  isAfter,
} from 'date-fns';

type RouteCardProps = {
  route: IRoute;
  index: number;
  driverName: string;
};

// display basic route info in card on home page
const RouteCard = ({ route, index, driverName }: RouteCardProps) => {
  const { id, start_time, date } = route;
  let exactStart = getExactStart(date, start_time);
  const navigate = useNavigate();

  // when each route clicked, navigate to that routes detailed page
  const routeClickHandler = (e: React.MouseEvent) => {
    navigate(`/admin/routes/${id}`);
  };

  // alternate styles based by route type or state
  let borderColor = (route.type === 'event' || route.type === 'field_trip') ? 'green' : 'black';
  let textColor = route.complete ? 'gray' : 'black';

  let vehicle = 'None'
  // if vehicle assigned (should be) display vehicle identifier
  if (route.vehicles && route.vehicles.identifier) {
    vehicle = route.vehicles.identifier
  }

  return (
    <Paper
      elevation={10}
      sx={{
        borderRadius: '15px',
        cursor: 'pointer',
        padding: 2,
        margin: 1,
        outline: `1px solid ${borderColor}`,
        width: '250px',
        color: textColor
      }}
      onClick={routeClickHandler}
    >
      <h2 style={{ margin: 0, textAlign: 'center' }}>{getRouteName(route)}</h2>
      {route.type === 'event' && (
        <p style={{ color: 'green', textAlign: 'center', marginTop: 0 }}>
          EVENT
        </p>
      )}
      {route.type === 'field_trip' && (
        <p style={{ color: 'green', textAlign: 'center', marginTop: 0 }}>
          FIELD TRIP
        </p>
      )}
      {route.in_progress && (
        <p style={{ color: 'red', textAlign: 'center', marginTop: 0}}>IN PROGRESS</p>
      )}
      <p>Driver: {driverName}</p>
      <p>Vehicle: {vehicle}</p>
      <p style={{ marginBottom: 0 }}>Check in - {formatTime(exactStart)}</p>
      {route.complete && (
        <p style={{border: '1px solid gray', textAlign: 'center'}}>Completed</p>
      )}
    </Paper>
  );
};

type AdminHomeProps = {
  routes: IRoute[];
  drivers: IDriver[];
  districtTimes: any;
  tableNames: any;
  districtInfo: any;
};

const AdminHome = ({ routes, drivers, districtTimes, tableNames, districtInfo }: AdminHomeProps) => {
  let [daysToShow, setDaysToShow] = useState<Date[]>();
  let [currentDayRoutes, setCurrentDayRoutes] = useState<IRoute[]>([]);
  let [indexOfDaySelected, setIndexOfDaySelected] = useState(0);
  let [blockedRoutes, setBlockedRoutes] = useState<IRouteBlocks>();
  let [multiMapRoutes, setMultiMapRoutes] = useState<any[] | []>([]);
  let [multiMapOpen, setMultiMapOpen] = useState(false);

  const navigate = useNavigate();

  // set up admin home page to start on current day and show next 3 weeks
  const getDaysToShow = () => {
    let firstDay = startOfWeek(Date.now(), { weekStartsOn: 1 });
    let fullScheduleArray = [firstDay];
    for (let i = 1; i < 21; i++) {
      let newDate = add(fullScheduleArray[0], {
        days: i,
      });
      fullScheduleArray.push(newDate);
    }

    let indexOfToday = fullScheduleArray.findIndex(
      (date) => date.getDate() === new Date(Date.now()).getDate()
    );
    let daysToShow = fullScheduleArray.slice(indexOfToday);

    return daysToShow;
  };

  // takes driver id of route and finds name from array of drivers pulled in parent and passed to Home component
  const getDriverName = (driverArray: IDriver[], driverID: number) => {
    let driver = driverArray.find((driver: IDriver) => {
      return driver.id === driverID;
    });
    if (driver) {
      return `${driver.fname} ${driver.lname}`;
    } else {
      return '';
    }
  };

  // methods to flip through days
  const backOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected - 1;
    if (newIndex >= 0) {
      setIndexOfDaySelected(newIndex);
    }
  };

  const aheadOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected + 1;
    if (daysToShow && newIndex < daysToShow.length) {
      setIndexOfDaySelected(newIndex);
    }
  };

  useEffect(() => {
    setDaysToShow(getDaysToShow);
  }, []);

  // methods take route, return date obj from date and time of route start and end - time blocks filtered using route start times only
  const dateTimeOfRouteStart = (route: IRoute) => {
    let timeArray = route.start_time.split(':');
    let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
    let dateArray = route.date.split('-').map((date: string) => {
      return Number(date);
    });
    let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    let formattedDate = format(newDate, 'MMMM d, yyyy');
    let dateStr = `${formattedDate} ${newTimeString} UTC`;
    return new Date(dateStr);
  };

  const dateTimeOfRouteEnd = (route: IRoute) => {
    let timeArray = route.end_time.split(':');
    let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
    let dateArray = route.date.split('-').map((date: string) => {
      return Number(date);
    });
    let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    let formattedDate = format(newDate, 'MMMM d, yyyy');
    let dateStr = `${formattedDate} ${newTimeString} UTC`;
    return new Date(dateStr);
  };

  // useEffect runs when day selected changes
  useEffect(() => {
    if (daysToShow) {
      let currentDay = daysToShow[indexOfDaySelected];

      let currentDayAsDateString = currentDay.toDateString();
      let formatCurrentDay = format(currentDay, 'iiii, MMMM dd');

      // filter routes to only include routes with dates matching current date string
      let todaysRoutes = routes.filter((route: any) => {
        let timeArray = route.start_time.split(':');
        let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
        let dateArray = route.date.split('-').map((date: string) => {
          return Number(date);
        });
        let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        let formattedDate = format(newDate, 'MMMM d, yyyy');
        let dateStr = `${formattedDate} ${newTimeString} UTC`;

        let dateOfRoute = new Date(dateStr);

        let formatDateOfRoute = format(dateOfRoute, 'iiii, MMMM dd');
        return formatDateOfRoute === formatCurrentDay;
      });

      // date obj for when school starts and ends used for comparison
      let amSchoolStart = new Date(
        `${currentDayAsDateString} ${districtTimes.start_time} UTC`
      );
      let pmSchoolEnd = new Date(
        `${currentDayAsDateString} ${districtTimes.end_time} UTC`
      );

      // date obj for when route starts and ends for comparison
      let amRouteEnd = sub(amSchoolStart, {
        minutes: districtTimes.am_buffer,
      });

      let pmRouteStart = add(pmSchoolEnd, {
        minutes: districtTimes.pm_buffer,
      });

      // sort routes by start time
      todaysRoutes.sort((a: any, b: any) => {
        let aSplit = a.start_time.split(':');
        let bSplit = b.start_time.split(':');

        let aMinutes = Number(aSplit[0] * 60) + Number(aSplit[1]);
        let bMinutes = Number(bSplit[0] * 60) + Number(bSplit[1]);

        return aMinutes - bMinutes;
      });

      let blockedRoutes: IRouteBlocks = {
        morning: [],
        midday: [],
        afternoon: [],
        evening: [],
      };

      // loop through days routes
      for (let i = 0; i < todaysRoutes.length; i++) {
        let exactStartTime = dateTimeOfRouteStart(todaysRoutes[i]);
        // commented out - refactored to allow for routes with unknown end time, blockedRoutes determined with start time of route only
        // let exactEndTime = dateTimeOfRouteEnd(todaysRoutes[i]);

        // use date-fns comparisons to determine what block of time each route belongs in
        if (
          // breaking these up is finnicky, needs attention
          // (isBefore(exactEndTime, amRouteEnd) && !isAfter(exactStartTime, pmRouteStart)) ||
          // isEqual(exactEndTime, amRouteEnd) ||
          isBefore(exactStartTime, amRouteEnd) ||
          isEqual(exactStartTime, amRouteEnd)
        ) {
          blockedRoutes.morning = [...blockedRoutes.morning, todaysRoutes[i]];
        } else if (isEqual(exactStartTime, pmRouteStart)) {
          blockedRoutes.afternoon = [
            ...blockedRoutes.afternoon,
            todaysRoutes[i],
          ];
        } else if (
          isBefore(exactStartTime, pmRouteStart) &&
          isAfter(exactStartTime, amRouteEnd)
        ) {
          blockedRoutes.midday = [...blockedRoutes.midday, todaysRoutes[i]];
        } else if (isAfter(exactStartTime, pmRouteStart)) {
          blockedRoutes.evening = [...blockedRoutes.evening, todaysRoutes[i]];
        }
      }

      setCurrentDayRoutes(todaysRoutes);
      setBlockedRoutes(blockedRoutes);
    }
  }, [indexOfDaySelected, daysToShow]);

  // for each block a map modal can be opened showing just that blocks routes
  const openMultiMap = (block: string) => {

    // 
    const addMarkerArray = async (route: any) => {
      if (route.type === 'standard') {
        let studentInfoArray = await getStudentInfoFromRoute(
          route,
          tableNames.students
        );
        // get student info from students on route, get map marker info for students, store on route
        if (studentInfoArray) {
          let markerInfoArray = getMapMarkerInfo(route, studentInfoArray);
          route.markerInfoArray = markerInfoArray;
        }
      }
      // if event or field trip, get info for event or field trip - get map marker info, store marker array on route
      if (route.type === 'event') {
        let { data: event, error } = await supabase
          .from('events')
          .select()
          .eq('id', route.event_id)
          .single();

        if (event) {
          let markerInfoArray = getEventMapMarker(route, event);
          route.markerInfoArray = markerInfoArray;
        }
      }

      if (route.type === 'field_trip') {
        let { data: fieldTrip, error } = await supabase
        .from('field_trips')
        .select()
        .eq('id', route.field_trip_id)
        .single();

        if (fieldTrip) {
          let markerInfoArray = getEventMapMarker(route, fieldTrip);
          route.markerInfoArray = markerInfoArray;
        }
      }
    };

    if (blockedRoutes) {
      // if there are routes in the block, add map marker to each, add color to each, store in multimap routes array
  let colors = ['blue', 'indigo', 'violet', 'green', 'orange', 'yellow', 'red'];

      if (block === 'morning') {
        blockedRoutes.morning.forEach( (route: any, index) => {
          addMarkerArray(route);
          route.color = colors[index]
        })
        setMultiMapRoutes(blockedRoutes.morning);
      } else if (block === 'midday') {
        blockedRoutes.midday.forEach( (route: any, index) => {
          addMarkerArray(route);
          route.color = colors[index]
        })
        setMultiMapRoutes(blockedRoutes.midday);
      } else if (block === 'afternoon') {
        blockedRoutes.afternoon.forEach((route: any, index) => {
          addMarkerArray(route);
          route.color = colors[index];
        });
        setMultiMapRoutes(blockedRoutes.afternoon);
      } else if (block === 'evening') {
        blockedRoutes.evening.forEach((route: any, index) => {
          addMarkerArray(route);
          route.color = colors[index];
        });
        setMultiMapRoutes(blockedRoutes.evening);
      }
    }
    // then open multimap
    setMultiMapOpen(true);
  };

  return (
    <div style={{ paddingBottom: '5em' }} className="admin-home-container">
      <div style={{ marginTop: '5em' }}>
        <Box sx={{ textAlign: 'right' }}>
          <Button variant="contained" onClick={() => navigate('/admin/routes/past')}>
            Past Routes
          </Button>
        </Box>
        {blockedRoutes && (
          <>
            <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
              Morning Routes{' '}
              <Button onClick={() => openMultiMap('morning')}>Map</Button>
            </h2>
            {blockedRoutes.morning.length ? (
              <div className="admin-route-block">
                {blockedRoutes.morning.map((route: IRoute, index: number) => (
                  <RouteCard
                    key={route.id}
                    route={route}
                    index={index + 1}
                    driverName={getDriverName(drivers, route.driver_id)}
                  />
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center' }}>No routes scheduled</p>
            )}
            <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
              Midday Routes
              <Button onClick={() => openMultiMap('midday')}>Map</Button>
            </h2>

            {blockedRoutes.midday.length ? (
              <div className="admin-route-block">
                {blockedRoutes.midday.map((route: IRoute, index: number) => (
                  <RouteCard
                    key={route.id}
                    route={route}
                    index={index + 1}
                    driverName={getDriverName(drivers, route.driver_id)}
                  />
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center' }}>No routes scheduled</p>
            )}
            <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
              Afternoon Routes
              <Button onClick={() => openMultiMap('afternoon')}>Map</Button>
            </h2>
            {blockedRoutes.afternoon.length ? (
              <div className="admin-route-block">
                {blockedRoutes.afternoon.map((route: IRoute, index: number) => (
                  <RouteCard
                    key={route.id}
                    route={route}
                    index={index + 1}
                    driverName={getDriverName(drivers, route.driver_id)}
                  />
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center' }}>No routes scheduled</p>
            )}
            <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
              Evening Routes
              <Button onClick={() => openMultiMap('evening')}>Map</Button>
            </h2>
            {blockedRoutes.evening.length ? (
              <div className="admin-route-block">
                {blockedRoutes.evening.map((route: IRoute, index: number) => (
                  <RouteCard
                    key={route.id}
                    route={route}
                    index={index + 1}
                    driverName={getDriverName(drivers, route.driver_id)}
                  />
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center' }}>No routes scheduled</p>
            )}
          </>
        )}
        {multiMapOpen && (
          <Modal open={multiMapOpen} onClose={() => setMultiMapOpen(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                paddingInline: '1em',
              }}
            >
              <Box
                sx={{ margin: 0, width: 'fit-content', float: 'right' }}
                onClick={() => setMultiMapOpen(false)}
              >
                <Button
                  sx={{
                    fontSize: '1em',
                    marginTop: '.5em',
                    marginBottom: '.5em',
                  }}
                >
                  X
                </Button>
              </Box>
              {multiMapRoutes && multiMapRoutes.length >= 1 && (
                <MultiMap routes={multiMapRoutes} tableNames={tableNames} districtInfo={districtInfo}/>
              )}
              {multiMapRoutes && multiMapRoutes.length === 0 && (
                <p style={{ textAlign: 'center' }}>
                  No routes in this block
                </p>
              )}
            </Box>
          </Modal>
        )}
      </div>
      <footer
        className="admin-footer"
        style={{ paddingBottom: '1em', borderTop: 'none' }}
      >
        <div>
          {indexOfDaySelected !== 0 && <ArrowBackIosNew onClick={backOneDay} />}
        </div>
        <p style={{ fontSize: '1.2em', lineHeight: 1 }}>
          {daysToShow &&
            format(daysToShow[indexOfDaySelected], 'iiii, MMMM dd')}
        </p>
        <div>
          {daysToShow && indexOfDaySelected !== daysToShow.length - 1 && (
            <ArrowForwardIos onClick={aheadOneDay} />
          )}
        </div>
      </footer>
    </div>
  );
};

export default AdminHome;
