import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';

import { supabase } from '../../db/client';

import { useAuth } from '../../contexts/Auth';

import { IRoute } from '../../ts/interfaces';

import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  TextField,
} from '@mui/material';

// custom components
import { FinishCheckModal, CheckListItem } from '../../components';

import { generateUrlForGoogleMaps, generateUrlForGoogleMapsDriverEvent } from '../../utils/generateUrlForGoogleMaps';

const PostCheck = () => {  
  const [postcheck, setPostcheck] = useState<string[]>([]);
  const [checkListItems, setCheckListItems] = useState<string[]>([]);
  const [reportModalState, setReportModalState] = useState(false);
  const [issueText, setIssueText] = useState('');
  const [allChecked, setAllChecked] = useState(false);
  const [mapsUrl, setMapsUrl] = useState<string>('');

  const [routeInfo, setRouteInfo] = useState<any>();
  const [routeLoaded, setRouteLoaded] = useState(false);

  const id = useParams().id
  const { user, updateUserRoutes, cancelWatchLocation } = useAuth();

  // get route info from user or navigate to home if user managed to navigate to the route this post check is for but is not assigned to this route
  const getRoute = async () => {
    let currentRouteFromUser = user.routes.filter((route: IRoute) => {
      return route.id === Number(id);
    })[0];

    if (!currentRouteFromUser) {
      navigate('/');
      return;
    }

    let vehicleID = currentRouteFromUser.vehicle_id;
    // get vehicle info for route - post check is dependent on vehicle type
    let { data: vehicleInfo, error: vehicleError } = await supabase
    .from('vehicles')
    .select()
    .eq('id', vehicleID);
    
    currentRouteFromUser.vehicleInfo = vehicleInfo?.[0];

    if (currentRouteFromUser.vehicleInfo.electric_range) {
      standardCheckListItems.push('Plug in the vehicle for charging');
    }
    if (currentRouteFromUser.vehicleInfo.license_required === 'cdl') {
      setCheckListItems(busCheckListItems);
    }

    if (currentRouteFromUser.vehicleInfo.license_required !== 'cdl') {
      setCheckListItems(standardCheckListItems);
    }
    
    setRouteInfo(currentRouteFromUser);
    // include link back to navigation in the event user navigates to post check before they are done running route
    if (currentRouteFromUser.type === 'standard') {
      setMapsUrl(generateUrlForGoogleMaps(currentRouteFromUser.data));
    }

    if (currentRouteFromUser.type === 'event') {
      setMapsUrl(generateUrlForGoogleMapsDriverEvent(currentRouteFromUser.data));
    }

    setRouteLoaded(true);
  };

  useEffect(() => {
    if (!routeLoaded && user.routes) {
      getRoute();
    }
  }, [routeLoaded, user]);

  // if checklist changes, see if all items are checked
  useEffect(() => {
    if (postcheck.length === checkListItems.length) setAllChecked(true);
    else setAllChecked(false);
  }, [postcheck, checkListItems]);

  const navigate = useNavigate();

  // when item is clicked, update item in state
  const handlePostcheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const index = postcheck.indexOf(event.target.value);
    if (index === -1) {
      setPostcheck([...postcheck, event.target.value]);
    } else {
      setPostcheck(
        postcheck.filter((postcheck) => postcheck !== event.target.value)
      );
    }
  };

  // open report modal when finish is clicked
  const handleFinishClick = async (event: React.MouseEvent) => {
      setReportModalState(true);
  };

  const busCheckListItems = ['Verify that you have filled out standard paper post check'];

  const standardCheckListItems = [
    'Verify that fuel level is at least 1/2',
    'Check entire vehicle for students or missing articles',
    'Ensure vehicle is clean',
  ];

  const handleIssueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssueText(event.target.value);
  };

  const generateChecklistForAdmin = (checklist: string[]) => {
      let checklistForAdmin = checklist.map( (item) => {
        let newItem = {
          item: item,
          checked: postcheck.includes(item)
        };
        return newItem;
      });
      return checklistForAdmin;
  }


  const finishRouteHandler = async () => {
    // if driver has not added a message, no report is sent - driver must check all boxes to continue
    if (issueText !== '') {
      let newAdminReport = {
        reporter: routeInfo.driver_id,
        vehicle: routeInfo.vehicle_id,
        message: issueText,
        checklist: JSON.stringify(generateChecklistForAdmin(checkListItems)),
        route: Number(id)
      };

      // returning: minimal is required - drivers have insert but not read on reports table per RLS policy in supabase
      const { data, error } = await supabase
        .from('reports')
        .insert([newAdminReport], { returning: 'minimal' });

      if (data) console.log('reponse', data);
      if (error) console.log('reponse', error);
    }

    // update route to set in progress to false and complete to true
    const { data, error } = await supabase
      .from('routes')
      .update({ in_progress: false, complete: true })
      .eq('id', id);

    console.log('response', data || error);
    if (data) {
      // stop geolocation tracking, update user routes to reflect change to complete
      updateUserRoutes(user);
      cancelWatchLocation();
    }

    // navigate back to driver home
    navigate('/');
  };

  return (
    <div className='driver-app-parent'>
      <Header />
      <a href={mapsUrl} target="__blank">
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginTop: '1.5em', marginBottom: '3em' }}
        >
          Reopen Navigation
        </Button>
      </a>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ margin: '0 1em' }}
      >
        <FormControl>
          <FormLabel id="form-label-heading">Post Check</FormLabel>
          <FormGroup>
            {checkListItems.map((item) => {
              return (
                <CheckListItem
                  itemText={item}
                  checked={postcheck.includes(item)}
                  handleChange={handlePostcheckChange}
                  key={item}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleFinishClick}
          id="check-list-button"
        >
          Finish Route
        </Button>
        {reportModalState && (
          <FinishCheckModal
            open={reportModalState}
            setOpen={() => setReportModalState(false)}
          >
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: '1em',
                boxShadow: 24,
                p: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                sx={{
                  color: 'black',
                  marginBottom: '1em',
                  alignSelf: 'flex-end',
                }}
                onClick={() => setReportModalState(false)}
              >
                X
              </Button>
              {allChecked ? (
                <p>
                  You're all set. Include a message (optional). Click Finish to
                  end the route.
                </p>
              ) : (
                <p>
                  You have not checked all items. Close and check all items or
                  include a note to administartor.
                </p>
              )}
              <TextField
                id="outlined-textarea"
                label="Message to admin"
                placeholder="Include a message to admin"
                value={issueText}
                multiline
                rows={5}
                onChange={handleIssueChange}
              />
              {!allChecked && issueText && (
                <Button
                  variant="contained"
                  onClick={finishRouteHandler}
                  color="secondary"
                  sx={{ marginTop: '1.5em' }}
                >
                  Finish and Send
                </Button>
              )}
              {allChecked && (
                <Button
                  variant="contained"
                  onClick={finishRouteHandler}
                  color="secondary"
                  sx={{ marginTop: '1.5em' }}
                >
                  <span>Finish {issueText ? 'and Send' : ''}</span>
                </Button>
              )}
            </Box>
          </FinishCheckModal>
        )}
      </Box>
    </div>
  );
};

export default PostCheck;
