import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/Auth';

import { useNavigate } from 'react-router-dom';

import { supabase } from '../../db/client';
import {
  Button,
  Box,
  Modal,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { TextInput } from '../../components';

import { IDriver, IVehicle } from '../../ts/interfaces';

import {
  dateObjToUTCTime,
  dateObjToSBFormat,
  formatDate,
  getExactStart,
  formatTime,
} from '../../utils/dateTimeHelpers';

import { updateDriverByEmail } from '../../utils/sendGridMethods';
import {
  streetAddressToLatLong,
  distanceMatrixCall,
  metersToMiles,
} from '../../utils/geolocation';
import { sub, add } from 'date-fns';

type FieldTripCardProps = {
  fieldTrip: any;
  tableNames: any;
  updateFieldTrips: () => void;
  user: any;
  drivers: IDriver[];
  vehicles: IVehicle[];
  district: any;
  updateAllRoutes: () => void;
};

const FieldTripCard = ({
  fieldTrip,
  tableNames,
  updateFieldTrips,
  user,
  drivers,
  vehicles,
  district,
  updateAllRoutes,
}: FieldTripCardProps) => {
  const [editFieldTripModal, setEditFieldTripModal] = useState(false);
  const [fieldTripCopy, setFieldTripCopy] = useState<any>();
  const [addChaperone, setAddChaperone] = useState(false);
  const [newChaperone, setNewChaperone] = useState({ name: '' });
  const [errorText, setErrorText] = useState('');
  const [newRouteInfo, setNewRouteInfo] = useState<any>();
  const [routeInputError, setRouteInputError] = useState('');
  const [removeTripModal, setRemoveTripModal] = useState(false);
  const [removeFieldTripModal, setRemoveFieldTripModal] = useState(false);

  const navigate = useNavigate();

  const handleDriverChange = (event: SelectChangeEvent) => {
    let selectedDriver = drivers.find((driver: any) => {
      return driver.id === event.target.value;
    });

    setNewRouteInfo({
      ...newRouteInfo,
      driver: selectedDriver,
    });
  };

  const handleVehicleChange = (event: SelectChangeEvent) => {
    let selectedVehicle = vehicles.find((vehicle: any) => {
      return vehicle.id === event.target.value;
    });

    setNewRouteInfo({
      ...newRouteInfo,
      vehicle: selectedVehicle,
    });
  };

  const setFieldTripEditFormat = () => {
    let dateObj = null;
    // date obj created with time of 23:00:00 since we are always -N hours UTC, more care needs to be taken in the future with handling
    // dates to account for situations where UTC time is actually the next calendar day from date/time set
    if (fieldTrip.date) dateObj = getExactStart(fieldTrip.date, '23:00:00');
    let startTime = null;
    if (fieldTrip.start_time)
      startTime = getExactStart(fieldTrip.date, fieldTrip.start_time);
    let endTime = null;
    if (fieldTrip.end_time)
      endTime = getExactStart(fieldTrip.date, fieldTrip.end_time);

    let routeTime = getExactStart(fieldTrip.date, fieldTrip.route_time);

    setFieldTripCopy({
      title: fieldTrip.title,
      date: dateObj,
      start_time: startTime,
      end_time: endTime,
      location: fieldTrip.location,
      stop_instructions: fieldTrip.stop_instructions,
      id: fieldTrip.id,
      chaperones: fieldTrip.chaperones,
      route_id: fieldTrip.route_id,
      arrive_by: fieldTrip.arrive_by,
      route_time: routeTime,
    });
  };

  const editFieldTripHandler = () => {
    setFieldTripEditFormat();
    setEditFieldTripModal(true);
  };

  const updateFieldTrip = async () => {
    // require this data
    if (
      !fieldTripCopy.title ||
      !fieldTripCopy.date ||
      !fieldTripCopy.location ||
      !fieldTripCopy.start_time ||
      !fieldTripCopy.route_time ||
      !fieldTripCopy.end_time
    ) {
      setErrorText(
        'Please enter required information'
      );
    } else {
      // convert start and end time, date, to values needed for supabase
      let UTCStart = dateObjToUTCTime(fieldTripCopy.start_time);
      let dateFormat = dateObjToSBFormat(fieldTripCopy.date);
      let UTCEnd = dateObjToUTCTime(fieldTripCopy.end_time);

      let UTCRouteTime = dateObjToUTCTime(fieldTripCopy.route_time);

      // pull other values from state
      let { title, location, stop_instructions, chaperones, arrive_by } = fieldTripCopy;

      const { data, error } = await supabase
        .from(tableNames.field_trips)
        .update({
          title,
          location,
          stop_instructions,
          start_time: UTCStart,
          end_time: UTCEnd,
          route_time: UTCRouteTime,
          date: dateFormat,
          arrive_by,
          chaperones,
        })
        .eq('id', fieldTrip.id);

      console.log('response from update field trip', data || error);

      if (data) {
        let updatedFieldTrip = data[0];

        // if this field trip already had a route assigned...
        if (updatedFieldTrip.route_id) {
          let { data: route, error } = await supabase
            .from('routes')
            .select()
            .eq('id', updatedFieldTrip.route_id)
            .single();

          console.log('response from get route assigned to this field trip', route || error);
          let fieldTripStartTime = getExactStart(
            updatedFieldTrip.date,
            updatedFieldTrip.start_time
          );
          let fieldTripEndTime = getExactStart(
            updatedFieldTrip.date,
            updatedFieldTrip.end_time
          );
          let fieldTripRouteTime = getExactStart(
            updatedFieldTrip.date,
            fieldTrip.route_time
          );

          // reset route times based on change of field trip time if necessary
          let duration = route.est_duration;

          let routeStartTime = getExactStart(route.date, route.start_time);
          let routeEndTime = getExactStart(route.date, route.end_time);

          if (updatedFieldTrip.arrive_by) {
            routeStartTime = sub(fieldTripRouteTime, {
              minutes: duration,
            });
            routeEndTime = add(fieldTripEndTime, {
              minutes: duration,
            });
          } else {
            routeStartTime = fieldTripRouteTime;
            routeEndTime = add(fieldTripEndTime, {
              minutes: duration,
            });
          }

          const { data: updatedRoute, error: routeError } = await supabase
            .from('routes')
            .update({
              start_time: dateObjToUTCTime(routeStartTime),
              end_time: dateObjToUTCTime(routeEndTime),
            })
            .eq('id', route.id);

          let driver = drivers.find( (driver: any) => {
            return driver.id === route.driver_id;
          })
    
          console.log('response from update route', updatedRoute || error);

          if (updatedRoute) {
            // when route is updated, send driver email
            let response = await updateDriverByEmail(
              updatedRoute[0],
              driver
            );
            console.log(response);
  
            setFieldTripCopy(null);
            updateFieldTrips();
            updateAllRoutes();
            setEditFieldTripModal(false);
          }
        }        
      }
    }
  };

  const setBlankRoute = async () => {
    let streetAddress = district.depot;
    let response = await streetAddressToLatLong(streetAddress);

    let blankRoute = {
      date: fieldTrip.date,
      type: 'field_trip',
      stop_instructions: fieldTrip.stop_instructions,
      stop_count: 1,
      district_id: user.district_id,
      driver: null,
      vehicle: null,
      start_time: null,
      end_time: null,
      students_count: null,
      data: [
        {
          street_address: '',
        },
        {
          end: {
            lat_long: response,
            street_address: district.depot,
          },
          start: {
            lat_long: response,
            street_address: district.depot,
          },
        },
      ],
    };
    setNewRouteInfo(blankRoute);
  };

  const createRoute = async () => {
    // require this data for route
    if (
      !newRouteInfo.driver ||
      !newRouteInfo.vehicle ||
      !newRouteInfo.data[0].street_address ||
      !newRouteInfo.students_count
    ) {
      setRouteInputError('Please enter all required information');
      return;
    }

    let fieldTripStartTime = getExactStart(
      fieldTrip.date,
      fieldTrip.start_time
    );
    let fieldTripEndTime = getExactStart(fieldTrip.date, fieldTrip.end_time);

    let fieldTripRouteTime = getExactStart(
      fieldTrip.date,
      fieldTrip.route_time
    );

    // get lat long from street address entered
    let latLong = await streetAddressToLatLong(
      newRouteInfo.data[0].street_address
    );
    newRouteInfo.data[0].lat_long = latLong;

    // get distance and time
    let response = await distanceMatrixCall(
      newRouteInfo.data[1].start.lat_long,
      latLong
    );

    let distanceInMeters = response.rows[0].elements[0].distance.value;
    let timeInSeconds = response.rows[0].elements[0].duration.value;

    let distance = metersToMiles(distanceInMeters);
    let est_duration = (timeInSeconds / 60).toFixed(0);

    let routeStartTime: any = null;
    let routeEndTime: any = null;
    // if field trip was created with a time to arrive by, subtract duration
    if (fieldTrip.arrive_by) {
      routeStartTime = sub(fieldTripRouteTime, {
        seconds: timeInSeconds,
      });
      routeEndTime = add(fieldTripEndTime, {
        seconds: timeInSeconds,
      });
    } else {
      routeStartTime = fieldTripRouteTime;
      routeEndTime = add(fieldTripEndTime, {
        seconds: timeInSeconds,
      });
    }

    let UTCStart: string = dateObjToUTCTime(routeStartTime);
    let UTCEnd: string = dateObjToUTCTime(routeEndTime);

    const routeFormat = {
      date: newRouteInfo.date,
      data: newRouteInfo.data,
      distance,
      district_id: newRouteInfo.district_id,
      driver_id: newRouteInfo.driver.id,
      end_time: UTCEnd,
      est_duration,
      field_trip_id: fieldTrip.id,
      start_time: UTCStart,
      stop_count: newRouteInfo.stop_count,
      students_count: newRouteInfo.students_count,
      type: newRouteInfo.type,
      vehicle_id: newRouteInfo.vehicle.id,
    };

    const { data: newRoute, error } = await supabase
      .from(tableNames.routes)
      .insert([routeFormat]);

    if (newRoute?.[0]) {
      const { data: updatedFieldTrip, error } = await supabase
        .from(tableNames.field_trips)
        .update({ route_id: newRoute?.[0].id })
        .eq('id', fieldTrip.id);

      let response = await updateDriverByEmail(
        newRoute?.[0],
        newRouteInfo.driver
      );
      console.log(response);
      if (updatedFieldTrip) {
        updateFieldTrips();
        updateAllRoutes();
        setNewRouteInfo(null);
      }
    }
  };

  const removeTripHandler = async () => {
    // remove transportation for field trip
    let routeToRemove = fieldTrip.route_id;
    console.log('route to remove is ', routeToRemove);

    // set route id for field trip to null
    const { data: updatedFieldTrip, error } = await supabase
      .from(tableNames.field_trips)
      .update({ route_id: null })
      .eq('id', fieldTrip.id);

    console.log('response from nullify route id', updatedFieldTrip || error);
    // if null successful, delete route created for field trip
    if (updatedFieldTrip) {
      const { data: deletedRoute, error } = await supabase
        .from(tableNames.routes)
        .delete()
        .eq('id', routeToRemove);

      console.log('response from delete route', deletedRoute || error);
      if (deletedRoute) {
        updateFieldTrips();
        updateAllRoutes();
        setRemoveTripModal(false);
      }
    }
  };

  const removeFieldTripHandler = async () => {
    let fieldTripID = fieldTrip.id;
    let routeID = fieldTrip.route_id;

    // if field trip to remove had no route assigned, delete field trip
    if (!routeID) {
      const { data: deletedFieldTrip, error } = await supabase
        .from(tableNames.field_trips)
        .delete()
        .eq('id', fieldTripID);

      console.log('response from delete field trip', deletedFieldTrip);
      if (deletedFieldTrip) {
        updateFieldTrips();
        setRemoveFieldTripModal(false);
        setEditFieldTripModal(false);
      }
    }

    // if field trip to remove had route assigned, remove assigned route id from field trip and field trip id from route
    if (routeID) {
      const { data: updatedFieldTrip, error: fieldTripError } = await supabase
        .from(tableNames.field_trips)
        .update({ route_id: null })
        .eq('id', fieldTripID);

      const { data: updatedRoute, error: routeError } = await supabase
        .from(tableNames.routes)
        .update({ field_trip_id: null })
        .eq('id', routeID);

        // then delete both field trip and route
      if (updatedFieldTrip && updatedRoute) {
        const { data: deletedFieldTrip, error: fieldTripDeleteError } =
          await supabase
            .from(tableNames.field_trips)
            .delete()
            .eq('id', fieldTripID);

        const { data: deletedRoute, error: routeDeleteError } = await supabase
          .from(tableNames.routes)
          .delete()
          .eq('id', routeID);

        if (deletedFieldTrip && deletedRoute) {
          updateFieldTrips();
          setRemoveFieldTripModal(false);
          setEditFieldTripModal(false);
        }
      }
    }
  };

  useEffect(() => {
    setErrorText('');
  }, [fieldTripCopy]);

  return (
    <>
      <Paper
        elevation={2}
        className="field-trip-details"
        sx={{
          borderRadius: '10px',
          textAlign: 'center',
          margin: 3,
        }}
      >
        <h3 style={{ marginBottom: 0, paddingTop: '.5em' }}>
          {fieldTrip.title}
        </h3>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'red',
            fontSize: '.7em',
            marginTop: 0,
            ':hover': {
              bgcolor: 'maroon',
              color: 'white',
            },
          }}
          onClick={editFieldTripHandler}
        >
          Edit
        </Button>
        <p>
          <b>{formatDate(getExactStart(fieldTrip.date, '23:00:00'))}</b>
        </p>
        <Box
          sx={{
            textAlign: 'left',
            marginLeft: '2em',
          }}
        >
          <p>
            <b>{fieldTrip.arrive_by ? 'Arrive By' : 'Depart At'}</b> :{' '}
            {fieldTrip.route_time
              ? formatTime(getExactStart(fieldTrip.date, fieldTrip.route_time))
              : 'Not set'}
          </p>
          <p>
            <b>Location: </b>
            {fieldTrip.location}
          </p>
          <p>
            <b>Start Time: </b>
            {fieldTrip.start_time
              ? formatTime(getExactStart(fieldTrip.date, fieldTrip.start_time))
              : 'Not listed'}
          </p>
          <p>
            <b>End Time: </b>
            {fieldTrip.end_time
              ? formatTime(getExactStart(fieldTrip.date, fieldTrip.end_time))
              : 'Not listed'}
          </p>
          <p>
            <b>Stop Instructions: </b>
            {fieldTrip.stop_instructions || 'None'}
          </p>
          <p style={{ textAlign: 'center', textDecoration: 'underline' }}>
            <b>Chaperones</b>
          </p>
          {fieldTrip.chaperones.length === 0 && (
            <p style={{ textAlign: 'center' }}>None</p>
          )}
          {fieldTrip.chaperones.length > 0 &&
            fieldTrip.chaperones.map((chaperone: any, index: number) => {
              return (
                <p style={{ textAlign: 'center' }} key={index}>
                  {chaperone.name}
                </p>
              );
            })}
        </Box>
        {!fieldTrip.route_id && !newRouteInfo && (
          <Button
            variant="contained"
            sx={{ marginBottom: '2em' }}
            onClick={setBlankRoute}
          >
            Schedule Transportation
          </Button>
        )}
        {newRouteInfo ? (
          <>
            <Box sx={{ width: '80%', margin: '0 auto' }}>
              <h3>Route Details</h3>
              <FormControl fullWidth sx={{ margin: '1em auto' }}>
                <InputLabel id="driver-select">Driver</InputLabel>

                <Select
                  labelId="driver-select"
                  id="driver-select-input"
                  value={newRouteInfo.driver?.id}
                  label="Driver"
                  onChange={handleDriverChange}
                  sx={{ marginBottom: '2em' }}
                  MenuProps={{ color: 'red' }}
                >
                  {drivers.map((driver: any) => {
                    return (
                      <MenuItem key={driver.id} value={driver.id}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <p>
                            {driver.fname} {driver.lname}
                          </p>
                          <p>ID: {driver.id}</p>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ margin: '0 auto' }}>
                <InputLabel id="vehicle-select">Vehicle</InputLabel>

                <Select
                  labelId="vehicle-select"
                  id="vehicle-select-input"
                  value={newRouteInfo.vehicle?.id}
                  label="Vehicle"
                  onChange={handleVehicleChange}
                  sx={{ marginBottom: '2em' }}
                >
                  {vehicles.map((vehicle: any) => {
                    return (
                      <MenuItem key={vehicle.id} value={vehicle.id}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <p>{vehicle.identifier}</p>
                          <p>ID: {vehicle.id}</p>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextInput
                label="Destination Address"
                value={newRouteInfo.data[0].street_address}
                changeHandler={(event) => {
                  let dataCopy = newRouteInfo.data;
                  dataCopy[0].street_address = event.target.value;
                  setNewRouteInfo({
                    ...newRouteInfo,
                    data: dataCopy,
                  });
                }}
              />
              <p style={{ marginTop: '0', color: 'gray', textAlign: 'left' }}>
                (valid street address where vehicle will stop)
              </p>
              {newRouteInfo.data[0].street_address !== fieldTrip.location && (
                <Box sx={{ textAlign: 'left' }}>
                  <Button
                    onClick={() => {
                      let dataCopy = newRouteInfo.data;
                      dataCopy[0].street_address = fieldTrip.location;

                      setNewRouteInfo({
                        ...newRouteInfo,
                        data: dataCopy,
                      });
                    }}
                  >
                    Use Field Trip Location
                  </Button>
                </Box>
              )}
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                  textAlign: 'left',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="students-input"
                  label="Total Passengers"
                  variant="standard"
                  value={newRouteInfo.students_count}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(event) => {
                    let number = Number(event.target.value);
                    if (!isNaN(number) && number < 100) {
                      setNewRouteInfo({
                        ...newRouteInfo,
                        students_count: number,
                      });
                    }
                  }}
                />
              </Box>
              {routeInputError && (
                <p style={{ color: 'red' }}>{routeInputError}</p>
              )}
              <Button
                variant="contained"
                onClick={createRoute}
                sx={{ marginBottom: '2em' }}
              >
                Create
              </Button>
            </Box>
          </>
        ) : (
          <>
            {fieldTrip.route_id && (
              <Box
                sx={{
                  paddingBottom: '2em',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(`/admin/routes/${fieldTrip.route_id}`)
                  }
                >
                  Scheduled Transportation
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    ':hover': {
                      bgcolor: 'maroon',
                      color: 'white',
                    },
                  }}
                  onClick={() => setRemoveTripModal(true)}
                >
                  Remove Transportation
                </Button>
              </Box>
            )}
          </>
        )}
      </Paper>
      {removeFieldTripModal && (
        <Modal
          open={removeFieldTripModal}
          onClose={() => setRemoveFieldTripModal(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: 400 },
              bgcolor: 'background.paper',
              border: '2px solid #000',
              borderRadius: '10px',
              boxShadow: 24,
              paddingInline: '2em',
            }}
            textAlign="center"
          >
            <p>Are you sure you want to remove this field trip?</p>
            <Button
              variant="contained"
              sx={{ marginBottom: '1em' }}
              onClick={removeFieldTripHandler}
            >
              Remove
            </Button>
          </Box>
        </Modal>
      )}
      {removeTripModal && (
        <Modal open={removeTripModal} onClose={() => setRemoveTripModal(false)}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: 400 },
              bgcolor: 'background.paper',
              border: '2px solid #000',
              borderRadius: '10px',
              boxShadow: 24,
              paddingInline: '2em',
            }}
            textAlign="center"
          >
            <p>
              Are you sure you want to remove the scheduled transportation for
              this field trip?
            </p>
            <Button
              variant="contained"
              sx={{ marginBottom: '1em' }}
              onClick={removeTripHandler}
            >
              Remove Trip
            </Button>
          </Box>
        </Modal>
      )}

      {editFieldTripModal && (
        <Modal
          open={editFieldTripModal}
          onClose={() => setEditFieldTripModal(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: 400 },
              bgcolor: 'background.paper',
              border: '2px solid #000',
              borderRadius: '10px',
              boxShadow: 24,
              paddingInline: '2em',
              maxHeight: '90vh',
              overflow: 'scroll',
            }}
            textAlign="center"
          >
            <h2 style={{ marginBottom: 0 }}>Edit Field Trip</h2>
            <Button
              sx={{ color: 'red' }}
              onClick={() => setRemoveFieldTripModal(true)}
            >
              Remove
            </Button>
            <Box sx={{ marginTop: '1em' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={fieldTripCopy.date}
                  onChange={(newValue) => {
                    if (newValue)
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        date: newValue,
                      });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box style={{ margin: 0 }}>
              <TextInput
                label="Title"
                value={fieldTripCopy.title}
                changeHandler={(event) =>
                  setFieldTripCopy({
                    ...fieldTripCopy,
                    title: event.target.value,
                  })
                }
              />
            </Box>
            <TextInput
              label="Location"
              value={fieldTripCopy.location}
              changeHandler={(event) =>
                setFieldTripCopy({
                  ...fieldTripCopy,
                  location: event.target.value,
                })
              }
            />

            <TextInput
              label="Stop Instructions"
              value={fieldTripCopy.stop_instructions}
              changeHandler={(event) =>
                setFieldTripCopy({
                  ...fieldTripCopy,
                  stop_instructions: event.target.value,
                })
              }
            />

            <p style={{ fontWeight: 'bold' }}>Chaperones</p>
            {fieldTripCopy.chaperones.map((chaperone: any, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p style={{ margin: 0 }}>{chaperone.name}</p>
                  <Button
                    sx={{ color: 'red' }}
                    onClick={() => {
                      console.log(fieldTripCopy.chaperones[index]);
                      let newChaperonesArray = [...fieldTripCopy.chaperones];
                      newChaperonesArray.splice(index, 1);
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        chaperones: newChaperonesArray,
                      });
                    }}
                  >
                    X
                  </Button>
                </Box>
              );
            })}

            {addChaperone && (
              <>
                <TextInput
                  label="Chaperone Name"
                  value={newChaperone.name}
                  changeHandler={(event) => {
                    setNewChaperone({ name: event.target.value });
                  }}
                />
                <Button
                  onClick={() => {
                    if (newChaperone.name) {
                      let newChaperoneArray = [
                        ...fieldTripCopy.chaperones,
                        newChaperone,
                      ];
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        chaperones: newChaperoneArray,
                      });
                      setAddChaperone(false);
                      setNewChaperone({ name: '' });
                    }
                  }}
                >
                  Add
                </Button>
              </>
            )}
            {!addChaperone && (
              <Button onClick={() => setAddChaperone(true)}>
                Add Chaperone
              </Button>
            )}

            <Box sx={{ marginTop: '2em' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  label="Start Time"
                  value={fieldTripCopy.start_time}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        start_time: newValue,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '150px' }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginTop: '2em' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  label="End Time"
                  value={fieldTripCopy.end_time}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        end_time: newValue,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '150px' }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="arrive-by-label">Route Time</FormLabel>
                <RadioGroup
                  aria-labelledby="arrive-by-radio-group"
                  value={fieldTripCopy.arrive_by ? 'arriveBy' : 'departAt'}
                  name="radio-buttons-group"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    console.log(value);
                    // setValue((event.target as HTMLInputElement).value);
                    if (value === 'arriveBy')
                      setFieldTripCopy({ ...fieldTripCopy, arrive_by: true });
                    else
                      setFieldTripCopy({
                        ...fieldTripCopy,
                        arrive_by: false,
                      });
                  }}
                >
                  <FormControlLabel
                    value={'arriveBy'}
                    control={<Radio />}
                    label="Arrive By"
                  />
                  <FormControlLabel
                    value="departAt"
                    control={<Radio />}
                    label="Depart At"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Route Time"
                value={fieldTripCopy.route_time}
                onChange={(newValue) => {
                  if (newValue) {
                    setFieldTripCopy({
                      ...fieldTripCopy,
                      route_time: newValue,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '150px' }} />
                )}
              />
            </LocalizationProvider>
            <br />
            {errorText && (
              <p style={{ color: 'red', fontSize: '.8em' }}>{errorText}</p>
            )}
            <Button
              variant="contained"
              onClick={updateFieldTrip}
              sx={{ margin: '1em 0' }}
            >
              Update Field Trip
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

type AdminFieldTripsProps = {
  tableNames: any;
  drivers: IDriver[];
  vehicles: IVehicle[];
  district: any;
  updateAllRoutes: () => void;
};

// page to create field trips - needs redesign
const AdminFieldTrips = ({
  tableNames,
  drivers,
  vehicles,
  district,
  updateAllRoutes,
}: AdminFieldTripsProps) => {
  const [allFieldTrips, setAllFieldTrips] = useState<any>();
  const [newFieldTrip, setNewFieldTrip] = useState<any>();
  const [newFieldTripModal, setNewFieldTripModal] = useState(false);
  const [addChaperone, setAddChaperone] = useState(false);
  const [newChaperone, setNewChaperone] = useState({ name: '' });
  const [errorText, setErrorText] = useState('');

  const { user } = useAuth();

  const getFieldTrips = async () => {
    let { data, error } = await supabase
      .from(tableNames.field_trips)
      .select()
      .eq('district_id', user.district_id)
      .order('date');

    console.log('response from get field trips', data || error);

    if (data) {
      setAllFieldTrips(data);
    }
  };

  const blankFieldTrip: any = {
    title: '',
    date: null,
    start_time: null,
    end_time: null,
    location: '',
    stop_instructions: '',
    chaperones: [],
    arrive_by: true,
    route_time: null,
  };

  const addFieldTripHandler = async () => {
    console.log('Field trip data', newFieldTrip);

    if (
      !newFieldTrip.title ||
      !newFieldTrip.date ||
      !newFieldTrip.location ||
      !newFieldTrip.start_time ||
      !newFieldTrip.end_time ||
      !newFieldTrip.route_time
    ) {
      setErrorText('Please enter required information');
      return;
    } else {
      let UTCStart = dateObjToUTCTime(newFieldTrip.start_time);
      let dateFormat = dateObjToSBFormat(newFieldTrip.date);
      let UTCEnd = dateObjToUTCTime(newFieldTrip.end_time);

      let UTCRouteTime = dateObjToUTCTime(newFieldTrip.route_time);
      let { title, location, stop_instructions, chaperones, arrive_by } =
        newFieldTrip;

      const { data, error } = await supabase
        .from(tableNames.field_trips)
        .insert([
          {
            title,
            location,
            stop_instructions,
            start_time: UTCStart,
            end_time: UTCEnd,
            route_time: UTCRouteTime,
            date: dateFormat,
            arrive_by,
            district_id: user.district_id,
            chaperones,
          },
        ]);

      console.log('reponse from field trip creation', data || error);

      if (data) {
        setNewFieldTripModal(false);
        setNewFieldTrip(blankFieldTrip);
        getFieldTrips();
      }
    }
  };

  useEffect(() => {
    setErrorText('');
  }, [newFieldTrip]);

  useEffect(() => {
    getFieldTrips();
  }, []);

  return (
    <div className="admin-app-parent">
      <h1 style={{ textAlign: 'center', marginTop: '3em' }}>Field Trips</h1>
      {allFieldTrips && (
        <>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={() => {
                setNewFieldTrip(blankFieldTrip);
                setNewFieldTripModal(true);
              }}
            >
              Add Field Trip
            </Button>
          </Box>
          {allFieldTrips.map((fieldTrip: any) => {
            return (
              <FieldTripCard
                key={fieldTrip.id}
                fieldTrip={fieldTrip}
                tableNames={tableNames}
                updateFieldTrips={getFieldTrips}
                user={user}
                drivers={drivers}
                vehicles={vehicles}
                district={district}
                updateAllRoutes={updateAllRoutes}
              />
            );
          })}
          {newFieldTripModal && (
            <Modal
              open={newFieldTripModal}
              onClose={() => setNewFieldTripModal(false)}
            >
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '80%', md: 400 },
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  borderRadius: '10px',
                  boxShadow: 24,
                  paddingInline: '2em',
                  maxHeight: '90vh',
                  overflow: 'scroll',
                }}
                textAlign="center"
              >
                <h2 style={{ marginBottom: 0 }}>New Field Trip</h2>
                <Box sx={{ marginTop: '1em' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date"
                      value={newFieldTrip.date}
                      onChange={(newValue) => {
                        if (newValue)
                          setNewFieldTrip({
                            ...newFieldTrip,
                            date: newValue,
                          });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
                <Box style={{ margin: 0 }}>
                  <TextInput
                    label="Title"
                    value={newFieldTrip.title}
                    changeHandler={(event) =>
                      setNewFieldTrip({
                        ...newFieldTrip,
                        title: event.target.value,
                      })
                    }
                  />
                </Box>
                <TextInput
                  label="Location"
                  value={newFieldTrip.location}
                  changeHandler={(event) =>
                    setNewFieldTrip({
                      ...newFieldTrip,
                      location: event.target.value,
                    })
                  }
                />

                <TextInput
                  label="Stop Instructions"
                  value={newFieldTrip.stop_instructions}
                  changeHandler={(event) =>
                    setNewFieldTrip({
                      ...newFieldTrip,
                      stop_instructions: event.target.value,
                    })
                  }
                />

                <p style={{ fontWeight: 'bold' }}>Chaperones</p>
                {newFieldTrip.chaperones.map(
                  (chaperone: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: 0 }}>{chaperone.name}</p>
                        <Button
                          sx={{ color: 'red' }}
                          onClick={() => {
                            console.log(newFieldTrip.chaperones[index]);
                            let newChaperoneArray = [
                              ...newFieldTrip.chaperones,
                            ];
                            newChaperoneArray.splice(index, 1);
                            setNewFieldTrip({
                              ...newFieldTrip,
                              chaperones: newChaperoneArray,
                            });
                          }}
                        >
                          X
                        </Button>
                      </Box>
                    );
                  }
                )}

                {addChaperone && (
                  <>
                    <TextInput
                      label="Chaperone Name"
                      value={newChaperone.name}
                      changeHandler={(event) => {
                        setNewChaperone({ name: event.target.value });
                      }}
                    />
                    <Button
                      onClick={() => {
                        if (newChaperone.name) {
                          let newChaperoneArray = [
                            ...newFieldTrip.chaperones,
                            newChaperone,
                          ];
                          setNewFieldTrip({
                            ...newFieldTrip,
                            chaperones: newChaperoneArray,
                          });
                          setAddChaperone(false);
                          setNewChaperone({ name: '' });
                        }
                      }}
                    >
                      Add
                    </Button>
                  </>
                )}
                {!addChaperone && (
                  <Button onClick={() => setAddChaperone(true)}>
                    Add Chaperone
                  </Button>
                )}

                <Box sx={{ marginTop: '2em' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="Start Time"
                      value={newFieldTrip.start_time}
                      onChange={(newValue) => {
                        if (newValue) {
                          setNewFieldTrip({
                            ...newFieldTrip,
                            start_time: newValue,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '150px' }} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ marginTop: '2em' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="End Time"
                      value={newFieldTrip.end_time}
                      onChange={(newValue) => {
                        if (newValue) {
                          setNewFieldTrip({
                            ...newFieldTrip,
                            end_time: newValue,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '150px' }} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel id="arrive-by-label">Route Time</FormLabel>
                    <RadioGroup
                      aria-labelledby="arrive-by-radio-group"
                      value={newFieldTrip.arrive_by ? 'arriveBy' : 'departAt'}
                      name="radio-buttons-group"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let value = event.target.value;
                        console.log(value);
                        // setValue((event.target as HTMLInputElement).value);
                        if (value === 'arriveBy')
                          setNewFieldTrip({ ...newFieldTrip, arrive_by: true });
                        else
                          setNewFieldTrip({
                            ...newFieldTrip,
                            arrive_by: false,
                          });
                      }}
                    >
                      <FormControlLabel
                        value={'arriveBy'}
                        control={<Radio />}
                        label="Arrive By"
                      />
                      <FormControlLabel
                        value="departAt"
                        control={<Radio />}
                        label="Depart At"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label="Route Time"
                    value={newFieldTrip.route_time}
                    onChange={(newValue) => {
                      if (newValue) {
                        setNewFieldTrip({
                          ...newFieldTrip,
                          route_time: newValue,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>
                <br />
                {errorText && (
                  <p style={{ color: 'red', fontSize: '.8em' }}>{errorText}</p>
                )}
                <Button
                  variant="contained"
                  onClick={addFieldTripHandler}
                  sx={{ margin: '1em 0' }}
                >
                  Add Field Trip
                </Button>
              </Box>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default AdminFieldTrips;
