import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paper, Box, Button, FormControlLabel, Checkbox } from '@mui/material';
import { IStudent, IContact } from '../../ts/interfaces';

import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  capitalize,
  phoneNumberToString,
} from '../../utils/textFormattingHelpers';

type ContactCardProps = {
  contact: IContact;
};

const ContactCard = ({ contact }: ContactCardProps) => {
  const { fname, lname, phone, relation } = contact;

  return (
    <>
      <Paper
        elevation={5}
        className="student-details"
        sx={{
          borderRadius: '10px',
          width: '40%',
          padding: '1em 0 1em',
          margin: '1em',
          textAlign: 'center',
        }}
      >
        <p>
          <b>Name: </b>
          {fname} {lname}
        </p>
        <p>
          <b>Phone: </b>
          {phoneNumberToString(phone)}
        </p>
        <p>
          <b>Relation: </b>
          {relation || 'Not on file'}
        </p>
      </Paper>
    </>
  );
};

type BaselineAddressesProps = {
  baselineAddresses: any;
};

const BaselineAddresses = ({ baselineAddresses }: BaselineAddressesProps) => {
  // logic to check if students have same address daily to simplify display
  let checkAddressAM = baselineAddresses[0].am;
  let checkAddressPM = baselineAddresses[0].pm;
  let weekdays = true;

  for (let i = 1; i < 5; i++) {
    if (
      baselineAddresses[i].am !== checkAddressAM ||
      baselineAddresses[i].pm !== checkAddressPM
    ) {
      weekdays = false;
    }
  }

  let weekends = true;

  if (
    baselineAddresses[5].am !== baselineAddresses[6].am ||
    baselineAddresses[5].am !== baselineAddresses[6].am
  )
    weekends = false;

  return (
    <>
    {/* if addresses same for every day of week simplify display with Week Days, else display every day */}
      {weekdays ? (
        <>
          <p style={{ fontWeight: 'bold', borderBottom: '1px solid gray' }}>
            Week Days
          </p>
          <p>
            <b>AM: </b>
            {checkAddressAM || 'No AM address'}
          </p>
          <p>
            <b>PM: </b>
            {checkAddressPM || 'No PM address'}
          </p>
        </>
      ) : (
        <>
          {baselineAddresses.slice(0, 5).map((day: any) => {
            return (
              <div key={day.day} style={{ borderBottom: '1px solid grey' }}>
                <p>
                  <b>{capitalize(day.day)}</b>
                </p>
                <p>
                  <b>AM: </b>
                  {day.am || 'No AM address'}
                </p>
                <p>
                  <b>PM: </b>
                  {day.pm || 'No PM address'}
                </p>
              </div>
            );
          })}
        </>
      )}
      {/* same logic as Weekdays */}
      {weekends ? (
        <>
          <p style={{ fontWeight: 'bold', borderBottom: '1px solid grey' }}>
            Weekends
          </p>
          <p>
            <b>AM: </b>
            {baselineAddresses[5].am || 'No AM address'}
          </p>
          <p>
            <b>PM: </b>
            {baselineAddresses[5].pm || 'No PM address'}
          </p>
        </>
      ) : (
        <>
          {baselineAddresses.slice(5).map((day: any) => {
            return (
              <div key={day.day} style={{ borderBottom: '1px solid grey' }}>
                <p>
                  <b>{capitalize(day.day)}</b>
                </p>
                <p>
                  <b>AM: </b>
                  {day.am || 'No AM address'}
                </p>
                <p>
                  <b>PM: </b>
                  {day.pm || 'No PM address'}
                </p>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

type StudentCardProps = {
  student: IStudent;
};

const StudentCard = ({ student }: StudentCardProps) => {
  const navigate = useNavigate();

  // variable styles depending on whether student rides
  const ridesColor = (rides: boolean | undefined) => {
    let style = {
      fontWeight: 'bold',
      color: '',
      fontSize: '1.5em',
      textDecoration: '',
    };

    if (rides) {
      style.color = 'green';
      style.textDecoration = 'underline';
    } else {
      style.color = 'red';
      style.textDecoration = 'line-through';
    }

    return style;
  };

  return (
    <>
      <Paper
        elevation={5}
        className="student-details"
        sx={{
          borderRadius: '30px',
          textAlign: 'left',
          padding: '1em 0 1em',
          margin: '1em',
        }}
      >
        <Box sx={{ margin: 0 }}>
          <h2 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
            {student.fname} {student.lname}
          </h2>
          <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
            Rides: <span style={ridesColor(student.am_transportation)}>AM</span>{' '}
            <span style={ridesColor(student.pm_transportation)}>PM</span>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/admin/students/${student.id}`);
              }}
              sx={{ margin: '0 auto' }}
            >
              Edit
            </Button>
          </div>
          <Box
            sx={{
              borderRadius: '30px',
              padding: { sm: '0 1em 0 1em', md: '1em 2em 1em 2em' },
              outline: { md: '1px solid grey' },
              margin: { md: '1em' },
              lineHeight: 1,
            }}
          >
            <p>
              <b>ID:</b> {student.id}
            </p>
            <p>
              <b>Grade:</b> {student.grade}
            </p>
            <p>
              <b>Resident Address: </b>
              {student.resident_address || 'No Address'}
            </p>
            <p>
              <b>Email: </b>
              {student.email || 'No Email'}
            </p>
            <h3 style={{ textAlign: 'center', textDecoration: 'underline' }}>
              Emergency Contacts
            </h3>
            <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
              {!student.contact1_id && !student.contact2_id && <p>None</p>}
              {student.contact1 && <ContactCard contact={student.contact1} />}
              {student.contact2 && <ContactCard contact={student.contact2} />}
            </Box>
            <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>
              Baseline Addresses
            </h3>
            <BaselineAddresses baselineAddresses={student.baseline_addresses} />
            {student.transportation_address1 && (
              <>
                <h3>
                  Transportation Address
                  {student.transportation_address2 && 'es'}
                </h3>
                <p>{student.transportation_address1}</p>
                {student.transportation_address2 && (
                  <p>{student.transportation_address2}</p>
                )}
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

type AdminStudentsProps = {
  tableNames: any;
};

const AdminStudents = ({ tableNames }: AdminStudentsProps) => {
  const [allStudents, setAllStudents] = useState<IStudent[]>();
  const [filteredStudents, setFilteredStudents] = useState<IStudent[]>();
  const [ridesAM, setRidesAM] = useState(true);
  const [ridesPM, setRidesPM] = useState(true);
  const [bothOnly, setBothOnly] = useState(false);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { user } = useAuth();

  const getAllStudents = async () => {
    let studentSelectString =
      '*, contact1: contact1_id ( * ), contact2: contact2_id ( * )';

    let { data: students, error } = await supabase
      .from(tableNames.students)
      // .from('students')
      .select(studentSelectString)
      .eq('district_id', user.district_id)
      .order('lname');

    console.log('response', students || error);

    if (error) {
      console.log(error.message);
      setLoading(false);
      return;
    }

    if (students) {
      setAllStudents(students)
      let filteredStudents = students.filter((student: any) => {
        if (student.am_transportation || student.pm_transportation)
        return student;
      });
      console.log(filteredStudents);
      setFilteredStudents(filteredStudents);
    }
  };

  useEffect(() => {
    getAllStudents();
  }, []);

  // filter students by when they need transportation - default to showing students that need either am or pm
  useEffect(() => {
    if (allStudents) {
      if (ridesAM || ridesPM || bothOnly) {

        let selectedStudents: any = [];
        let amRiders = allStudents.filter((student: any) => {
          return student.am_transportation && !student.pm_transportation;
        });
        let pmRiders = allStudents.filter((student: any) => {
          return student.pm_transportation && !student.am_transportation;
        });;
        let bothRiders = allStudents.filter((student: any) => {
          return student.pm_transportation && student.am_transportation;
        });
  
        if (bothOnly) {
          setFilteredStudents(bothRiders);
          return;
        }

        if (ridesAM) {
          selectedStudents = [...amRiders, ...bothRiders]
        }
  
        if (ridesPM) {
          if (ridesAM) {
            selectedStudents = [...selectedStudents, ...pmRiders]
          } else {
            selectedStudents = [...pmRiders, ...bothRiders]
          }
        };
  
        setFilteredStudents(selectedStudents);
      }

      if (!ridesAM && !ridesPM) {
        setFilteredStudents(allStudents);  
      }
    }
  }, [ridesAM, ridesPM, bothOnly]);

  return (
    <div className="admin-app-parent" style={{ marginTop: '5em' }}>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={bothOnly}
              checked={ridesAM}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRidesAM(event.target.checked);
              }}
            />
          }
          label={'Rides AM'}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={bothOnly}
              checked={ridesPM}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRidesPM(event.target.checked);
              }}
            />
          }
          label={'Rides PM'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={bothOnly}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBothOnly(event.target.checked);
              }}
            />
          }
          label={'Only Rides Both'}
        />
        <Button
          variant="contained"
          onClick={() => {
            navigate('/admin/students/new');
          }}
        >
          Add Student
        </Button>
      </Box>
      {filteredStudents && (
        <>
          <h1 style={{ textAlign: 'center' }}>Students</h1>
          {filteredStudents.map((student: IStudent) => {
            return <StudentCard key={student.id} student={student} />;
          })}
        </>
      )}
      {!allStudents && !loading && (
        <h1 style={{ marginTop: '3em' }}>Something went wrong</h1>
      )}
    </div>
  );
};

export default AdminStudents;
