import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { supabase } from '../../db/client';
import { IStudent } from '../../ts/interfaces';

import { capitalize } from '../../utils/textFormattingHelpers';

import {
  Box,
  Paper,
  FormControl,
  Input,
  InputLabel,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  Modal,
} from '@mui/material';

import { SuccessModal, ErrorModal } from '../../components'

import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input';

type AdminSingleStudentProps = {
  tableNames: any;
};

const AdminSingleStudent = ({ tableNames }: AdminSingleStudentProps) => {
  const [currentStudent, setCurrentStudent] = useState<IStudent>();
  const [contact1, setContact1] = useState<any>();
  const [contact2, setContact2] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [contact1Phone, setContact1Phone] = useState<string>('+1 ');
  const [contact2Phone, setContact2Phone] = useState<string>('+1 ');
  const [isValidPhone1, setIsValidPhone1] = useState<boolean>();
  const [isValidPhone2, setIsValidPhone2] = useState<boolean>();
  const [phoneError, setPhoneError] = useState('');

  const [baselineAddresses, setBaselineAddresses] = useState<any>();

  const [removeContact1Modal, setRemoveContact1Modal] = useState(false);
  const [removeContact2Modal, setRemoveContact2Modal] = useState(false);

  const [removeStudentModal, setRemoveStudentModal] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeError, setRemoveError] = useState(false)

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const studentID = useParams().id;
  const navigate = useNavigate();

  const getStudentInfo = async () => {
    // fetch student and join emergency contacts if they exist
    let studentSelectString =
      '*, contact1: contact1_id ( * ), contact2: contact2_id ( * )';
    let { data: student, error } = await supabase
      .from(tableNames.students)
      // .from('students')
      .select(studentSelectString)
      .eq('id', studentID)
      .single();

    if (error) {
      console.log(error.message);
      setLoading(false);
      return;
    }

    if (student) {
      // set student to state, baseline_addresses is am/pm pick up address per day (will change), emergency contacts stored separately
      setCurrentStudent(student);
      setBaselineAddresses(student.baseline_addresses);
      if (student.contact1) {
        setContact1(student.contact1);
        if (student.contact1.phone) {
          let phoneStringSplit = String(student.contact1.phone).split('');
          let phoneFormat = `${phoneStringSplit[0]}${phoneStringSplit[1]}${phoneStringSplit[2]} ${phoneStringSplit[3]}${phoneStringSplit[4]}${phoneStringSplit[5]} ${phoneStringSplit[6]}${phoneStringSplit[7]}${phoneStringSplit[8]}${phoneStringSplit[9]}`;
          setContact1Phone('+1 ' + phoneFormat);
        }
      }
      if (student.contact2) {
        setContact2(student.contact2);
        if (student.contact2.phone) {
          let phoneStringSplit = String(student.contact2.phone).split('');
          let phoneFormat = `${phoneStringSplit[0]}${phoneStringSplit[1]}${phoneStringSplit[2]} ${phoneStringSplit[3]}${phoneStringSplit[4]}${phoneStringSplit[5]} ${phoneStringSplit[6]}${phoneStringSplit[7]}${phoneStringSplit[8]}${phoneStringSplit[9]}`;
          setContact2Phone('+1 ' + phoneFormat);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getStudentInfo();
  }, []);

  useEffect(() => {
    // validate phone numbers on change
    setIsValidPhone1(isValidPhoneNumber(contact1Phone));
    setIsValidPhone2(isValidPhoneNumber(contact2Phone));
  }, [contact1Phone, contact2Phone]);

  const contact1PhoneChange = (value: string) => {
    setPhoneError('');
    setContact1Phone(value);
  };

  const contact2PhoneChange = (value: string) => {
    setPhoneError('');
    setContact2Phone(value);
  };

  // add contact opens form
  const addContact1Handler = () => {
    let emptyContact = {
      email: '',
      fname: '',
      lname: '',
      phone: null,
      relation: '',
    };
    setContact1(emptyContact);
  };

  const addContact2Handler = () => {
    let emptyContact = {
      email: '',
      fname: '',
      lname: '',
      phone: null,
      relation: '',
    };
    setContact2(emptyContact);
  };

  const phoneStringToNumber = (phoneNumber: string) => {
    let phoneSplit = phoneNumber.split(' ');
    return Number(`${phoneSplit[1]}${phoneSplit[2]}${phoneSplit[3]}`);
  };

  // removing contact 1 and 2 is handled separately - if contact 1 is removed, contact 2 becomes contact 1 if present. If contact 2 is removed, contact one remains unchanged
  const removeContact1 = async () => {
    setPhoneError('');
    if (currentStudent?.contact2_id) {
      let idToDelete = currentStudent?.contact1_id;
      let newID1 = currentStudent?.contact2_id;
      // replace id1 with id2
      const { data: swappedID, error } = await supabase
        .from(tableNames.students)
        .update({ contact1_id: currentStudent.contact2_id, contact2_id: null })
        .eq('id', studentID);

      if (swappedID) {
        // delete contact with contact2_id
        const { data: deletedContact, error } = await supabase
          .from(tableNames.contacts)
          .delete()
          .eq('id', idToDelete);

        if (deletedContact) {
          setCurrentStudent({
            ...currentStudent,
            contact1_id: newID1,
            contact2_id: null,
          });
          // reset contact 1 to contact 2, contact 2 to null
          setContact1({
            ...contact2,
          });
          setContact1Phone(contact2Phone);
          setContact2(null);
          setContact2Phone('+1 ');
        }
      }
    } else if (currentStudent?.contact1_id && !currentStudent?.contact2_id) {
      // 	update student with this id to reset contact1_id to null
      const { data: removedID, error } = await supabase
        .from(tableNames.students)
        .update({ contact1_id: null })
        .eq('id', studentID);

      if (removedID) {
        // delete contact with contact1_id
        const { data: deletedContact, error } = await supabase
          .from(tableNames.contacts)
          .delete()
          .eq('id', currentStudent?.contact1_id);

        if (deletedContact) {
          setCurrentStudent({
            ...currentStudent,
            contact1_id: null,
          });
          setContact1(null);
          setContact1Phone('+1 ');

          if (contact2) {
            setContact1({ ...contact2 });
            setContact1Phone(contact2Phone);
            setContact2(null);
            setContact2Phone('+1 ');
          }
        }
      }
    }
    if (
      contact1 &&
      contact2 &&
      !currentStudent?.contact1_id &&
      !currentStudent?.contact2_id
    ) {
      setContact1({ ...contact2 });
      setContact1Phone(contact2Phone);
      setContact2(null);
      setContact2Phone('+1 ');
    }
    if (
      !currentStudent?.contact1_id &&
      !currentStudent?.contact2_id &&
      !contact2
    ) {
      setContact1(null);
      setContact1Phone('+1 ');
    }
    setRemoveContact1Modal(false);
  };

  const removeContact2 = async () => {
    setPhoneError('');
    //     remove contact2_id from student
    if (currentStudent?.contact2_id) {
      // 	update student with this id to reset contact2_id to null
      const { data: removedID, error } = await supabase
        .from(tableNames.students)
        .update({ contact2_id: null })
        .eq('id', studentID);

      if (removedID) {
        // delete contact with contact2_id
        const { data: deletedContact, error } = await supabase
          .from(tableNames.contacts)
          .delete()
          .eq('id', currentStudent?.contact2_id);

        if (deletedContact) {
          setCurrentStudent({
            ...currentStudent,
            contact2_id: null,
          });
          setContact2(null);
          setContact2Phone('+1 ');
        }
      }
    }
    if (!currentStudent?.contact2_id) {
      setContact2(null);
      setContact2Phone('+1 ');
    }
    setRemoveContact2Modal(false);
  };

  const updateStudent = async () => {
    if (contact1 && !isValidPhone1) {
      setPhoneError('Check that emergency contacts have valid phone number');
      return;
    }

    if (contact2 && !isValidPhone2) {
      setPhoneError('Check that emergency contacts have valid phone number');
      return;
    }

    let updatedStudent = { ...currentStudent };
    updatedStudent.baseline_addresses = baselineAddresses;

    if (contact1) {
      let newContact1 = {
        ...contact1,
        phone: phoneStringToNumber(contact1Phone),
      };

      if (currentStudent?.contact1_id) {
        // update contact1
        const { data, error } = await supabase
          .from(tableNames.contacts)
          .update({
            fname: newContact1.fname,
            lname: newContact1.lname,
            relation: newContact1.relation,
            phone: newContact1.phone,
            email: newContact1.email,
          })
          .eq('id', currentStudent.contact1_id);
      }
      if (!currentStudent?.contact1_id) {
        // create new contact with newContact1 info

        const { data, error } = await supabase
          .from(tableNames.contacts)
          .insert([
            {
              fname: newContact1.fname,
              lname: newContact1.lname,
              relation: newContact1.relation,
              phone: newContact1.phone,
              email: newContact1.email,
            },
          ]);

        // take returned id and set as updatedStudent.contact1_id
        if (data) {
          let newContactID = data[0].id;
          updatedStudent.contact1_id = newContactID;
        }
      }
    }

    if (contact2) {
      let newContact2 = {
        ...contact2,
        phone: phoneStringToNumber(contact2Phone),
      };

      if (currentStudent?.contact2_id) {
        // update contact2
        const { data, error } = await supabase
          .from(tableNames.contacts)
          .update({
            fname: newContact2.fname,
            lname: newContact2.lname,
            relation: newContact2.relation,
            phone: newContact2.phone,
            email: newContact2.email,
          })
          .eq('id', currentStudent.contact2_id);
      }
      if (!currentStudent?.contact2_id) {
        // create new contact with newContact2
        const { data, error } = await supabase
          .from(tableNames.contacts)
          .insert([
            {
              fname: newContact2.fname,
              lname: newContact2.lname,
              relation: newContact2.relation,
              phone: newContact2.phone,
              email: newContact2.email,
            },
          ]);

        // take returned id and set as updatedStudent.contact2_id
        if (data) {
          let newContactID = data[0].id;
          updatedStudent.contact2_id = newContactID;
        }
      }
    }

    // send updatedStudent info to supabase
    const { data, error } = await supabase
      .from(tableNames.students)
      .update({
        am_transportation: updatedStudent.am_transportation,
        baseline_addresses: updatedStudent.baseline_addresses,
        contact1_id: updatedStudent.contact1_id,
        contact2_id: updatedStudent.contact2_id,
        email: updatedStudent.email,
        pm_transportation: updatedStudent.pm_transportation,
        resident_address: updatedStudent.resident_address,
        transportation_address1: updatedStudent.transportation_address1,
        transportation_address2: updatedStudent.transportation_address2,
      })
      .eq('id', studentID);

    if (data) {
      setSuccess(true);
      setCurrentStudent(data[0]);
    }

    if (error) {
      setError(true);
    }
  };

  const removeStudentHandler = async () => {
    // delete student - emergency contacts if present will not be deleted
    const { data, error } = await supabase
      .from(tableNames.students)
      .delete()
      .eq('id', studentID);

    console.log('response', data || error);

    if (data) {
      setRemoveStudentModal(false);
      setRemoveSuccess(true);
    }
    if (error) {
      setRemoveStudentModal(false);
      setRemoveError(true);
    }
  };

  return (
    <div className="admin-app-parent">
      {currentStudent && !loading && (
        <Box sx={{ marginTop: '5em', textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => setRemoveStudentModal(true)}
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              top: '6em',
              right: 100,
            }}
          >
            Remove
          </Button>
          <h1 style={{ margin: 0, padding: 0 }}>
            {currentStudent.fname} {currentStudent.lname}
          </h1>
          <p style={{ margin: 0, padding: 0 }}>
            <b>Grade: {currentStudent.grade}</b>
          </p>
          <p>ID: {currentStudent.id}</p>
          <Paper
            elevation={5}
            className="student-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              padding: 5,
              marginBottom: '2em',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={currentStudent.am_transportation}
                  onChange={() => {
                    let updatedStudent = {
                      ...currentStudent,
                      am_transportation: !currentStudent.am_transportation,
                    };
                    setCurrentStudent(updatedStudent);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Rides AM"
            ></FormControlLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={currentStudent.pm_transportation}
                  onChange={() => {
                    let updatedStudent = {
                      ...currentStudent,
                      pm_transportation: !currentStudent.pm_transportation,
                    };
                    setCurrentStudent(updatedStudent);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Rides PM"
            ></FormControlLabel>

            <FormControl
              variant="standard"
              fullWidth
              sx={{ margin: '1em 0 1em' }}
            >
              <InputLabel>Resident Address</InputLabel>
              <Input
                id="resident-address-input"
                value={currentStudent.resident_address}
                onChange={(event) => {
                  setCurrentStudent({
                    ...currentStudent,
                    resident_address: event.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ margin: '1em 0 1em' }}
            >
              <InputLabel>Email</InputLabel>
              <Input
                id="resident-email-input"
                value={currentStudent.email}
                onChange={(event) => {
                  setCurrentStudent({
                    ...currentStudent,
                    email: event.target.value,
                  });
                }}
              />
            </FormControl>
            <h2 style={{ textDecoration: 'underline' }}>Emergency Contacts</h2>
            {!contact1 && !contact2 && (
              <Button variant="contained" onClick={addContact1Handler}>
                Add Contact
              </Button>
            )}
            {contact1 && (
              <>
                <h3>Contact 1</h3>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    ':hover': {
                      bgcolor: 'maroon',
                      color: 'white',
                    },
                  }}
                  onClick={() => setRemoveContact1Modal(true)}
                >
                  Remove
                </Button>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>First Name</InputLabel>
                  <Input
                    id="contact1-fname-input"
                    value={contact1.fname}
                    onChange={(event) => {
                      setContact1({ ...contact1, fname: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Last Name</InputLabel>
                  <Input
                    id="contact1-lname-input"
                    value={contact1.lname}
                    onChange={(event) => {
                      setContact1({ ...contact1, lname: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Email</InputLabel>
                  <Input
                    id="contact1-email-input"
                    value={contact1.email}
                    onChange={(event) => {
                      setContact1({ ...contact1, email: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Relation</InputLabel>
                  <Input
                    id="contact1-relation-input"
                    value={contact1.relation}
                    onChange={(event) => {
                      setContact1({
                        ...contact1,
                        relation: event.target.value,
                      });
                    }}
                  />
                </FormControl>
                <div style={{ margin: '1em auto' }}>
                  <Typography color="primary" sx={{ fontSize: '1em' }}>
                    Phone Number (w/ area code)
                  </Typography>
                  <MuiTelInput
                    value={contact1Phone}
                    onChange={contact1PhoneChange}
                    onlyCountries={['US']}
                    disableDropdown={true}
                  />
                </div>
              </>
            )}
            {contact1 && !contact2 && (
              <>
                <h3>Contact 2</h3>
                <Button variant="contained" onClick={addContact2Handler}>
                  Add Contact
                </Button>
              </>
            )}
            {contact2 && (
              <>
                <h3>Contact 2</h3>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    ':hover': {
                      bgcolor: 'maroon',
                      color: 'white',
                    },
                  }}
                  onClick={() => setRemoveContact2Modal(true)}
                >
                  Remove
                </Button>

                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>First Name</InputLabel>
                  <Input
                    id="contact2-fname-input"
                    value={contact2.fname}
                    onChange={(event) => {
                      setContact2({ ...contact2, fname: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Last Name</InputLabel>
                  <Input
                    id="contact2-lname-input"
                    value={contact2.lname}
                    onChange={(event) => {
                      setContact2({ ...contact2, lname: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Email</InputLabel>
                  <Input
                    id="contact2-email-input"
                    value={contact2.email}
                    onChange={(event) => {
                      setContact2({ ...contact2, email: event.target.value });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>Relation</InputLabel>
                  <Input
                    id="contact2-relation-input"
                    value={contact2.relation}
                    onChange={(event) => {
                      setContact2({
                        ...contact2,
                        relation: event.target.value,
                      });
                    }}
                  />
                </FormControl>
                <div style={{ margin: '1em auto' }}>
                  <Typography color="primary" sx={{ fontSize: '1em' }}>
                    Phone Number (w/ area code)
                  </Typography>
                  <MuiTelInput
                    value={contact2Phone}
                    onChange={contact2PhoneChange}
                    onlyCountries={['US']}
                    disableDropdown={true}
                  />
                </div>
              </>
            )}
            <h2>Baseline Addresses</h2>
            {baselineAddresses.map((day: any, index: number) => {
              return (
                <>
                  <p>
                    <b>{capitalize(day.day)}</b>
                  </p>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ margin: '1em 0 1em' }}
                  >
                    <InputLabel>AM Address</InputLabel>
                    <Input
                      id={`baseline-${day.day}-am-input`}
                      value={baselineAddresses[index].am}
                      onChange={(event) => {
                        let value: string | null = event.target.value;
                        if (value === '') value = null;
                        let newBaseline = [...baselineAddresses];
                        newBaseline[index].am = value;
                        setBaselineAddresses(newBaseline);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ margin: '1em 0 1em' }}
                  >
                    <InputLabel>PM Address</InputLabel>
                    <Input
                      id={`baseline-${day.day}-pm-input`}
                      value={baselineAddresses[index].pm}
                      onChange={(event) => {
                        let value: string | null = event.target.value;
                        if (value === '') value = null;
                        let newBaseline = [...baselineAddresses];
                        newBaseline[index].pm = value;
                        setBaselineAddresses(newBaseline);
                      }}
                    />
                  </FormControl>
                </>
              );
            })}
            <h3>Transportation Addresses</h3>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ margin: '1em 0 1em' }}
            >
              <InputLabel>Transportation Address 1</InputLabel>
              <Input
                id="transportation-address-1-input"
                value={currentStudent.transportation_address1}
                onChange={(event) => {
                  setCurrentStudent({
                    ...currentStudent,
                    transportation_address1: event.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ margin: '1em 0 1em' }}
            >
              <InputLabel>Transportation Address 2</InputLabel>
              <Input
                id="transportation-address-2-input"
                value={currentStudent.transportation_address2}
                onChange={(event) => {
                  setCurrentStudent({
                    ...currentStudent,
                    transportation_address2: event.target.value,
                  });
                }}
              />
            </FormControl>
            {phoneError && (
              <p style={{ color: 'red', textAlign: 'center' }}>{phoneError}</p>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" onClick={updateStudent}>
                Update Student
              </Button>
            </div>
          </Paper>
        </Box>
      )}
      {removeContact1Modal && (
        <Modal
          open={removeContact1Modal}
          onClose={() => setRemoveContact1Modal(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <Box textAlign="right" sx={{ margin: 0 }}>
              <Button
                sx={{
                  fontSize: '1em',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
                onClick={() => setRemoveContact1Modal(false)}
              >
                X
              </Button>
            </Box>
            <p>
              Are you sure you want to remove {contact1.fname} {contact1.lname}?
            </p>
            <Button
              variant="contained"
              onClick={removeContact1}
              sx={{ marginBottom: '1em' }}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      )}
      {removeContact2Modal && (
        <Modal
          open={removeContact2Modal}
          onClose={() => setRemoveContact2Modal(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <Box textAlign="right" sx={{ margin: 0 }}>
              <Button
                sx={{
                  fontSize: '1em',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
                onClick={() => setRemoveContact2Modal(false)}
              >
                X
              </Button>
            </Box>
            <p>
              Are you sure you want to remove {contact2.fname} {contact2.lname}?
            </p>
            <Button
              variant="contained"
              onClick={removeContact2}
              sx={{ marginBottom: '1em' }}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      )}

      {!currentStudent && !loading && (
        <h1 style={{ marginTop: '3em' }}>No student with this ID</h1>
      )}
      {success && (
        <Modal open={success} onClose={() => setSuccess(false)}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <p>Student Updated</p>
            <Button
              variant="contained"
              onClick={() => setSuccess(false)}
              sx={{ marginBottom: '1em' }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
      {error && (
        <Modal open={error} onClose={() => setError(false)}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <p>Something went wrong...</p>
            <Button
              variant="contained"
              onClick={() => setError(false)}
              sx={{ marginBottom: '1em' }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
      {removeStudentModal && (
        <Modal
          open={removeStudentModal}
          onClose={() => setRemoveStudentModal(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <Box textAlign="right" sx={{ margin: 0 }}>
              <Button
                sx={{
                  fontSize: '1em',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
                onClick={() => setRemoveStudentModal(false)}
              >
                X
              </Button>
            </Box>
            <p>
              Are you sure you want to remove {currentStudent?.fname}{' '}
              {currentStudent?.lname}?
            </p>
            <Button
              variant="contained"
              onClick={removeStudentHandler}
              sx={{ marginBottom: '1em' }}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      )}
      {removeSuccess && (
        <Modal
          open={removeSuccess}
          onClose={() => setRemoveSuccess(false)}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
            textAlign="center"
          >
            <Box textAlign="right" sx={{ margin: 0 }}>
              <Button
                sx={{
                  fontSize: '1em',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
                onClick={() => setRemoveSuccess(false)}
              >
                X
              </Button>
            </Box>
            <p>
              {currentStudent?.fname} {currentStudent?.lname} has been removed.
            </p>
            <Button
              variant="contained"
              onClick={() => navigate('/admin/students')}
              sx={{ marginBottom: '1em' }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
      {removeError && (
        <ErrorModal open={removeError} onClose={() => setRemoveError(false)} text='Student could not be removed' />
      )}
    </div>
  );
};

export default AdminSingleStudent;
