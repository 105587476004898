import { FormControlLabel, Radio } from '@mui/material';

// day selection for driver availability

type DayRadioButtonProps = {
  day: string;
};

export const DayRadioButton = ({ day }: DayRadioButtonProps) => {
  let split = day.split('');
  let label = split[0].toUpperCase() + split[1];

  return (
    <FormControlLabel
      value={day}
      control={<Radio />}
      label={label}
      labelPlacement="top"
      sx={{
        margin: '0',
      }}
      key={label}
    />
  );
};