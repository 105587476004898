import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import { Paper, Box } from '@mui/material';

import { IDriver } from '../../ts/interfaces';

import { getRouteName } from '../../utils/routeDataHandlers';
import {
  formatDate,
  getExactStart,
} from '../../utils/dateTimeHelpers';

type RouteByDateBlockProps = {
  dayOfRoutes: any;
  drivers: IDriver[];
};

const RouteByDayBlock = ({ dayOfRoutes, drivers }: RouteByDateBlockProps) => {
  return (
    <>
      <h2>{dayOfRoutes.dateString}</h2>
      <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>

      {dayOfRoutes.routes.map( (route: any) => {
        return (
          <CompletedRouteCard route={route} drivers={drivers} key={route.id} />
        )
      })}

      </Box>
    </>
  );
};

type CompletedRouteCardProps = {
  route: any;
  drivers: IDriver[];
};

const CompletedRouteCard = ({ route, drivers }: CompletedRouteCardProps) => {
  const navigate = useNavigate();

  const routeClickHandler = (e: React.MouseEvent) => {
    navigate(`/admin/routes/${route.id}`);
  };

  let borderColor = route.type === 'event' ? 'green' : 'black';

  const getDriverName = (driverArray: IDriver[], driverID: number) => {
    let driver = driverArray.find((driver: IDriver) => {
      return driver.id === driverID;
    });
    if (driver) {
      return `${driver.fname} ${driver.lname}`;
    } else {
      return '';
    }
  };

  return (
    <Paper
      elevation={10}
      sx={{
        borderRadius: '15px',
        cursor: 'pointer',
        padding: 2,
        margin: 1,
        outline: `1px solid ${borderColor}`,
        width: '250px',
      }}
      onClick={routeClickHandler}
    >
      <h2 style={{ margin: 0, textAlign: 'center' }}>{getRouteName(route)}</h2>
      {route.type === 'event' && (
        <p style={{ color: 'green', textAlign: 'center', marginTop: 0 }}>
          EVENT
        </p>
      )}
      <p>{route.formattedDate}</p>
      <p>Driver: {getDriverName(drivers, route.driver_id)}</p>
    </Paper>
  );
};
type AdminPastRoutesProps = {
  tableNames: any;
  drivers: IDriver[];
};

const AdminPastRoutes = ({ tableNames, drivers }: AdminPastRoutesProps) => {
  const [completedRoutes, setCompletedRoutes] = useState<any>();
  const [allRoutesByDate, setAllRoutesByDate] = useState<any>();

  const { user } = useAuth();

  // get routes completed for user's district
  const getCompletedRoutes = async () => {
    let { data: routes, error } = await supabase
      .from(tableNames.routes)
      .select()
      .eq('district_id', user.district_id)
      .eq('complete', true);

    console.log('response', routes || error);

    let allRoutesByDate: any[] = [];

    if (routes) {
      for (let i = 0; i < routes.length; i++) {
        let jsDate = getExactStart(routes[i].date, '12:00');
        let formattedDate = formatDate(jsDate);
        // store js date and formatted date on route
        routes[i].jsDate = jsDate;
        routes[i].formattedDate = formattedDate;
        let allRoutesMatch = allRoutesByDate.find((dayOfRoutes: any) => {
          let thisDate = dayOfRoutes.dateString;
          return thisDate === formattedDate;
        });
        if (allRoutesMatch) {
          allRoutesMatch.routes.push(routes[i]);
        } else {
          allRoutesByDate.push({
            dateString: formattedDate,
            dateObj: jsDate,
            routes: [routes[i]],
          });
        }
      }
      // sort all routes in reverse chronological order
      allRoutesByDate.sort((a: any, b: any) => {
        let aDate = a.dateObj;
        let bDate = b.dateObj;

        return bDate - aDate;
      });
      setCompletedRoutes(routes);
      setAllRoutesByDate(allRoutesByDate);
    }
  };

  useEffect(() => {
    getCompletedRoutes();
  }, []);
  return (
    <div
      className="admin-home-container"
      style={{ marginTop: '5em', textAlign: 'center' }}
    >
      <h1>Past Routes</h1>
      {/* Completed routes in reverse chronological order, by date */}
      {allRoutesByDate &&
        allRoutesByDate.map((dayOfRoutes: any) => {
          return (
            <RouteByDayBlock dayOfRoutes={dayOfRoutes} drivers={drivers} key={dayOfRoutes.dateString} />
          )
        })}
    </div>
  );
};

export default AdminPastRoutes;
