import React, { useState } from 'react';
import { supabase } from '../../db/client';
import { Box, TextField, Button } from '@mui/material';

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState<string>('');
  const [success, setSuccess] = useState(false);

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // check for input
    if (email && password) {
      const { user, session, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      // if user is returned, sign up successful - sent email with link to register
      if (user) {
        setSuccess(true);
        setErrorText('Success');
      }


      if (error) setErrorText(error.message);
    } else {
      setErrorText('Enter your email and password');
    }
  };

  return (
    <div style={{marginTop: '5vh'}} className='driver-app-parent'>
      <img
        src="/logo512.png"
        alt=""
        style={{ width: '100px', marginTop: '5vh' }}
      ></img>
      {!success ? (
        <>
          <h1>Sign Up</h1>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSignUp}
          >
            <TextField
              id="email"
              label="Email Address"
              name="email"
              variant="outlined"
              value={email}
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value.trim());

                setErrorText('');
              }}
              sx={{ color: 'black' }}
            />

            <br />

            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              value={password}
              type="password"
              onChange={(event) => {
                setPassword(event.target.value.trim());

                setErrorText('');
              }}
              sx={{
                margin: '1em 0',
              }}
            />

            <br />

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ margin: '0 auto' }}
            >
              Sign Up
            </Button>
          </Box>
          <p style={{ fontSize: '1.5em' }}>
            Already signed up? {' '}
            <a
              href="/login"
              style={{
                color: 'blue',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              Log In
            </a>
          </p>

          <p style={{ color: 'red' }}>{errorText}</p>
        </>
      ) : (
        <p style={{margin: '5em'}}>
          Thank you for signing up. Please check your email and click the link
          to continue your registration.
        </p>
      )}
    </div>
  );
}