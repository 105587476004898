import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import {
  Paper,
  Box,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Input,
  FormControlLabel,
  Switch,
  TextField,
  Radio,
  RadioGroup,
  FormLabel
} from '@mui/material';

import { SuccessModal, ErrorModal } from '../../components';

import { IVehicle } from '../../ts/interfaces';

import { supabase } from '../../db/client';


type VehicleCardProps = {
  vehicle: IVehicle;
};

const VehicleCard = ({ vehicle }: VehicleCardProps) => {
  const navigate = useNavigate();

  // variable styles depending on availability
  const availColor = (available: boolean | undefined) => {
    let style = {
      fontWeight: 'bold',
      color: '',
      fontSize: '1.5em',
      textDecoration: '',
    };

    if (available) {
      style.color = 'green';
      style.textDecoration = 'underline';
    } else {
      style.color = 'red';
      style.textDecoration = 'line-through';
    }

    return style;
  };

  return (
    <Paper
      elevation={5}
      className="vehicle-details"
      sx={{
        borderRadius: '30px',
        textAlign: 'left',
        padding: '1em 0 1em',
        margin: '1em',
      }}
    >
      <Box sx={{}}>
        <h2 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          {vehicle.identifier}
        </h2>
        <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          <span style={availColor(vehicle.available)}>Available</span>
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/admin/vehicles/${vehicle.id}`);
            }}
            sx={{ margin: '0 auto' }}
          >
            Edit
          </Button>
        </div>
        <Box
          sx={{
            borderRadius: '30px',
            padding: { sm: '0 1em 0 1em', md: '1em 2em 1em 2em' },
            outline: { md: '1px solid grey' },
            margin: { md: '1em' },
          }}
        >
          <p>
            <b>Vehicle ID: </b>
            {vehicle.id}
          </p>
          <p>
            <b>VIN: </b>
            {vehicle.vin}
          </p>
          <p>
            <b>License Required: </b>
            {vehicle.license_required}
          </p>
          <p>
            <b>Capacity: </b>
            {vehicle.capacity}
          </p>
        </Box>
      </Box>
    </Paper>
  );
};

type AdminVehiclesProps = {
  vehicles: IVehicle[];
  tableNames: any;
  updateAllVehicles: () => void;
};

const AdminVehicles = ({ vehicles, tableNames, updateAllVehicles }: AdminVehiclesProps) => {
  const [allVehicles, setAllVehicles] = useState(vehicles);
  const [newVehicleModal, setNewVehicleModal] = useState(false);
  const [newVehicle, setNewVehicle] = useState<any>({
    vin: '',
    make: '',
    model: '',
    year: Number(new Date(Date.now()).getFullYear()),
    capacity: 0,
    license_required: 'standard',
    available: false,
    identifier: '',
  });

  const [licenseError, setLicenseError] = useState('');
  const [inputError, setInputError] = useState(false);
  const [inputErrorText, setInputErrorText] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const [type, setType] = useState('bus');

  const { user } = useAuth();
  // validate license required input
  const validLicense = (license: string) => {
    if (license !== 'cdl' && license !== 'standard' && license !== 'chauffer') {
      setLicenseError(
        `License required must be 'cdl', 'chauffer', or 'standard'`
      );
    } else {
      setLicenseError('');
    }
  };

  const newVehicleHandler = async () => {
    if (!inputError) {
      let {
        available,
        identifier,
        vin,
        make,
        model,
        year,
        capacity,
        license_required,
      } = newVehicle;

      const { data: newVehicleResponse, error } = await supabase
        .from(tableNames.vehicles)
        .insert([
          {
            available,
            identifier,
            vin,
            make,
            model,
            year,
            capacity,
            license_required,
            district_id: user.district_id,
            type
          },
        ]);

      console.log('response', newVehicleResponse || error);

      if (newVehicleResponse) {
        setSuccess(true);
        setNewVehicleModal(false);
        setNewVehicle({
          vin: '',
          make: '',
          model: '',
          year: Number(new Date(Date.now()).getFullYear()),
          capacity: 0,
          license_required: 'standard',
          available: false,
          identifier: '',
        });
        setType('bus');
        updateAllVehicles()
      }
      if (error) {
        setError(error.message);
        setNewVehicleModal(false);
      }

    } else {
      setInputErrorText('Check that you\'ve entered all required information')
    }
  };

  const validVehicle = (vehicle: any) => {
    if (
      !vehicle.vin ||
      !vehicle.make ||
      !vehicle.model ||
      !vehicle.identifier ||
      !vehicle.capacity
    )
      return true;
    return false;
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setInputErrorText('');
    validLicense(newVehicle?.license_required || '');
    setInputError(validVehicle(newVehicle));
  }, [newVehicle]);

  useEffect( () => {
    setAllVehicles(vehicles);
  }, [vehicles]);
  
  return (
    <div className="admin-app-parent" style={{ marginTop: '5em' }}>
      <Box sx={{display: 'flex', justifyContent: 'right'}}>
      <Button
        variant="contained"
        onClick={() => {
          setNewVehicleModal(true);
        }}
      >
        New Vehicle
      </Button>
      </Box>
      <h1 style={{ textAlign: 'center' }}>Vehicles</h1>
      {allVehicles.map((vehicle: IVehicle) => {
        return <VehicleCard key={vehicle.id} vehicle={vehicle} />;
      })}
      {newVehicleModal && (
        <Modal open={newVehicleModal} onClose={() => setNewVehicleModal(false)}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: 400 },
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              paddingInline: '2em',
              maxHeight: '90vh',
              overflow: 'scroll'
            }}
            textAlign="center"
          >
            <h2 style={{ marginBottom: 0 }}>New Vehicle Form</h2>
            <FormControlLabel
              control={
                <Switch
                  checked={newVehicle.available}
                  onChange={() => {
                    let updatedVehicle = {
                      ...newVehicle,
                      available: !newVehicle.available,
                    };
                    setNewVehicle(updatedVehicle);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Available"
            />
           <br />
            <FormControl>
              <FormLabel id="type-radio-group">Type</FormLabel>
              <RadioGroup
                aria-labelledby="vehicle-type"
                value={type}
                name="type-buttons-group"
                onChange={handleTypeChange}
              >
                <FormControlLabel value="bus" control={<Radio />} label="Bus" />
                <FormControlLabel value="van" control={<Radio />} label="Van" />
                <FormControlLabel value="minivan" control={<Radio />} label="Minivan" />
              </RadioGroup>
            </FormControl>

            <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
              <InputLabel>Identifier ('Dodge Caravan' or 'Bus 27')</InputLabel>
              <Input
                id="identifier-input"
                value={newVehicle.identifier}
                onChange={(event) => {
                  setNewVehicle({
                    ...newVehicle,
                    identifier: event.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
              <InputLabel>VIN</InputLabel>
              <Input
                id="vin-input"
                value={newVehicle.vin}
                onChange={(event) => {
                  setNewVehicle({
                    ...newVehicle,
                    vin: event.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
              <InputLabel>Make</InputLabel>
              <Input
                id="make-input"
                value={newVehicle.make}
                onChange={(event) => {
                  setNewVehicle({
                    ...newVehicle,
                    make: event.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
              <InputLabel>Model</InputLabel>
              <Input
                id="model-input"
                value={newVehicle.model}
                onChange={(event) => {
                  setNewVehicle({
                    ...newVehicle,
                    model: event.target.value,
                  });
                }}
              />
            </FormControl>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                textAlign: 'left',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="year-input"
                label="Year"
                variant="standard"
                value={newVehicle.year}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(event) => {
                  let number = Number(event.target.value);
                  if (!isNaN(number) && number < 9999) {
                    let updatedVehicle = {
                      ...newVehicle,
                      year: number,
                    };
                    setNewVehicle(updatedVehicle);
                  }
                }}
              />
            </Box>
            <FormControl variant="standard" fullWidth sx={{ margin: '.5em 0' }}>
              <InputLabel>License Required</InputLabel>
              <Input
                id="license_required-input"
                value={newVehicle.license_required}
                onChange={(event) => {
                  setNewVehicle({
                    ...newVehicle,
                    license_required: event.target.value,
                  });
                }}
              />
            </FormControl>
            {licenseError && (
              <p style={{ color: 'red', fontSize: '.7em', marginTop: 0, marginBottom: 0 }}>{licenseError}</p>
            )}
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                textAlign: 'left',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="capacity-input"
                label="Capacity"
                variant="standard"
                value={newVehicle.capacity}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(event) => {
                  let number = Number(event.target.value);
                  if (!isNaN(number) && number < 100) {
                    let updatedVehicle = {
                      ...newVehicle,
                      capacity: number,
                    };
                    setNewVehicle(updatedVehicle);
                  }
                }}
              />
            </Box>
                {inputErrorText && (
                  <p style={{color: 'red', fontSize: '.7em'}}>{inputErrorText}</p>
                )}
            <Button
              variant="contained"
              disabled={licenseError ? true : false}
              onClick={newVehicleHandler}
              sx={{ marginBottom: '1em' }}
            >
              Add Vehicle
            </Button>
          </Box>
        </Modal>
      )}
      {success && (
        <SuccessModal open={success} onClose={() => setSuccess(false)} text='New Vehicle Added'/>
      )}
      {error && (
        <ErrorModal open={error ? true : false} onClose={() => setError('')} text={`Error: ${error}`} />
      )}
    </div>
  );
};

export default AdminVehicles;
