import React, { useState, useEffect } from 'react';
import './WeeklySchedule.css';

import {
  findDriverWeeklyAvailability,
  pushWeeklyAvailabilityUpdate,
} from '../../db/supabase-calls/driver_availability';

import { useAuth } from '../../contexts/Auth';

import {
  FormGroup,
  Button,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';

// custom components
import {
  Header,
  CheckBoxTimeSlot,
  DayRadioButton,
  UpdateScheduleModal,
} from '../../components';

// types
import {
  TDriverAvailabilityArray,
  TWeeklyAvailabilityArray,
} from '../../ts/types';

const WeeklySchedule = () => {
  const { user } = useAuth();

  const [driverAvailability, setDriverAvailability] =
    useState<TDriverAvailabilityArray>([]);
  const [weeklyAvailabilityFormat, setWeeklyAvailabilityFormat] =
    useState<TWeeklyAvailabilityArray>([]);
  const [priorWeeks, setPriorWeeks] = useState<TWeeklyAvailabilityArray>([]);

  const [driverLoaded, setDriverLoaded] = useState(false);
  const [daySelected, setDaySelected] = useState('monday');
  const [startDay, setStartDay] = useState<string>('');
  const [endDay, setEndDay] = useState<string>('');

  const [pleaseRegister, setPleaseRegister] = useState(false);

  // modal controls
  const [modalState, setModalState] = useState(false);
  const handleOpen = () => setModalState(true);
  const handleClose = () => setModalState(false);

  const dayOptions = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const checkOptions = ['morning', 'midday', 'afternoon', 'evening'];

  // method to mark each box checked based on state
  const checkValue = (timeslot: string): boolean => {
    if (driverLoaded) {
      let currentDay = driverAvailability.filter(
        (day) => day.day === daySelected
      )[0];

      let value = currentDay[timeslot as keyof typeof currentDay];
      return value as boolean;
    }

    return false;
  };

  // when box clicked, cycle through to update state
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (driverLoaded) {
      let slot = event.target.value;
      let dayOfWeek = daySelected;
      let newAvailability = [...driverAvailability];
      let index = newAvailability.findIndex((day) => {
        return day.day === dayOfWeek;
      });

      let newDay = {
        day: dayOfWeek,
        morning: newAvailability[index]['morning'],
        midday: newAvailability[index]['midday'],
        afternoon: newAvailability[index]['afternoon'],
        evening: newAvailability[index]['evening'],
      };

      if (slot === 'morning') {
        newDay.morning = !newAvailability[index].morning;
      } else if (slot === 'midday') {
        newDay.midday = !newAvailability[index].midday;
      } else if (slot === 'afternoon') {
        newDay.afternoon = !newAvailability[index].afternoon;
      } else if (slot === 'evening') {
        newDay.evening = !newAvailability[index].evening;
      }

      newAvailability[index] = newDay;
      setDriverAvailability(newAvailability);
    }
  };

  const setNewAvail = async () => {
    const driverId = user.identities[0].id;

    let newWeeklyAvailability: TWeeklyAvailabilityArray = [];
    for (let i = 0; i < driverAvailability.length; i++) {
      let newDay = {
        date: weeklyAvailabilityFormat[i].date,
        morning: driverAvailability[i]['morning'],
        midday: driverAvailability[i]['midday'],
        afternoon: driverAvailability[i]['afternoon'],
        evening: driverAvailability[i]['evening'],
      };
      newWeeklyAvailability.push(newDay);
    }
    let fullWeeklyAvailability = [...priorWeeks, ...newWeeklyAvailability];
    
    let response = await pushWeeklyAvailabilityUpdate(driverId, fullWeeklyAvailability);
    handleOpen();
  };

  const formatDate = (date: string): string => {
    let splitArr = date.split('-');
    return `${splitArr[1]}/${splitArr[2]}`;
  };

  useEffect(() => {
    // get availability for driver, pull USER id from context
    const driverId = user.identities[0].id;
    const getWeeklyAvailability = async () => {
      let data = await findDriverWeeklyAvailability(driverId);
      // if no driver row switch to show link to register
      if (!data) {
        setPleaseRegister(true);
      }

      // as of 11/3/22 weekly availability is only a moving two week window. priorWeeksAvail is first 7 days, unchangeable, and following week is editable
      let priorWeeksAvail = data.slice(0, 7);
      setPriorWeeks(priorWeeksAvail);

      let weeklyArray = data.slice(-7);
      setStartDay(formatDate(weeklyArray[0].date));
      setEndDay(formatDate(weeklyArray[6].date));
      setWeeklyAvailabilityFormat(weeklyArray);
      let formattedWeeklyArray: TDriverAvailabilityArray = [];
      for (let i = 0; i < weeklyArray.length; i++) {
        let newDay = {
          day: dayOptions[i],
          morning: weeklyArray[i].morning,
          midday: weeklyArray[i].midday,
          afternoon: weeklyArray[i].afternoon,
          evening: weeklyArray[i].evening,
        };
        formattedWeeklyArray.push(newDay);
      }
      setDriverAvailability(formattedWeeklyArray);
      setDriverLoaded(true);
    };
    getWeeklyAvailability();
  }, []);

  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaySelected(event.target.value);
  };

  return (
    <div className='driver-app-parent'>
      {!pleaseRegister ? (
        <>
          <Header />
          <FormControl onChange={dayChangeHandler}>
            <FormLabel id="weekly-form-label">
              <p>Modify availability for</p>
              <p>
                <span style={{ fontSize: '2em', marginRight: 20 }}>
                  {startDay}
                </span>
                to
                <span style={{ fontSize: '2em', marginLeft: 20 }}>
                  {endDay}
                </span>
              </p>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="weekly-schedule"
              value={daySelected}
            >
              {dayOptions.map((day) => (
                <DayRadioButton day={day} key={day} />
              ))}
            </RadioGroup>
          </FormControl>
          <div
            style={{
              width: '200px',
              margin: '1em auto',
            }}
          >
            <FormGroup
              sx={{
                margin: '0 auto',
              }}
            >
              {checkOptions.map((timeSlot) => (
                <CheckBoxTimeSlot
                  timeSlot={timeSlot}
                  checkValue={checkValue(timeSlot)}
                  handleCheck={handleCheck}
                  key={timeSlot}
                />
              ))}
            </FormGroup>
          </div>

          <Button variant="contained" onClick={setNewAvail} color="secondary">
            Update Availability
          </Button>
          <UpdateScheduleModal
            modalState={modalState}
            handleClose={handleClose}
          />
        </>
      ) : (
        <p style={{ fontSize: '2.5em', marginTop: '2em' }}>
          Please{' '}
          <a
            href="/register"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Register
          </a>
        </p>
      )}
    </div>
  );
};

export default WeeklySchedule;
