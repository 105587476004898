import { Modal, Box, Typography, Button } from '@mui/material';

type UpdateModalProps = {
  modalState: boolean;
  handleClose: () => void;
};

// reused modal to alert user schedule has been updated

export const UpdateScheduleModal = ({
  modalState,
  handleClose,
}: UpdateModalProps) => {
  return (
    <Modal
      open={modalState}
      onClose={handleClose}
      aria-labelledby="update-success"
      aria-describedby="displays update sucesss"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Your schedule has been updated!
        </Typography>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ marginTop: '1em' }}
          color="secondary"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
