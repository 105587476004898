import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  IExtracurricular,
  IStudent,
  IDriver,
  IVehicle,
  ICoach,
} from '../../ts/interfaces';

import {
  Paper,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { TextInput, SuccessModal, ErrorModal } from '../../components';
import {
  getExactStart,
  formatDate,
  formatTime,
  dateObjToUTCTime,
  dateObjToSBFormat,
} from '../../utils/dateTimeHelpers';

import { isBefore, sub, add } from 'date-fns';
import { phoneNumberToString } from '../../utils/textFormattingHelpers';

import { updateDriverByEmail } from '../../utils/sendGridMethods';
import {
  streetAddressToLatLong,
  distanceMatrixCall,
  metersToMiles,
} from '../../utils/geolocation';

type EventCardProps = {
  event: any;
  openModal: (id: any) => void;
  drivers: IDriver[];
  vehicles: IVehicle[];
  district: any;
  tableNames: any;
  updateEvents: (id: any) => void;
  updateAllRoutes: () => void;
};

const EventCard = ({
  event,
  openModal,
  drivers,
  vehicles,
  district,
  tableNames,
  updateEvents,
  updateAllRoutes,
}: EventCardProps) => {
  const [newRouteInfo, setNewRouteInfo] = useState<any>();
  const [routeSuccess, setRouteSuccess] = useState<string>('');
  const [routeInputError, setRouteInputError] = useState<string>('');
  const [removeTripModal, setRemoveTripModal] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const handleDriverChange = (event: SelectChangeEvent) => {
    let selectedDriver = drivers.find((driver: any) => {
      return driver.id === event.target.value;
    });

    setNewRouteInfo({
      ...newRouteInfo,
      driver: selectedDriver,
    });
  };

  const handleVehicleChange = (event: SelectChangeEvent) => {
    let selectedVehicle = vehicles.find((vehicle: any) => {
      return vehicle.id === event.target.value;
    });

    setNewRouteInfo({
      ...newRouteInfo,
      vehicle: selectedVehicle,
    });
  };

  useEffect(() => {
    setRouteInputError('');
  }, [newRouteInfo]);

  const setBlankRoute = async () => {
    // start address set as district depot - will need to be made variable or set per school of district
    let streetAddress = district.depot;
    // lat long currently not stored, call to get lat long for district depot
    let response = await streetAddressToLatLong(streetAddress);
    console.log(response);

    let blankRoute = {
      date: event.date,
      type: 'event',
      extracurricular_id: event.extracurricular_id,
      stop_instructions: event.stop_instructions,
      stop_count: 1,
      district_id: user.district_id,
      driver: null,
      vehicle: null,
      start_time: null,
      end_time: null,
      students_count: null,
      data: [
        {
          street_address: '',
        },
        {
          end: {
            lat_long: response,
            street_address: district.depot,
          },
          start: {
            lat_long: response,
            street_address: district.depot,
          },
        },
      ],
    };

    console.log(blankRoute);
    setNewRouteInfo(blankRoute);
  };

  const createRoute = async () => {
    // validate
    if (
      !newRouteInfo.driver ||
      !newRouteInfo.vehicle ||
      !newRouteInfo.data[0].street_address ||
      !newRouteInfo.students_count
    ) {
      setRouteInputError('Please enter all required information');
      return;
    }

    let eventStartTime = getExactStart(event.date, event.start_time);
    let eventEndTime: Date | null = null;
    // require start time, many events will not include an end time
    if (event.end_time)
      eventEndTime = getExactStart(event.date, event.end_time);

    let eventRouteTime = getExactStart(event.date, event.route_time);

    // get lat long from input address
    let latLong = await streetAddressToLatLong(
      newRouteInfo.data[0].street_address
    );
    newRouteInfo.data[0].lat_long = latLong;

    let response = await distanceMatrixCall(
      newRouteInfo.data[1].start.lat_long,
      latLong
    );

    // get route metrics from distance matrix call, sub time from route start time if arrive by
    let distanceInMeters = response.rows[0].elements[0].distance.value;
    let timeInSeconds = response.rows[0].elements[0].duration.value;

    let distance = metersToMiles(distanceInMeters);
    let est_duration = (timeInSeconds / 60).toFixed(0);

    let routeStartTime: any = null;
    let routeEndTime: any = null;

    if (event.arrive_by) {
      routeStartTime = sub(eventRouteTime, {
        seconds: timeInSeconds,
      });
      if (eventEndTime) {
        routeEndTime = add(eventEndTime, {
          seconds: timeInSeconds,
        });
      }
      // if arrive by not set, route time is time to depart
    } else {
      routeStartTime = eventRouteTime;
      if (eventEndTime) {
        routeEndTime = add(eventEndTime, {
          seconds: timeInSeconds,
        });
      }
    }

    let UTCStart = dateObjToUTCTime(routeStartTime);
    let UTCEnd: string | null = null;
    if (routeEndTime) {
      UTCEnd = dateObjToUTCTime(routeEndTime);
    }

    const routeFormat = {
      date: newRouteInfo.date,
      data: newRouteInfo.data,
      distance,
      district_id: newRouteInfo.district_id,
      driver_id: newRouteInfo.driver.id,
      end_time: UTCEnd,
      est_duration,
      event_id: event.id,
      extracurricular_id: newRouteInfo.extracurricular_id,
      start_time: UTCStart,
      stop_count: newRouteInfo.stop_count,
      students_count: newRouteInfo.students_count,
      type: newRouteInfo.type,
      vehicle_id: newRouteInfo.vehicle.id,
    };

    console.log(routeFormat);

    const { data: newRoute, error } = await supabase
      .from(tableNames.routes)
      .insert([routeFormat]);

    if (newRoute?.[0]) {
      const { data: updatedEvent, error } = await supabase
        .from(tableNames.events)
        .update({ route_id: newRoute?.[0].id })
        .eq('id', event.id);

      updateDriverByEmail(routeFormat, newRouteInfo.driver);
      setRouteSuccess('Trip created');
      setNewRouteInfo(null);
      updateEvents(event.extracurricular_id);
      updateAllRoutes();
    }
  };

  const removeTripHandler = async () => {
    // remove scheduled transportation for event but not event itself
    let routeToRemove = event.route_id;

    const { data: updatedEvent, error } = await supabase
      .from(tableNames.events)
      .update({ route_id: null })
      .eq('id', event.id);

    if (updatedEvent) {
      const { data: deletedRoute, error } = await supabase
        .from(tableNames.routes)
        .delete()
        .eq('id', routeToRemove);

      if (deletedRoute) {
        updateEvents(event.extracurricular_id);
        updateAllRoutes();
        setRemoveTripModal(false);
      }
    }
  };

  return (
    <Paper
      elevation={2}
      className="event-details"
      sx={{
        borderRadius: '10px',
        textAlign: 'center',
        margin: 3,
      }}
    >
      <h3 style={{ marginBottom: 0, paddingTop: '.5em' }}>{event.title}</h3>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'red',
          fontSize: '.7em',
          marginTop: 0,
          ':hover': {
            bgcolor: 'maroon',
            color: 'white',
          },
        }}
        onClick={() => {
          openModal(event.id);
        }}
      >
        Edit
      </Button>
      <p>
        <b>{formatDate(getExactStart(event.date, '23:00:00'))}</b>
      </p>
      <Box
        sx={{
          textAlign: 'left',
          marginLeft: '2em',
        }}
      >
        <p>
          <b>Location: </b>
          {event.location}
        </p>
        <p>
          <b>Start Time: </b>
          {event.start_time
            ? formatTime(getExactStart(event.date, event.start_time))
            : 'Not listed'}
        </p>
        <p>
          <b>End Time: </b>
          {event.end_time
            ? formatTime(getExactStart(event.date, event.end_time))
            : 'Not listed'}
        </p>
        <p>
          <b>{event.arrive_by ? 'Arrive by: ' : 'Depart at: '}</b>{' '}
          {event.route_time
            ? formatTime(getExactStart(event.date, event.route_time))
            : 'Not set'}
        </p>
        <p>
          <b>Stop Instructions: </b>
          {event.stop_instructions || 'None'}
        </p>
      </Box>
      {newRouteInfo ? (
        <>
          <Box sx={{ width: '80%', margin: '0 auto' }}>
            <h3>Route Details</h3>

            <FormControl fullWidth sx={{ margin: '1em auto' }}>
              <InputLabel id="driver-select">Driver</InputLabel>

              <Select
                labelId="driver-select"
                id="driver-select-input"
                value={newRouteInfo.driver?.id}
                label="Driver"
                onChange={handleDriverChange}
                sx={{ marginBottom: '2em' }}
                MenuProps={{ color: 'red' }}
              >
                {drivers.map((driver: any) => {
                  return (
                    <MenuItem key={driver.id} value={driver.id}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <p>
                          {driver.fname} {driver.lname}
                        </p>
                        <p>ID: {driver.id}</p>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ margin: '0 auto' }}>
              <InputLabel id="vehicle-select">Vehicle</InputLabel>

              <Select
                labelId="vehicle-select"
                id="vehicle-select-input"
                value={newRouteInfo.vehicle?.id}
                label="Vehicle"
                onChange={handleVehicleChange}
                sx={{ marginBottom: '2em' }}
              >
                {vehicles.map((vehicle: any) => {
                  return (
                    <MenuItem key={vehicle.id} value={vehicle.id}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <p>{vehicle.identifier}</p>
                        <p>ID: {vehicle.id}</p>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextInput
              label="Destination Address"
              value={newRouteInfo.data[0].street_address}
              changeHandler={(event) => {
                let dataCopy = newRouteInfo.data;
                dataCopy[0].street_address = event.target.value;
                setNewRouteInfo({
                  ...newRouteInfo,
                  data: dataCopy,
                });
              }}
            />
            <p style={{ marginTop: '0', color: 'gray', textAlign: 'left' }}>
              (valid street address where vehicle will stop)
            </p>
            {newRouteInfo.data[0].street_address !== event.location && (
              <Box sx={{ textAlign: 'left' }}>
                <Button
                  onClick={() => {
                    let dataCopy = newRouteInfo.data;
                    dataCopy[0].street_address = event.location;

                    setNewRouteInfo({
                      ...newRouteInfo,
                      data: dataCopy,
                    });
                  }}
                >
                  Use Event Location
                </Button>
              </Box>
            )}

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                textAlign: 'left',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="students-input"
                label="Total Passengers"
                variant="standard"
                value={newRouteInfo.students_count}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(event) => {
                  let number = Number(event.target.value);
                  if (!isNaN(number) && number < 100) {
                    setNewRouteInfo({
                      ...newRouteInfo,
                      students_count: number,
                    });
                  }
                }}
              />
            </Box>
            {routeInputError && (
              <p style={{ color: 'red' }}>{routeInputError}</p>
            )}
            <Button
              variant="contained"
              onClick={createRoute}
              sx={{ marginBottom: '2em' }}
            >
              Create
            </Button>
          </Box>
        </>
      ) : (
        <>
          {event.route_id && (
            <Box
              sx={{
                paddingBottom: '2em',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate(`/admin/routes/${event.route_id}`)}
              >
                View Scheduled Trip
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'red',
                  ':hover': {
                    bgcolor: 'maroon',
                    color: 'white',
                  },
                }}
                onClick={() => setRemoveTripModal(true)}
              >
                Remove Trip
              </Button>
            </Box>
          )}
          {removeTripModal && (
            <Modal
              open={removeTripModal}
              onClose={() => setRemoveTripModal(false)}
            >
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '80%', md: 400 },
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  borderRadius: '10px',
                  boxShadow: 24,
                  paddingInline: '2em',
                }}
                textAlign="center"
              >
                <p>
                  Are you sure you want to remove the scheduled trip for this
                  event?
                </p>
                <Button
                  variant="contained"
                  sx={{ marginBottom: '1em' }}
                  onClick={removeTripHandler}
                >
                  Remove Trip
                </Button>
              </Box>
            </Modal>
          )}
          {!event.route_id && event.route_time && (
            <Button
              variant="contained"
              sx={{ marginBottom: '2em' }}
              onClick={setBlankRoute}
            >
              Schedule Trip
            </Button>
          )}
          {!event.route_time && (
            <p
              style={{ color: 'red', fontWeight: 'bold', paddingBottom: '1em' }}
            >
              Edit event to include arrival/departure time to schedule
              transportation.
            </p>
          )}
        </>
      )}
      {routeSuccess && (
        <SuccessModal
          open={routeSuccess ? true : false}
          onClose={() => setRouteSuccess('')}
          text={routeSuccess}
        />
      )}
    </Paper>
  );
};

type AdminSingleSportProps = {
  tableNames: any;
  drivers: IDriver[];
  vehicles: IVehicle[];
  district: any;
  updateAllRoutes: () => void;
};

// single sport page has roster, coaches, and events for each extracurricular if available
const AdminSingleSport = ({
  tableNames,
  drivers,
  vehicles,
  district,
  updateAllRoutes,
}: AdminSingleSportProps) => {
  const [currentSport, setCurrentSport] = useState<IExtracurricular>();
  const [roster, setRoster] = useState<IStudent[]>();
  const [staff, setStaff] = useState<ICoach[]>();
  const [events, setEvents] = useState<any[]>();
  const [loading, setLoading] = useState(true);

  const [errorText, setErrorText] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const [eventModal, setEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState<any>({
    title: '',
    date: null,
    start_time: null,
    end_time: null,
    location: '',
    stop_instructions: '',
    arrive_by: true,
    route_time: null,
  });

  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [removeEventModal, setRemoveEventModal] = useState(false);
  const sportID = useParams().id;
  const navigate = useNavigate();

  const getSportInfo = async () => {
    let { data: sport, error } = await supabase
      .from(tableNames.extracurriculars)
      .select()
      .eq('id', sportID)
      .single();

    if (error) {
      console.log(error.message);
      setLoading(false);
      return;
    }

    if (sport) {
      if (sport.roster) {
        // if roster information for sport get student details from list of student ids
        getStudentInfo(sport);
      }
      if (sport.staff) {
        // if staff for sport get staff details from list of ids
        getStaffInfo(sport);
      }
      setCurrentSport(sport);
      getEvents(sport.id);
    }
  };

  const getStaffInfo = async (sport: IExtracurricular) => {
    let arrayOfCoachIDs = sport.staff;

    let coachQueryString = '';

    for (let i = 0; i < arrayOfCoachIDs.length; i++) {
      coachQueryString += `id.eq.${arrayOfCoachIDs[i]}`;
      if (i < arrayOfCoachIDs.length - 1) coachQueryString += ',';
    }

    let { data: coachInfo, error } = await supabase
      .from('coaches')
      .select()
      .or(coachQueryString);

    if (coachInfo) {
      setStaff(coachInfo);
    }
  };

  const getStudentInfo = async (sport: IExtracurricular) => {
    let arrayOfStudentIDs = sport.roster;

    let studentQueryString = '';
    for (let i = 0; i < arrayOfStudentIDs.length; i++) {
      studentQueryString += `id.eq.${arrayOfStudentIDs[i]}`;
      if (i < arrayOfStudentIDs.length - 1) studentQueryString += ',';
    }

    let { data: studentInfo, error: error2 } = await supabase
      .from(tableNames.students)
      .select()
      .or(studentQueryString)
      .order('lname');

    if (studentInfo) {
      setRoster(studentInfo);
    }
  };

  const getEvents = async (extracurricularID: number) => {
    // events are stored per extracurricular
    let { data: events, error } = await supabase
      .from(tableNames.events)
      .select('*')
      .eq('extracurricular_id', sportID);

    console.log('response', events || error);

    if (events) {
      // sort by date
      let sortedEvents = events.sort((a: any, b: any) => {
        let aDate = getExactStart(a.date, '00:00:00');
        let bDate = getExactStart(b.date, '00:00:00');

        if (isBefore(aDate, bDate)) return -1;
        else return 1;
      });
      setEvents(sortedEvents);
    }
  };

  const addEventHandler = async () => {
    // validate
    if (
      !newEvent.date ||
      !newEvent.start_time ||
      !newEvent.title ||
      !newEvent.location ||
      !newEvent.route_time
    ) {
      setErrorText('Event must include date, title, location, and start time');
      return;
    } else {
      let UTCStart: string = dateObjToUTCTime(newEvent.start_time);
      let dateFormat = dateObjToSBFormat(newEvent.date);
      let UTCEnd: string | null = null;

      if (newEvent.end_time) UTCEnd = dateObjToUTCTime(newEvent.end_time);
      let UTCRouteTime = dateObjToUTCTime(newEvent.route_time);

      let { title, location, stop_instructions, arrive_by } = newEvent;

      const { data, error } = await supabase.from(tableNames.events).insert([
        {
          title,
          location,
          stop_instructions,
          start_time: UTCStart,
          end_time: UTCEnd,
          route_time: UTCRouteTime,
          date: dateFormat,
          arrive_by,
          extracurricular_id: sportID,
        },
      ]);

      console.log('response from add event', data || error);

      if (data) {
        setEventModal(false);
        setSuccess('New event created succesfully');
        setNewEvent({
          title: '',
          date: null,
          start_time: null,
          end_time: null,
          location: '',
          stop_instructions: '',
        });
        getEvents(Number(sportID));
      }
      if (error) {
        setEventModal(false);
        setError(`Database Error: ${error.message}`);
      }
    }
  };

  // select event to edit
  const setEventToState = (id: number) => {
    if (events) {
      let event = events.find((event) => {
        return event.id === id;
      });
      console.log(event);

      let dateObj = null;
      if (event.date) {
        dateObj = getExactStart(event.date, '23:00:00');
      }

      let startTime = null;
      if (event.start_time) {
        startTime = getExactStart(event.date, event.start_time);
      }

      let endTime = null;
      if (event.end_time) {
        endTime = getExactStart(event.date, event.end_time);
      }

      let routeTime = null;
      if (event.route_time) {
        routeTime = getExactStart(event.date, event.route_time);
      }
      setSelectedEvent({
        title: event.title,
        date: dateObj,
        start_time: startTime,
        end_time: endTime,
        location: event.location,
        stop_instructions: event.stop_instructions,
        id: event.id,
        arrive_by: event.arrive_by,
        route_time: routeTime,
        route_id: event.route_id,
      });
    }
  };

  const updateEventHandler = async () => {

    // validate needed info
    if (
      !selectedEvent.title ||
      !selectedEvent.date ||
      !selectedEvent.location ||
      !selectedEvent.start_time ||
      !selectedEvent.route_time
    ) {
      setErrorText('Please enter required information');
      return;
    }

    // format times and dates
    let UTCStart: string = dateObjToUTCTime(selectedEvent.start_time);
    let dateFormat = dateObjToSBFormat(selectedEvent.date);
    let UTCEnd: string | null = null;
    let UTCRouteTime = dateObjToUTCTime(selectedEvent.route_time);

    // end time is optional and will often not be known
    if (selectedEvent.end_time)
      UTCEnd = dateObjToUTCTime(selectedEvent.end_time);

    let { title, location, stop_instructions, arrive_by } = selectedEvent;

    const { data, error } = await supabase
      .from(tableNames.events)
      .update({
        title,
        location,
        stop_instructions,
        date: dateFormat,
        start_time: UTCStart,
        end_time: UTCEnd,
        arrive_by,
        route_time: UTCRouteTime,
      })
      .eq('id', selectedEvent.id);

    console.log('response', data || error);

    if (data) {
      let updatedEvent = data[0];
      console.log('updated event', updatedEvent);

      // if event that was updated already had a route assigned, check updated times and update route to match
      if (updatedEvent.route_id) {
        let { data: route, error } = await supabase
          .from('routes')
          .select()
          .eq('id', updatedEvent.route_id)
          .single();

        console.log(
          'response from get route assigned to this event',
          route || error
        );

        let eventStartTime = getExactStart(
          updatedEvent.date,
          updatedEvent.start_time
        );
        let eventEndTime: Date | null = null;
        if (updatedEvent.end_time) {
          eventEndTime = getExactStart(
            updatedEvent.date,
            updatedEvent.end_time
          );
        }
        let eventRouteTime = getExactStart(
          updatedEvent.date,
          updatedEvent.route_time
        );
        let duration = route.est_duration;

        let routeStartTime = getExactStart(route.date, route.start_time);
        let routeEndTime: Date | null = null;
        if (route.end_time)
          routeEndTime = getExactStart(route.date, route.end_time);

        if (updatedEvent.arrive_by) {
          routeStartTime = sub(eventRouteTime, {
            minutes: duration,
          });
          if (eventEndTime) {
            routeEndTime = add(eventEndTime, {
              minutes: duration,
            });
          }
        } else {
          routeStartTime = eventRouteTime;
          if (eventEndTime) {
            routeEndTime = add(eventEndTime, {
              minutes: duration,
            });
          }
        }

        let UTCStart = dateObjToUTCTime(routeStartTime);
        let UTCEnd = null;

        if (routeEndTime) UTCEnd = dateObjToUTCTime(routeEndTime);

        const { data: updatedRoute, error: routeError } = await supabase
          .from('routes')
          .update({
            start_time: UTCStart,
            end_time: UTCEnd,
          })
          .eq('id', route.id);

        let driver = drivers.find((driver: any) => {
          return driver.id === route.driver_id;
        });

        console.log('response from update route', updatedRoute || error);

        // update driver if route changed
        if (updatedRoute) {
          let response = await updateDriverByEmail(updatedRoute[0], driver);
          console.log(response);
        }
      }
      setSelectedEvent(null);
      setSuccess('Event Updated');
      updateAllRoutes();
      getEvents(Number(sportID));
    }
    if (error) {
      setError(`Database Error: ${error.message}`);
    }
  };

  useEffect(() => {
    getSportInfo();
  }, []);

  useEffect(() => {
    setErrorText('');
  }, [newEvent, selectedEvent]);

  const removeEventHandler = async () => {
    // delete event
    let eventID = selectedEvent.id;
    let routeID = selectedEvent.route_id;
    if (!routeID) {
      const { data: deletedEvent, error } = await supabase
        .from(tableNames.events)
        .delete()
        .eq('id', eventID);

      if (deletedEvent) {
        // run get events again to reflect changes
        getEvents(selectedEvent.extracurricular_id);
        setRemoveEventModal(false);
        setSelectedEvent(null);
      }
    }
    
    // if route was assigned, set route id to null, event id to null on route, then delete both. f key relationship will prevent
    // deletion route/event id present
    
    if (routeID) {
      const { data: updatedEvent, error: eventError } = await supabase
        .from(tableNames.events)
        .update({ route_id: null })
        .eq('id', eventID);

      const { data: updatedRoute, error: routeError } = await supabase
        .from(tableNames.routes)
        .update({ event_id: null })
        .eq('id', routeID);

      if (updatedEvent && updatedRoute) {
        const { data: deletedEvent, error: eventDeleteError } = await supabase
          .from(tableNames.events)
          .delete()
          .eq('id', eventID);

        const { data: deletedRoute, error: routeDeleteError } = await supabase
          .from(tableNames.routes)
          .delete()
          .eq('id', routeID);

        if (deletedEvent && deletedRoute) {
          getEvents(selectedEvent.extracurricular_id);
          setRemoveEventModal(false);
          setSelectedEvent(null);
        }
      }
    }
  };

  return (
    <div style={{ marginTop: '5em' }}>
      {currentSport && (
        <>
          <p style={{ margin: 0, textAlign: 'center' }}>
            <b>{currentSport.gender}</b>
          </p>
          <h2
            style={{
              textAlign: 'center',
              padding: 0,
              margin: 0,
              fontSize: '2em',
            }}
          >
            {currentSport.title}
          </h2>
          <Paper
            elevation={5}
            className="sport-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              margin: '1em',
              padding: '1em',
            }}
          >
            <h3
              style={{
                margin: 1,
                padding: 0,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 'bold',
              }}
            >
              Staff
            </h3>
            {staff &&
              staff.map((coach: ICoach) => {
                return (
                  <Paper
                    elevation={2}
                    className="coach-details"
                    sx={{
                      borderRadius: '10px',
                      cursor: 'pointer',
                      margin: 3,
                    }}
                    key={coach.id}
                  >
                    <p
                      style={{
                        marginLeft: '1em',
                        textAlign: 'center',
                        fontSize: '1.3em',
                      }}
                    >
                      <b>
                        {coach.fname} {coach.lname}
                      </b>
                    </p>
                    <p style={{ marginLeft: '1em' }}>
                      <b>Email: </b>
                      {coach.email}
                    </p>
                    <p style={{ marginLeft: '1em' }}>
                      <b>Phone: </b>
                      {phoneNumberToString(coach.phone)}
                    </p>
                  </Paper>
                );
              })}
            <h3
              style={{
                margin: 1,
                padding: 0,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 'bold',
              }}
            >
              Roster
            </h3>
            {roster &&
              roster.map((student: IStudent) => {
                return (
                  <Paper
                    elevation={2}
                    className="student-details"
                    sx={{
                      borderRadius: '10px',
                      textAlign: 'left',
                      cursor: 'pointer',
                      margin: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/admin/students/${student.id}`)}
                    key={student.id}
                  >
                    <p style={{ marginLeft: '1em' }}>
                      <b>
                        {student.fname} {student.lname}
                      </b>
                    </p>
                    <p style={{ marginRight: '1em' }}>Grade: {student.grade}</p>
                  </Paper>
                );
              })}
          </Paper>
          <h2 style={{ textAlign: 'center' }}>Events</h2>
          <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant="contained"
              sx={{ textAlign: 'right' }}
              onClick={() => setEventModal(true)}
            >
              Add Event
            </Button>
          </Box>
          {events &&
            events.map((event: any) => {
              return (
                <EventCard
                  event={event}
                  key={event.id}
                  openModal={() => setEventToState(event.id)}
                  drivers={drivers}
                  vehicles={vehicles}
                  district={district}
                  tableNames={tableNames}
                  updateEvents={() => getEvents(event.extracurricular_id)}
                  updateAllRoutes={updateAllRoutes}
                />
              );
            })}
          {eventModal && (
            <Modal open={eventModal} onClose={() => setEventModal(false)}>
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '80%', md: 400 },
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  borderRadius: '10px',
                  boxShadow: 24,
                  paddingInline: '2em',
                  maxHeight: '90vh',
                  overflow: 'scroll',
                }}
                textAlign="center"
              >
                <h2 style={{ marginBottom: 0 }}>New Event</h2>
                <Box sx={{ marginTop: '1em' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date"
                      value={newEvent.date}
                      onChange={(newValue) => {
                        if (newValue)
                          setNewEvent({
                            ...newEvent,
                            date: newValue,
                          });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
                <Box style={{ margin: 0 }}>
                  <TextInput
                    label="Title"
                    value={newEvent.title}
                    changeHandler={(event) =>
                      setNewEvent({
                        ...newEvent,
                        title: event.target.value,
                      })
                    }
                  />
                </Box>
                <TextInput
                  label="Location"
                  value={newEvent.location}
                  changeHandler={(event) =>
                    setNewEvent({
                      ...newEvent,
                      location: event.target.value,
                    })
                  }
                />
                <TextInput
                  label="Stop Instructions"
                  value={newEvent.stop_instructions}
                  changeHandler={(event) =>
                    setNewEvent({
                      ...newEvent,
                      stop_instructions: event.target.value,
                    })
                  }
                />

                <Box sx={{ marginTop: '2em' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="Start Time"
                      value={newEvent.start_time}
                      onChange={(newValue) => {
                        if (newValue) {
                          setNewEvent({
                            ...newEvent,
                            start_time: newValue,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '150px' }} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ marginTop: '2em' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="End Time"
                      value={newEvent.end_time}
                      onChange={(newValue) => {
                        if (newValue) {
                          setNewEvent({
                            ...newEvent,
                            end_time: newValue,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '150px' }} />
                      )}
                    />
                  </LocalizationProvider>
                  <br />
                  {newEvent.end_time && (
                    <Button
                      onClick={() =>
                        setNewEvent({
                          ...newEvent,
                          end_time: null,
                        })
                      }
                      sx={{ color: 'red', fontSize: '.8em' }}
                    >
                      Reset
                    </Button>
                  )}
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel id="arrive-by-label">Route Time</FormLabel>
                    <RadioGroup
                      aria-labelledby="arrive-by-radio-group"
                      value={newEvent.arrive_by ? 'arriveBy' : 'departAt'}
                      name="radio-buttons-group"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let value = event.target.value;
                        console.log(value);
                        if (value === 'arriveBy')
                          setNewEvent({ ...newEvent, arrive_by: true });
                        else
                          setNewEvent({
                            ...newEvent,
                            arrive_by: false,
                          });
                      }}
                    >
                      <FormControlLabel
                        value={'arriveBy'}
                        control={<Radio />}
                        label="Arrive By"
                      />
                      <FormControlLabel
                        value="departAt"
                        control={<Radio />}
                        label="Depart At"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label="Route Time"
                    value={newEvent.route_time}
                    onChange={(newValue) => {
                      if (newValue) {
                        setNewEvent({
                          ...newEvent,
                          route_time: newValue,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>
                <br />
                {errorText && (
                  <p style={{ color: 'red', fontSize: '.8em' }}>{errorText}</p>
                )}
                <Button
                  variant="contained"
                  onClick={addEventHandler}
                  sx={{ margin: '1em 0' }}
                >
                  Add Event
                </Button>
              </Box>
            </Modal>
          )}
          {selectedEvent && (
            <Modal
              open={selectedEvent ? true : false}
              onClose={() => setSelectedEvent(null)}
            >
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '80%', md: 400 },
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  borderRadius: '10px',
                  boxShadow: 24,
                  paddingInline: '2em',
                  maxHeight: '90vh',
                  overflow: 'scroll',
                }}
                textAlign="center"
              >
                {selectedEvent && (
                  <>
                    <h2 style={{ marginBottom: 0 }}>Edit Event</h2>
                    <Button
                      sx={{ color: 'red' }}
                      onClick={() => setRemoveEventModal(true)}
                    >
                      Remove
                    </Button>
                    <Box sx={{ marginTop: '1em' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date"
                          value={selectedEvent.date}
                          onChange={(newValue) => {
                            if (newValue)
                              setSelectedEvent({
                                ...selectedEvent,
                                date: newValue,
                              });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box style={{ margin: 0 }}>
                      <TextInput
                        label="Title"
                        value={selectedEvent.title}
                        changeHandler={(event) =>
                          setSelectedEvent({
                            ...selectedEvent,
                            title: event.target.value,
                          })
                        }
                      />
                    </Box>
                    <TextInput
                      label="Location"
                      value={selectedEvent.location}
                      changeHandler={(event) =>
                        setSelectedEvent({
                          ...selectedEvent,
                          location: event.target.value,
                        })
                      }
                    />
                    <TextInput
                      label="Stop Instructions"
                      value={selectedEvent.stop_instructions}
                      changeHandler={(event) =>
                        setSelectedEvent({
                          ...selectedEvent,
                          stop_instructions: event.target.value,
                        })
                      }
                    />

                    <Box sx={{ marginTop: '2em' }}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          label="Start Time"
                          value={selectedEvent.start_time}
                          onChange={(newValue) => {
                            if (newValue) {
                              setSelectedEvent({
                                ...selectedEvent,
                                start_time: newValue,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: '150px' }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ marginTop: '2em' }}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          label="End Time"
                          value={selectedEvent.end_time}
                          onChange={(newValue) => {
                            if (newValue) {
                              setSelectedEvent({
                                ...selectedEvent,
                                end_time: newValue,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: '150px' }} />
                          )}
                        />
                      </LocalizationProvider>
                      {selectedEvent.end_time && (
                        <Button
                          onClick={() =>
                            setSelectedEvent({
                              ...selectedEvent,
                              end_time: null,
                            })
                          }
                          sx={{ color: 'red', fontSize: '.8em' }}
                        >
                          Reset
                        </Button>
                      )}
                    </Box>
                    <Box>
                      <FormControl>
                        <FormLabel id="arrive-by-label">Route Time</FormLabel>
                        <RadioGroup
                          aria-labelledby="arrive-by-radio-group"
                          value={
                            selectedEvent.arrive_by ? 'arriveBy' : 'departAt'
                          }
                          name="radio-buttons-group"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            let value = event.target.value;
                            console.log(value);
                            if (value === 'arriveBy')
                              setSelectedEvent({
                                ...selectedEvent,
                                arrive_by: true,
                              });
                            else
                              setSelectedEvent({
                                ...selectedEvent,
                                arrive_by: false,
                              });
                          }}
                        >
                          <FormControlLabel
                            value={'arriveBy'}
                            control={<Radio />}
                            label="Arrive By"
                          />
                          <FormControlLabel
                            value="departAt"
                            control={<Radio />}
                            label="Depart At"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <TimePicker
                        label="Route Time"
                        value={selectedEvent.route_time}
                        onChange={(newValue) => {
                          if (newValue) {
                            setSelectedEvent({
                              ...selectedEvent,
                              route_time: newValue,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: '150px' }} />
                        )}
                      />
                    </LocalizationProvider>
                    <br />
                  </>
                )}
                {removeEventModal && (
                  <Modal
                    open={removeEventModal}
                    onClose={() => setRemoveEventModal(false)}
                  >
                    <Box
                      sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80%', md: 400 },
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        borderRadius: '10px',
                        boxShadow: 24,
                        paddingInline: '2em',
                      }}
                      textAlign="center"
                    >
                      <p>Are you sure you want to remove this event?</p>
                      <Button
                        variant="contained"
                        sx={{ marginBottom: '1em' }}
                        onClick={removeEventHandler}
                      >
                        Remove Event
                      </Button>
                    </Box>
                  </Modal>
                )}
                {errorText && (
                  <p style={{ color: 'red', fontSize: '.8em' }}>{errorText}</p>
                )}
                <Button
                  variant="contained"
                  onClick={updateEventHandler}
                  sx={{ margin: '1em 0' }}
                >
                  Update Event
                </Button>
              </Box>
            </Modal>
          )}
          {success && (
            <SuccessModal
              open={success ? true : false}
              onClose={() => setSuccess('')}
              text={success}
            />
          )}
          {error && (
            <ErrorModal
              open={error ? true : false}
              onClose={() => setError('')}
              text={error}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdminSingleSport;
