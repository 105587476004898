import { useState, useEffect, Fragment } from 'react';

import { useNavigate } from 'react-router-dom';

import { Paper, Box, Button, Tooltip } from '@mui/material';
import { IDriver } from '../../ts/interfaces';

import {
  capitalize,
  phoneNumberToString,
} from '../../utils/textFormattingHelpers';
import { formatDate } from '../../utils/dateTimeHelpers';
import { startOfWeek, add, sub, format, isBefore, isEqual, isAfter } from 'date-fns';

type DriverAvailabilityDisplayProps = {
  drivers: IDriver[];
  routes: any;
  districtTimes: any;
};

// basic way to visualize number of routes and drivers available in each block of each day
const DriverAvailabilityDisplay = ({
  drivers,
  routes,
  districtTimes
}: DriverAvailabilityDisplayProps) => {
  const [thisWeek, setThisWeek] = useState<any>();
  const [fullWeekRoutes, setFullWeekRoutes] = useState<any[]>();
  const [weekLoaded, setWeekLoaded] = useState(false);

  // set initial state
  useEffect(() => {
    let fullWeek: any = [
      {
        day: 'Monday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Tuesday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Wednesday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Thursday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Friday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Saturday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
      {
        day: 'Sunday',
        morningDrivers: [],
        morningRoutes: 0,
        middayDrivers: [],
        middayRoutes: 0,
        afternoonDrivers: [],
        afternoonRoutes: 0,
        eveningDrivers: [],
        eveningRoutes: 0,
      },
    ];

    setThisWeek(fullWeek);
    setWeekLoaded(true);
  }, []);

  useEffect(() => {
    if (thisWeek) {
      let fullWeek = [...thisWeek];
      // for each day of week
      for (let i = 0; i < fullWeek.length; i++) {
        let currentDay = fullWeek[i];

        // loop through drivers - if driver is avaiable for given block, add name to that block
        for (let j = 0; j < drivers.length; j++) {
          let thisDriverAvail = drivers[j].weekly_availability.slice(0, 7);
          let availForCurrentDay = thisDriverAvail[i];
          if (availForCurrentDay.morning)
            fullWeek[i].morningDrivers.push(`${drivers[j].fname} ${drivers[j].lname}`);
          if (availForCurrentDay.midday)
            fullWeek[i].middayDrivers.push(`${drivers[j].fname} ${drivers[j].lname}`);
          if (availForCurrentDay.afternoon)
            fullWeek[i].afternoonDrivers.push(
              `${drivers[j].fname} ${drivers[j].lname}`
            );
          if (availForCurrentDay.evening)
            fullWeek[i].eveningDrivers.push(`${drivers[j].fname} ${drivers[j].lname}`);
        }
      }
      // filter routes from context to just routes for current week
      let fullWeeksRoutes = filterThisWeeksRoutes(routes);

      // for each day of week, check which time block route falls in, add to number of routes for that block on that day
      for (let i = 0; i < fullWeeksRoutes.length; i++) {
        for (let j = 0; j < fullWeeksRoutes[i].routes.length; j++) {
          let slot = determineBlockForRoute(fullWeeksRoutes[i].routes[j], fullWeeksRoutes[i].dateObj)
          if (slot === 'morning') fullWeek[i].morningRoutes++;
          if (slot === 'midday') fullWeek[i].middayRoutes++;
          if (slot === 'afternoon') fullWeek[i].afternoonRoutes++;
          if (slot === 'evening') fullWeek[i].eveningRoutes++;
        }
      }
      setThisWeek(fullWeek);
    }
  }, [weekLoaded]);

  const routeDateFormat = (date: Date) => {
    // pad 0 if necessary - note months using .getMonth() are off by 1 - zero indexed
    let dateArray = [
      String(date.getFullYear()),
      String(date.getMonth() + 1),
      String(date.getDate()),
    ];
    if (dateArray[1].length === 1) dateArray[1] = `0${dateArray[1]}`;
    return dateArray.join('-');
  };

  // takes route, returns javascript date object from route date and start time
    const dateTimeOfRouteStart = (route: any) => {
      let timeArray = route.start_time.split(':');
      let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
      let dateArray = route.date.split('-').map((date: string) => {
        return Number(date);
      });
      let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
      let formattedDate = format(newDate, 'MMMM d, yyyy');
      let dateStr = `${formattedDate} ${newTimeString} UTC`;
      return new Date(dateStr);
    };

    const dateTimeOfRouteEnd = (route: any) => {
      let timeArray = route.end_time.split(':');
      let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
      let dateArray = route.date.split('-').map((date: string) => {
        return Number(date);
      });
      let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
      let formattedDate = format(newDate, 'MMMM d, yyyy');
      let dateStr = `${formattedDate} ${newTimeString} UTC`;
      return new Date(dateStr);
    };

  const determineBlockForRoute = (route: any, date: Date) => {
    let exactStartTime = dateTimeOfRouteStart(route);
    let exactEndTime = dateTimeOfRouteEnd(route);

    let currentDayAsDateString = date.toDateString();

    // get date object for school start and end, to run comparisons to route times, to determine block route should fall in
    let amSchoolStart = new Date(
      `${currentDayAsDateString} ${districtTimes.start_time} UTC`
    );
    let pmSchoolEnd = new Date(
      `${currentDayAsDateString} ${districtTimes.end_time} UTC`
    );

    let amRouteEnd = sub(amSchoolStart, {
      minutes: districtTimes.am_buffer,
    });

    let pmRouteStart = add(pmSchoolEnd, {
      minutes: districtTimes.pm_buffer,
    });

    if (
      isBefore(exactEndTime, amRouteEnd) ||
      isEqual(exactEndTime, amRouteEnd) ||
      isBefore(exactStartTime, amRouteEnd) ||
      isEqual(exactStartTime, amRouteEnd)
    ) {
      return 'morning'
    } else if (isEqual(exactStartTime, pmRouteStart)) {
      return 'afternoon'
    } else if (
      isBefore(exactStartTime, pmRouteStart) &&
      isAfter(exactStartTime, amRouteEnd)
    ) {
      return 'midday'
    } else if (isAfter(exactStartTime, pmRouteStart)) {
      return 'evening'
    }
  }

  const filterThisWeeksRoutes = (routes: any[]) => {
    // week starts on Monday
    let firstDay = startOfWeek(Date.now(), { weekStartsOn: 1 });
    // string version of first day to match against date field of routes
    let firstDayString = routeDateFormat(firstDay);
    // store value for the first day that will not be included - where to break loop
    let pastDay = add(firstDay, { weeks: 1 });
    // array for full week = js date object for date, day of week, string date for comparison, routes that match that date
    let fullWeekArray: any[] = [
      {
        dateObj: firstDay,
        day: firstDay.getDay(),
        dateString: firstDayString,
        routes: [],
      },
    ];
    for (let i = 1; i < 7; i++) {
      let newDate = add(firstDay, { days: i });
      fullWeekArray.push({
        dateObj: newDate,
        day: newDate.getDay(),
        dateString: routeDateFormat(newDate),
        routes: [],
      });
    }


    let indexOfFirstRoute = -1;
    // loop through routes, set index of first route encountered that matches first day, then break loop
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].date === firstDayString && indexOfFirstRoute < 0) {
        indexOfFirstRoute = i;
        break;
      }
    }
    // slice routes array at index of first route to remove routes older than today
    let slicedRoutes = routes.slice(indexOfFirstRoute);

    // loop through sliced routes
    for (let i = 0; i < slicedRoutes.length; i++) {
      let thisRoute = slicedRoutes[i];
      // if route is past window, break
      if (thisRoute.date === routeDateFormat(pastDay)) break;

      // find day to add route to and push
      let dayOfArray = fullWeekArray.find((day: any) => {
        return day.dateString === thisRoute.date;
      });
      if (dayOfArray) {
        dayOfArray.routes.push(thisRoute);
      }
    }
    console.log(fullWeekArray);
    return fullWeekArray;
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <h2>This Week</h2>
      {thisWeek && (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginTop: '2.2em' }}>
            <p style={{ margin: 0, padding: 17 }}>Morning:</p>
            <p style={{ margin: 0, padding: 17 }}>Midday:</p>
            <p style={{ margin: 0, padding: 17 }}>Afternoon:</p>
            <p style={{ margin: 0, padding: 17 }}>Evening:</p>
          </Box>
          {thisWeek.map((day: any, index: number) => {
            return (
              <Box sx={{ width: 'fit-content' }} key={day.day}>
                <h3
                  style={{
                    borderBottom: '1px solid black',
                    margin: 0,
                    padding: 5,
                  }}
                >
                  {day.day}
                </h3>

                <Tooltip
                  title={
                    <Fragment>
                      {day.morningDrivers.length > 0 &&
                        day.morningDrivers.map((name: any) => {
                          return (
                            <>
                              <p key={name}>{name}</p>
                            </>
                          );
                        })}
                      {day.morningDrivers.length === 0 && <p>None</p>}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      backgroundColor:
                        day.morningDrivers.length < day.morningRoutes
                          ? '#FFCCCB'
                          : 'lightgreen',
                      margin: 0,
                      outline: '1px solid black',
                      padding: 5,
                    }}
                  >
                    Drivers: {day.morningDrivers.length} Routes: {day.morningRoutes}
                  </p>
                </Tooltip>

                <Tooltip
                  title={
                    <Fragment>
                      {day.middayDrivers.length > 0 &&
                        day.middayDrivers.map((name: any) => {
                          return (
                            <>
                              <p>{name}</p>
                            </>
                          );
                        })}
                      {day.middayDrivers.length === 0 && <p>None</p>}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      backgroundColor:
                        day.middayDrivers.length < day.middayRoutes
                          ? '#FFCCCB'
                          : 'lightgreen',
                      margin: 0,
                      outline: '1px solid black',
                      padding: 5,
                    }}
                  >
                    Drivers: {day.middayDrivers.length} Routes:{' '}
                    {day.middayRoutes}
                  </p>
                </Tooltip>

                <Tooltip
                  title={
                    <Fragment>
                      {day.afternoonDrivers.length > 0 &&
                        day.afternoonDrivers.map((name: any) => {
                          return (
                            <>
                              <p>{name}</p>
                            </>
                          );
                        })}
                      {day.afternoonDrivers.length === 0 && <p>None</p>}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      backgroundColor:
                        day.afternoonDrivers.length < day.afternoonRoutes
                          ? '#FFCCCB'
                          : 'lightgreen',
                      margin: 0,
                      outline: '1px solid black',
                      padding: 5,
                    }}
                  >
                    Drivers: {day.afternoonDrivers.length} Routes:{' '}
                    {day.afternoonRoutes}
                  </p>
                </Tooltip>

                <Tooltip
                  title={
                    <Fragment>
                      {day.eveningDrivers.length > 0 &&
                        day.eveningDrivers.map((name: any) => {
                          return (
                            <>
                              <p>{name}</p>
                            </>
                          );
                        })}
                      {day.eveningDrivers.length === 0 && (
                        <p style={{ color: 'none' }}>None</p>
                      )}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      backgroundColor:
                        day.eveningDrivers.length < day.eveningRoutes
                          ? '#FFCCCB'
                          : 'lightgreen',
                      margin: 0,
                      outline: '1px solid black',
                      padding: 5,
                    }}
                  >
                    Drivers: {day.eveningDrivers.length} Routes: {day.eveningRoutes}
                  </p>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

type DriverCardProps = {
  driver: IDriver;
};

const DriverCard = ({ driver }: DriverCardProps) => {
  const navigate = useNavigate();

  const slotColor = (avail: boolean) => {
    if (avail) return 'lightgreen';
    return '#FFCCCB';
  };

  const baseAvailCheck = (value: boolean) => {
    if (value) return 'O';
    return 'X';
  };

  return (
    <Paper
      elevation={5}
      className="driver-details"
      sx={{
        borderRadius: '30px',
        textAlign: 'left',
        padding: '1em 0 1em',
        margin: '1em',
      }}
    >
      <Box sx={{}}>
        <h2 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          {driver.fname} {driver.lname}
        </h2>
        <h3
          style={{
            textAlign: 'center',
            fontSize: '1em',
            padding: 0,
            margin: 0,
          }}
        >
          {driver.email || 'No email'}
        </h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/admin/drivers/${driver.id}`);
            }}
            sx={{ margin: '0 auto' }}
          >
            Edit
          </Button>
        </div>
        <Box
          sx={{
            borderRadius: '30px',
            padding: { sm: '0 1em 0 1em', md: '1em 2em 1em 2em' },
            outline: { md: '1px solid grey' },
            margin: { md: '1em' },
          }}
        >
          <p>
            <b>Baseline Availability</b> (as of {formatDate(new Date())}{' '}
            {new Date().getFullYear()})
          </p>
          <Box
            sx={{
              display: 'flex',
              width: 'fit-content',
            }}
          >
            <div
              id="blocks-container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '4.1em',
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 10,
                  fontWeight: 'bold',
                }}
              >
                Morning
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 10,
                  fontWeight: 'bold',
                }}
              >
                Midday
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 10,
                  fontWeight: 'bold',
                }}
              >
                Afternoon
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 10,
                  fontWeight: 'bold',
                }}
              >
                Evening
              </p>
            </div>
            {driver.baseline_availability.map((day: any) => {
              return (
                <div id="day" key={day.day} style={{}}>
                  <h3
                    style={{
                      textAlign: 'center',
                      textDecoration: 'underline',
                    }}
                  >
                    {capitalize(day.day)[0]}
                    {day.day[1]}
                  </h3>
                  <div
                    id="blocks-container"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <p
                      style={{
                        outline: '1px solid black',
                        margin: 0,
                        padding: 10,
                        backgroundColor: `${slotColor(day.morning)}`,
                        fontWeight: 'bold',
                      }}
                    >
                      {baseAvailCheck(day.morning)}
                    </p>
                    <p
                      style={{
                        outline: '1px solid black',
                        margin: 0,
                        padding: 10,
                        backgroundColor: `${slotColor(day.midday)}`,
                        fontWeight: 'bold',
                      }}
                    >
                      {baseAvailCheck(day.midday)}
                    </p>
                    <p
                      style={{
                        outline: '1px solid black',
                        margin: 0,
                        padding: 10,
                        backgroundColor: `${slotColor(day.afternoon)}`,
                        fontWeight: 'bold',
                      }}
                    >
                      {baseAvailCheck(day.afternoon)}
                    </p>
                    <p
                      style={{
                        outline: '1px solid black',
                        margin: 0,
                        padding: 10,
                        backgroundColor: `${slotColor(day.evening)}`,
                        fontWeight: 'bold',
                      }}
                    >
                      {baseAvailCheck(day.evening)}
                    </p>
                  </div>
                </div>
              );
            })}
          </Box>
          <p>
            <b>Driver ID: </b>
            {driver.id}
          </p>
          <p>
            <b>Phone: </b>
            {phoneNumberToString(driver.phone) || 'None'}
          </p>
          <p>
            <b>Address: </b>
            {driver.home_address || 'None'}
          </p>
          <p>
            <b>License: </b>
            {driver.license}
          </p>
        </Box>
      </Box>
    </Paper>
  );
};

type AdminDriversProps = {
  drivers: IDriver[];
  tableNames: any;
  routes: any;
  districtTimes: any;
};

const AdminDrivers = ({ drivers, tableNames, routes, districtTimes }: AdminDriversProps) => {
  const [sortedDrivers, setSortedDrivers] = useState<IDriver[]>([]);

  useEffect(() => {
    let driversCopy = [...drivers];

    // sort drivers by last name
    driversCopy.sort((a: any, b: any) => {
      let aLetter = a.lname.charCodeAt(0);
      let bLetter = b.lname.charCodeAt(0);
      return aLetter - bLetter;
    });
    setSortedDrivers(driversCopy);
  }, []);

  return (
    <div className="admin-app-parent">
      {sortedDrivers && (
        <>
          <h1 style={{ marginTop: '3em', textAlign: 'center' }}>Drivers</h1>
          <DriverAvailabilityDisplay drivers={sortedDrivers} routes={routes} districtTimes={districtTimes}/>
          {sortedDrivers.map((driver: IDriver) => {
            return <DriverCard key={driver.id} driver={driver} />;
          })}
        </>
      )}
    </div>
  );
};

export default AdminDrivers;
