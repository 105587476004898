import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link, Routes, Route } from 'react-router-dom';

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  CircularProgress,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import HomeIcon from '@mui/icons-material/Home';
import RoomIcon from '@mui/icons-material/Room';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import GroupIcon from '@mui/icons-material/Group';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CommuteIcon from '@mui/icons-material/Commute';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import './AdminDashboard.css';

import { useAuth } from '../../contexts/Auth';
import { supabase } from '../../db/client';

import OEInterface from '../OEInterface';
import AdminHome from '../AdminHome';
import AdminSports from '../AdminSports';
import AdminStudents from '../AdminStudents';
import AdminDrivers from '../AdminDrivers';
import AdminVehicles from '../AdminVehicles';
import AdminLiveMap from '../AdminLiveMap';
import AdminSingleRoute from '../AdminSingleRoute';
import AdminSingleDriver from '../AdminSingleDriver';
import AdminSingleStudent from '../AdminSingleStudent';
import AdminSingleVehicle from '../AdminSingleVehicle';
import AdminSingleSport from '../AdminSingleSport';
import AdminNewStudent from '../AdminNewStudent';
import AdminDistrict from '../AdminDistrict';
import AdminPastRoutes from '../AdminPastRoutes';
import AdminProfile from '../AdminProfile';
import AdminFieldTrips from '../AdminFieldTrips';

import { IDriver, IRoute, IDistrictTimes } from '../../ts/interfaces';

import { streetAddressToLatLong } from '../../utils/geolocation'

// left nav bar pages
const pages = [
  {
    title: 'Home',
    href: '/admin/home',
    icon: HomeIcon,
  },
  {
    title: 'Routes',
    href: '/admin/oe-interface',
    icon: RoomIcon,
  },
  {
    title: 'Athletics',
    href: '/admin/sports',
    icon: SportsFootballIcon,
  },
  {
    title: 'Students',
    href: '/admin/students',
    icon: GroupIcon,
  },
  {
    title: 'Drivers',
    href: '/admin/drivers',
    icon: ContactPageIcon,
  },
  {
    title: 'Vehicles',
    href: '/admin/vehicles',
    icon: CommuteIcon,
  },
  {
    title: 'Routes In Progress',
    href: '/admin/live-map',
    icon: GpsFixedIcon,
  },
];

const AdminDashboard = () => {
  const [allRoutes, setAllRoutes] = useState<IRoute[] | null>();
  const [allDrivers, setAllDrivers] = useState<IDriver[] | null>();
  const [allVehicles, setAllVehicles] = useState<any>();
  const [districtInfo, setDistrictInfo] = useState<any>();

  const [loading, setLoading] = useState(true);
  const [tableNames, setTableNames] = useState<any>({});
  const [districtTimes, setDistrictTimes] = useState<IDistrictTimes | null>();

  const navigate = useNavigate();

  const { user, logOut } = useAuth();

  useEffect(() => {
    let environment = process.env.NODE_ENV;

    // temporary logic set up to use duplicate tables in development - currently using standard tables but logic left in place
    if (environment === 'development') {
      console.log('You are running the dev server and using duplicate tables');
      setTableNames({
        routes: 'routes',
        drivers: 'drivers',
        vehicles: 'vehicles',
        students: 'students',
        contacts: 'contacts',
        extracurriculars: 'extracurriculars',
        events: 'events',
        districts: 'districts',
        reports: 'reports',
        field_trips: 'field_trips',
      });
    } else if (environment === 'production') {
      setTableNames({
        routes: 'routes',
        drivers: 'drivers',
        vehicles: 'vehicles',
        students: 'students',
        contacts: 'contacts',
        extracurriculars: 'extracurriculars',
        events: 'events',
        districts: 'districts',
        reports: 'reports',
        field_trips: 'field_trips'
      });
    }
  }, []);

  // use effect checks user, if admin allow, if driver or new user redirect to driver home
  useEffect(() => {
    if (user) {
      if (user.role) {
        if (user.role === 'admin' && tableNames.routes && tableNames.drivers) {
          // if user is admin and tablenames set run methods to get all relevant district info on page load
          setLoading(false);
          getAllDrivers();
          getAllRoutes();
          getAllVehicles();
          getDistrictTimes();
          getDistrictInfo();
        }
        if (user.role === 'driver') {
          navigate('/');
        }
      }
      if (user.newUser) {
        console.log(user);
        navigate('/');
      }
    }
  }, [user, tableNames]);

  const getAllDrivers = async () => {
    let { data: drivers, error } = await supabase
      .from(tableNames.drivers)
      .select('*')
      .eq('district_id', user.district_id);

    if (drivers) setAllDrivers(drivers);
    if (error) console.log(error);
  };

  const getAllVehicles = async () => {
    let { data: vehicles, error } = await supabase
      .from(tableNames.vehicles)
      .select('*')
      .eq('district_id', user.district_id);

    if (vehicles) setAllVehicles(vehicles);
    if (error) console.log(error);
  };

  let routeInfoForCall = `complete, cumulative_rider_time, data, date, distance, district_id, ${tableNames.drivers} (*), driver_id, end_time, est_duration, event_id, extracurricular_id, field_trip_id, id, longest_rider_time, navigation_start, navigation_end, start_time, stop_count, students_count, type, vehicle_id, ${tableNames.vehicles} (*), in_progress`;
  const getAllRoutes = async () => {
    let { data: routes, error } = await supabase
      .from(tableNames.routes)
      .select(routeInfoForCall)
      .eq('district_id', user.district_id)
      .order('date');

    if (routes) setAllRoutes(routes);
    if (error) console.log(error);
  };

  const getDistrictTimes = async () => {
    let { data: timeData, error } = await supabase
      .from('districts')
      .select('start_time, end_time, am_buffer, pm_buffer')
      .eq('id', user.district_id);

    if (timeData) setDistrictTimes(timeData[0]);
    if (error) console.log(error);
  };

  const getDistrictInfo = async () => {
    
    let { data: district, error } = await supabase
      .from('districts')
      .select()
      .eq('id', user.district_id);

    if (district) {
      // don't think I need this call
      // let latLong = await streetAddressToLatLong(district[0].depot);
      // district[0].latLong = latLong;
      setDistrictInfo(district[0]);
    }
    if (error) console.log(error);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // pages in user menu
  const settings = [
    {
      title: 'Profile',
      method: () => {
        navigate('/admin/profile');
      },
    },
    {
      title: 'My Routes',
      method: () => {
        window.location.assign('/');
      },
    },
    {
      title: 'District',
      method: () => navigate('/admin/district'),
    },
    {
      title: 'Log Out',
      method: () => {
        logOut();
      },
    },
  ];

  // simple method to create abbreviated version of district name to display on smaller screens
  const abbreviateDistrictName = ( name: string) => {
    let nameArray = name.split(' ');
    for (let i = 0; i < nameArray.length; i++) {
      nameArray[i] = nameArray[i][0];
    };
    return nameArray.join('');
  }

  return (
    <div className="admin-app-parent">
      {!loading ? (
        <>
        {/* AppBar component contains the dashboard overlay for admin pages - formatted differently by screen size using
        display property on Material components below */}
          <AppBar
            position="fixed"
            className="appBar"
            sx={{ zIndex: 999, backgroundColor: 'white', color: 'black' }}
          >
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <img
                  src="/logo192.png"
                  alt="Fleet Lab logo"
                  style={{ width: '50px', margin: 0, padding: 0 }}
                ></img>
                <Typography
                  variant="h6"
                  noWrap
                  component="p"
                  sx={{
                    mr: 2,
                    ml: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'arial',
                    fontWeight: 500,
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  Fleet Lab | {districtInfo ? districtInfo.name : ''}
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages.map((page) => (
                      <Link to={page.href} key={page.title}>
                        <MenuItem onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">
                            {page.title}
                          </Typography>
                        </MenuItem>
                      </Link>
                    ))}
                  </Menu>
                </Box>
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href=""
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {districtInfo ? `${abbreviateDistrictName(districtInfo.name)}` : ''}
                </Typography>
                <Box
                  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                ></Box>

                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                          mr: 2,
                          display: { xs: 'none', md: 'flex' },
                          flexGrow: 1,
                          fontFamily: 'arial',
                          fontWeight: 500,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        {user.email}
                      </Typography>

                      <Avatar
                        id="avatar"
                        src="/headshot.png"
                        sx={{
                          color: '#b29430',
                          backgroundColor: '#004812',
                          width: 56,
                          height: 56,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <Typography
                        textAlign="center"
                        onClick={setting.method}
                        key={setting.title}
                      >
                        <MenuItem onClick={handleCloseUserMenu}>
                          {setting.title}
                        </MenuItem>
                      </Typography>
                    ))}
                  </Menu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>

          {/* left nav bar on md + screens */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              position: 'fixed',
              left: 0,
              top: 65,
              width: 70,
              height: '98vh',
              flexDirection: 'column',
              borderRight: '1px solid gray',
            }}
          >
            {pages.map((page: any, index) => {
              return (
                <div
                  style={{
                    borderBottom: '1px solid gray',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  key={page.title}
                >
                  <Tooltip title={page.title} placement="right">
                    <Link
                      to={page.href}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <page.icon
                        fontSize="medium"
                        style={{ width: '75%', height: '75%', margin: 10 }}
                      />
                    </Link>
                  </Tooltip>
                </div>
              );
            })}
          </Box>

          {/* nested router for admin pages */}
          {allRoutes && allDrivers && districtTimes && (
            <Routes>
              <Route path="oe-interface" element={<OEInterface />} />
              <Route
                path="home"
                element={
                  <AdminHome
                    drivers={allDrivers}
                    routes={allRoutes}
                    districtTimes={districtTimes}
                    tableNames={tableNames}
                    districtInfo={districtInfo}
                  />
                }
              />
              <Route
                path="routes/:id"
                element={
                  <AdminSingleRoute
                    drivers={allDrivers}
                    routes={allRoutes}
                    vehicles={allVehicles}
                    districtTimes={districtTimes}
                    tableNames={tableNames}
                    updateAllRoutes={getAllRoutes}
                    districtInfo={districtInfo}
                  />
                }
              />
              <Route 
                path='routes/past'
                element={
                  <AdminPastRoutes 
                    tableNames={tableNames}
                    drivers={allDrivers}
                  />
                }
              />

              {/* for driver pages */}
              <Route
                path="drivers/:id"
                element={
                  <AdminSingleDriver
                    drivers={allDrivers}
                    tableNames={tableNames}
                    updateAllDrivers={getAllDrivers}
                  />
                }
              />
              {/* for student pages */}
              <Route
                path="students/:id"
                element={<AdminSingleStudent tableNames={tableNames} />}
              />
              {/* for vehicle pages */}
              <Route
                path="vehicles/:id"
                element={
                  <AdminSingleVehicle
                    vehicles={allVehicles}
                    tableNames={tableNames}
                    updateAllVehicles={getAllVehicles}
                  />
                }
              />
              {/* for sports pages */}
              <Route
                path="sports/:id"
                element={
                  <AdminSingleSport
                    tableNames={tableNames}
                    drivers={allDrivers}
                    vehicles={allVehicles}
                    district={districtInfo}
                    updateAllRoutes={getAllRoutes}
                  />
                }
              />
              <Route
                path="sports"
                element={<AdminSports tableNames={tableNames} />}
              />
              <Route
                path="students"
                element={<AdminStudents tableNames={tableNames} />}
              />
              <Route
                path="students/new"
                element={<AdminNewStudent tableNames={tableNames} />}
              />
              <Route
                path="drivers"
                element={
                  <AdminDrivers drivers={allDrivers} tableNames={tableNames} routes={allRoutes} districtTimes={districtTimes}/>
                }
              />
              <Route
                path="vehicles"
                element={
                  <AdminVehicles
                    vehicles={allVehicles}
                    tableNames={tableNames}
                    updateAllVehicles={getAllVehicles}
                  />
                }
              />
              <Route path="live-map" element={<AdminLiveMap routes={allRoutes} tableNames={tableNames} drivers={allDrivers} districtInfo={districtInfo} />} />
              <Route
                path="district"
                element={<AdminDistrict tableNames={tableNames} />}
              />
              <Route path='profile' element={<AdminProfile />} />
              <Route path='field-trips' element={<AdminFieldTrips tableNames={tableNames} drivers={allDrivers} vehicles={allVehicles} district={districtInfo}updateAllRoutes={getAllRoutes}/>} />
            </Routes>
          )}
        </>
      ) : (
        // progress wheel when loading
        <CircularProgress
          size={100}
          sx={{ position: 'absolute', top: '40%', left: '45%' }}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
