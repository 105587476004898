import { useAuth } from '../../contexts/Auth';
import { Paper, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { phoneNumberToString} from '../../utils/textFormattingHelpers';

// basic user profile information
const AdminProfile = () => {

  const { user } = useAuth();
  const navigate = useNavigate();

  console.log(user);
  return (
    <div style={{ marginTop: '5em', textAlign: 'center' }}>
      <h1>Profile</h1>
      <Paper elevation={5} sx={{ paddingBottom: '1em'}}>
        <h2>
          {user.fname} {user.lname}
        </h2>
        <p>{user.email}</p>
        <Box sx={{ textAlign: 'left', marginLeft: '2em' }}>
          <p>
            <b>Role: </b>
            {user.role}
          </p>
          <p>
            <b>Address: </b>
            {user.home_address || 'None'}
          </p>
          <p>
            <b>Phone: </b>
            {phoneNumberToString(user.phone)}
          </p>
          {typeof user.id === 'number' && (
            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '2em' }}>
              <Button
                variant="contained"
                onClick={() => navigate(`/admin/drivers/${user.id}`)}
              >
                My Driver Page
              </Button>
              <Button variant="contained" onClick={() => navigate('/')}>
                Open Driver App
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </div>
  );
}

export default AdminProfile
