import { supabase } from '../client.tsx';

// globals
const validDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const validShifts = ['morning', 'midday', 'afternoon', 'evening'];
const weeksAhead = 2;

// (helper function, baseline) sends updated baseline availability JSON to Supabase
export async function pushBaselineAvailabilityUpdate(id, newBaseline) {
  let { data: drivers, error } = await supabase
    .from('drivers')
    .update({ baseline_availability: newBaseline })
    .eq('user_id', id);

  if (drivers) return drivers;
  if (error) {
    console.error(error);
    return error;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return error;
  }
}

// (helper function, weekly) sends updated weekly availability JSON to Supabase
export async function pushWeeklyAvailabilityUpdate(id, newWeekly) {
  let { data: drivers, error } = await supabase
    .from('drivers')
    .update({ weekly_availability: newWeekly })
    .eq('user_id', id);

  if (drivers) return drivers;
  if (error) {
    console.error(error);
    return error;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return error;
  }
}

// (helper function, baseline) checks that day input is valid
function checkValidDay(day) {
  for (var i = 0; i < validDays.length; i++) {
    if (day === validDays[i]) {
      return true;
    }
  }
  return false;
}

// (helper function, baseline) checks that shift input is valid
function checkValidShift(shift) {
  for (var i = 0; i < validShifts.length; i++) {
    if (shift === validShifts[i]) {
      return true;
    }
  }
  return false;
}

// (helper function, baseline) checks that value input is valid
function checkValidValue(value) {
  if (value === true || value === false) {
    return true;
  }
  return false;
}

// (helper function, weekly) formats a date to what is used in the weekly schedule JSON
function dateFormat(date) {
  return (
    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  );
}

// (helper function, weekly) check that date falls within the 1 week time window starting from the Monday 2 weeks away from current date
function checkDateRange(date) {
  var start = new Date();
  start.setDate(
    start.getDate() +
      ((weeksAhead * 7 - start.getDay() + 1) % (weeksAhead * 7) ||
        weeksAhead * 7)
  );
  start.setHours(0, 0, 0, 0);

  var end = new Date();
  end.setDate(start.getDate() + 6);
  end.setHours(0, 0, 0, 0);

  date.setHours(0, 0, 0, 0);

  if (date < start || date > end) {
    console.error('date selected falls out of range');
    return false;
  }
  return true;
}

// returns the selected driver's baseline availability as a JSON
export async function findDriverBaselineAvailability(id) {
  let { data: drivers, error } = await supabase
    .from('drivers')
    .select('baseline_availability')
    .eq('user_id', id);

  if (error) {
    console.error(error);
    return error;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return error;
  }

  console.log('find baseline returns ', drivers[0].baseline_availability);
  return drivers[0].baseline_availability;
}

// returns the selected driver's weekly availability as a JSON
export async function findDriverWeeklyAvailability(id) {
  let { data: drivers, error } = await supabase
    .from('drivers')
    .select('weekly_availability')
    .eq('user_id', id);

  if (error) {
    console.error(error);
    return;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return;
  }

  return drivers[0].weekly_availability;
}

// updates the selected driver's baseline for the selected day of the week and shift
export async function updateDriverBaselineAvailability(id, day, shift, value) {
  day = day.toLowerCase();
  shift = shift.toLowerCase();

  if (!checkValidDay(day)) {
    console.error('invalid day');
    return;
  }
  if (!checkValidShift(shift)) {
    console.error('invalid shift');
    return;
  }
  if (!checkValidValue(value)) {
    console.error('invalid value');
    return;
  }

  let { data: drivers, error } = await supabase
    .from('drivers')
    .select('baseline_availability')
    .eq('id', id);

  if (error) {
    console.error(error);
    return;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return;
  }

  // the current baseline
  var newBaseline = drivers[0].baseline_availability;
  for (var i = 0; i < newBaseline.length; i++) {
    if (newBaseline[i].day === day) {
      if (shift === 'morning') {
        newBaseline[i].morning = value;
        break;
      } else if (shift === 'midday') {
        newBaseline[i].midday = value;
        break;
      } else if (shift === 'afternoon') {
        newBaseline[i].afternoon = value;
        break;
      } else if (shift === 'evening') {
        newBaseline[i].evening = value;
        break;
      }
    }
  }
  pushBaselineAvailabilityUpdate(id, newBaseline);
}

// overwrites entire weekly (3 weeks) to be same as basline. used when inserting new driver
export async function setWeeklyToBaseline(id) {
  var baseline = await findDriverBaselineAvailability(id);
  var weekly = [];
  var start = new Date();
  start.setDate(start.getDate() - start.getDay() + 1);
 
  for (var i = 0; i < 21; i++) {
    var obj = new Object();
    obj.date = dateFormat(start);
    obj.morning = baseline[i % 7].morning;
    obj.midday = baseline[i % 7].midday;
    obj.afternoon = baseline[i % 7].afternoon;
    obj.evening = baseline[i % 7].evening;

    weekly.push(obj);
    start.setDate(start.getDate() + 1);
  }

  // pushes update to Supabase
  let { data: drivers, error } = await supabase
    .from('drivers')
    .update({ weekly_availability: weekly })
    .eq('user_id', id)

  if (error) {
    console.error(error);
    return;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return;
  }
}


// updates the selected driver's weekly for the selected date and shift
export async function updateDriverWeeklyAvailability(id, date, shift, value) {
  if (!checkDateRange(date)) {
    return;
  }

  let { data: drivers, error } = await supabase
    .from('drivers')
    .select('weekly_availability')
    .eq('id', id);

  if (error) {
    console.error(error);
    return;
  }
  if (drivers.length < 1) {
    console.error('error: no driver found with matching ID');
    return;
  }

  date = dateFormat(date);

  var newWeekly = drivers[0].weekly_availability;
  for (var i = 0; i < newWeekly.length; i++) {
    if (newWeekly[i].date === date) {
      if (shift === 'morning') {
        newWeekly[i].morning = value;
        break;
      } else if (shift === 'midday') {
        newWeekly[i].midday = value;
        break;
      } else if (shift === 'afternoon') {
        newWeekly[i].afternoon = value;
        break;
      } else if (shift === 'evening') {
        newWeekly[i].evening = value;
        break;
      }
    }
  }
  pushWeeklyAvailabilityUpdate(id, newWeekly);
}
