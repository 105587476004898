import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { supabase } from '../../db/client';
import { IVehicle } from '../../ts/interfaces';

import {
  Box,
  Paper,
  FormControl,
  Input,
  InputLabel,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Modal,
} from '@mui/material';

import { SuccessModal, ErrorModal } from '../../components';

type AdminSingleVehicleProps = {
  vehicles: IVehicle[];
  tableNames: any;
  updateAllVehicles: () => void;
};

const AdminSingleVehicle = ({
  vehicles,
  tableNames,
  updateAllVehicles,
}: AdminSingleVehicleProps) => {
  const [currentVehicle, setCurrentVehicle] = useState<IVehicle>();
  const [licenseError, setLicenseError] = useState('');
  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [removeModal, setRemoveModal] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);

  const vehicleID = useParams().id;
  const navigate = useNavigate();

  useEffect(() => {
    // get vehicle from context based on params
    const thisVehicle = vehicles.find((vehicle: IVehicle) => {
      return vehicle.id === Number(vehicleID);
    });

    if (thisVehicle) setCurrentVehicle(thisVehicle);
    setLoading(false);
  }, []);

  // require certain values for license_required, validate on change
  useEffect(() => {
    validLicense(currentVehicle?.license_required || '');
  }, [currentVehicle]);

  const updateVehicle = async () => {
    if (currentVehicle) {
      const { available, make, model, identifier, license_required, capacity } =
        currentVehicle;

      const { data: updatedVehicle, error } = await supabase
        .from(tableNames.vehicles)
        .update({
          available,
          make,
          model,
          identifier,
          license_required,
          capacity,
        })
        .eq('id', vehicleID);

      console.log('response', updatedVehicle || error);

      if (updatedVehicle) {
        setRemoveModal(false);
        setRemoveSuccess(true);
        updateAllVehicles();
      }
      if (error) setError(true);
    }
  };

  const validLicense = (license: string) => {
    if (license !== 'cdl' && license !== 'standard' && license !== 'chauffer') {
      setLicenseError(
        `License required must be 'cdl', 'chauffer', or 'standard'`
      );
    } else {
      setLicenseError('');
    }
  };

  const removeHandler = async () => {
    const { data, error } = await supabase
      .from(tableNames.vehicles)
      .delete()
      .eq('id', vehicleID);
    
    console.log('response', data || error);

    if (data) {
      setRemoveModal(false);
      setRemoveSuccess(true);
      updateAllVehicles();
    }
    if (error) {
      setRemoveModal(false);
      setError(true);
    }
  };

  return (
    <>
      <div className="admin-app-parent">
        {currentVehicle && !loading && (
          <Box
            sx={{ marginTop: '5em', textAlign: 'center', marginBottom: '3em' }}
          >
            <h1>{currentVehicle.identifier}</h1>
            <p>
              <b>VIN: </b>
              {currentVehicle.vin}
            </p>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'red',
                marginBottom: '1em',
                ':hover': {
                  bgcolor: 'maroon',
                  color: 'white',
                },
              }}
              onClick={() => setRemoveModal(true)}
            >
              Remove
            </Button>
            <Paper
              elevation={5}
              className="vehicle-details"
              sx={{
                borderRadius: '30px',
                textAlign: 'left',
                padding: 5,
                marginBottom: '2em',
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={currentVehicle.available}
                    onChange={() => {
                      let updatedVehicle = {
                        ...currentVehicle,
                        available: !currentVehicle.available,
                      };
                      setCurrentVehicle(updatedVehicle);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Available"
              ></FormControlLabel>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>Make</InputLabel>
                <Input
                  id="make-input"
                  value={currentVehicle.make}
                  onChange={(event) => {
                    setCurrentVehicle({
                      ...currentVehicle,
                      make: event.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>Model</InputLabel>
                <Input
                  id="model-input"
                  value={currentVehicle.model}
                  onChange={(event) => {
                    setCurrentVehicle({
                      ...currentVehicle,
                      model: event.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>Identifier</InputLabel>
                <Input
                  id="identifier-input"
                  value={currentVehicle.identifier}
                  onChange={(event) => {
                    setCurrentVehicle({
                      ...currentVehicle,
                      identifier: event.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>License Required</InputLabel>
                <Input
                  id="license_required-input"
                  value={currentVehicle.license_required}
                  onChange={(event) => {
                    setCurrentVehicle({
                      ...currentVehicle,
                      license_required: event.target.value,
                    });
                  }}
                />
              </FormControl>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="capacity-input"
                  label="Capacity"
                  variant="standard"
                  value={currentVehicle.capacity}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(event) => {
                    let number = Number(event.target.value);
                    if (!isNaN(number) && number < 100) {
                      let updatedVehicle = {
                        ...currentVehicle,
                        capacity: number,
                      };
                      setCurrentVehicle(updatedVehicle);
                    }
                  }}
                />
              </Box>
            </Paper>
            {licenseError && <p style={{ color: 'red' }}>{licenseError}</p>}
            <Button
              variant="contained"
              disabled={licenseError ? true : false}
              onClick={updateVehicle}
            >
              Update
            </Button>
          </Box>
        )}
        {success && (
          <SuccessModal
            open={success}
            onClose={() => setSuccess(false)}
            text="Vehicle Updated"
          />
        )}
        {error && (
          <ErrorModal
            open={error}
            onClose={() => setError(false)}
            text="Something went wrong..."
          />
        )}
        {removeModal && (
          <Modal open={removeModal} onClose={() => setRemoveModal(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 1,
              }}
              textAlign="center"
            >
              <Box
                textAlign="right"
                sx={{ margin: 0 }}
                onClick={() => setRemoveModal(false)}
              >
                <Button
                  sx={{
                    fontSize: '1em',
                    marginTop: '.5em',
                    marginBottom: '.5em',
                  }}
                >
                  X
                </Button>
              </Box>
              <p style={{ marginBottom: '2em' }}>
                Are you sure you want to remove {currentVehicle?.identifier}{' '}
                from your fleet? This cannot be undone.
              </p>
              <Button
                variant="contained"
                onClick={removeHandler}
                sx={{ marginBottom: '1em', marginRight: '1em' }}
              >
                Confirm
              </Button>
            </Box>
          </Modal>
        )}
        {removeSuccess && (
          <Modal open={removeSuccess} onClose={() => setRemoveSuccess(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 1,
              }}
              textAlign="center"
            >
              <p style={{ marginBottom: '2em' }}>
                {currentVehicle?.identifier} has been removed from your fleet.
              </p>
              <Button
                variant="contained"
                onClick={() => navigate('/admin/vehicles')}
                sx={{ marginBottom: '1em', marginRight: '1em' }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        )}
        {!currentVehicle && !loading && (
          <h1 style={{ marginTop: '5em' }}>No vehicle with this ID</h1>
        )}
      </div>
    </>
  );
};

export default AdminSingleVehicle;
