import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paper, Box, FormControlLabel, Checkbox } from '@mui/material';

import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';
import { IExtracurricular } from '../../ts/interfaces';

// each card shows basic info per sport, on click navigates to further details
type SportCardProps = {
  sport: IExtracurricular;
};

const SportCard = ({ sport }: SportCardProps) => {
  const navigate = useNavigate();

  const handleSportCardClick = (sportID: number) => {
    navigate(`/admin/sports/${sport.id}`);
  };

  return (
    <Paper
      elevation={5}
      className="sport-details"
      sx={{
        borderRadius: '10px',
        textAlign: 'left',
        margin: 3,
        cursor: 'pointer',
      }}
      onClick={() => handleSportCardClick(sport.id)}
    >
      <Box sx={{ margin: 0, padding: 0, textAlign: 'center' }}>
        <p style={{ margin: 0 }}>
          <b>{sport.gender}</b>
        </p>
        <h2 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          {sport.title}
        </h2>
        {sport.coaches && (
          <>
            <h3 style={{ marginBottom: 0 }}>
              <b>Staff</b>
            </h3>
            {sport.coaches.map((coach: any) => (
              <p style={{ marginTop: 0, fontSize: '1.5em' }} key={coach.id}>
                {coach.fname} {coach.lname}
              </p>
            ))}
          </>
        )}
      </Box>
    </Paper>
  );
};

type AdminSportsProps = {
  tableNames: any;
};

const AdminSports = ({ tableNames }: AdminSportsProps) => {
  const [allSports, setAllSports] = useState<IExtracurricular[]>();
  const [filteredSports, setFilteredSports] = useState<IExtracurricular[]>();
  const [filterList, setFilterList] = useState({
    boys: true,
    girls: true,
    coed: true,
    varsity: true,
    jv: true,
    freshman: true,
  });

  const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  const getAllSports = async () => {
    let { data: extracurriculars, error } = await supabase
      .from(tableNames.extracurriculars)
      .select(`*`)
      .eq('district_id', user.district_id);

    console.log('response', extracurriculars || error);

    if (error) {
      console.log(error.message);
      setLoading(false);
      return;
    }

    // get all coaches to display coach per sport in sport card
    let { data: coaches, error: error2 } = await supabase
      .from('coaches')
      .select('*')
      .eq('district_id', user.district_id);

    if (extracurriculars && coaches) {
      // for each sport match coach to sport to pass to sport card
      for (let i = 0; i < extracurriculars.length; i++) {
        extracurriculars[i].coaches = [];
        let staffArray = extracurriculars[i].staff;
        if (staffArray) {
          for (let j = 0; j < staffArray.length; j++) {
            let matchingCoach = coaches.find((coach: any) => {
              return coach.id === staffArray[j];
            });
            if (matchingCoach) extracurriculars[i].coaches.push(matchingCoach);
          }
        }
      }

      // sort alphabetically
      extracurriculars.sort((a: any,b: any) => {
        let textA = a.title;
        let textB = b.title;

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setAllSports(extracurriculars);
      setFilteredSports(extracurriculars);
      setLoading(false);
    }
  };

  // check boxes used to filter by boys/girls/coed and by freshman/jv/varsity, defaults to all
  const filterSports = () => {
    if (allSports) {
      let allSportsCopy = [...allSports];

      if (!filterList.boys) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          return team.gender !== 'Boys'
        })
      }
      if (!filterList.girls) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          return team.gender !== 'Girls'
        })
      }
      if (!filterList.coed) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          return team.gender !== 'Coed'
        })
      }

      // sorting by tier is based on inclusion of words in title so not applicable to any situation
      if (!filterList.varsity) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          let title = team.title;

          return !team.title.includes('Varsity') || team.title.includes('Junior');
        })
      }

      if (!filterList.jv) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          return !team.title.includes('Junior');
        })
      }

      if (!filterList.freshman) {
        allSportsCopy = allSportsCopy.filter( (team: any) => {
          return !team.title.includes('Freshman');
        })
      }

      setFilteredSports(allSportsCopy);
    }
  };

  useEffect(() => {
    getAllSports();
  }, []);

  useEffect(() => {
    filterSports();
  }, [filterList]);

  return (
    <div className="admin-app-parent">
      {filteredSports && !loading && (
        <>
          <h1 style={{ marginTop: '3em', textAlign: 'center' }}>Sports</h1>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.girls}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    girls: event.target.checked,
                  });
                }}
              />
            }
            label={'Girls'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.boys}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    boys: event.target.checked,
                  });
                }}
              />
            }
            label={'Boys'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.coed}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    coed: event.target.checked,
                  });
                }}
              />
            }
            label={'Coed'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.varsity}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    varsity: event.target.checked,
                  });
                }}
              />
            }
            label={'Varsity'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.jv}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    jv: event.target.checked,
                  });
                }}
              />
            }
            label={'JV'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterList.freshman}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterList({
                    ...filterList,
                    freshman: event.target.checked,
                  });
                }}
              />
            }
            label={'Freshman'}
          />
          {filteredSports.map((sport: IExtracurricular) => {
            return <SportCard sport={sport} key={sport.id} />;
          })}
        </>
      )}
    </div>
  );
};

export default AdminSports;
