export const updateDriverByEmail = async (route: any, driver: any) => {

  // basic message constructed
  const msg = {
    to: driver.email,
    from: 'admin@fleet-lab.com',
    subject: 'Trip Updated',
    text: `Your schedule for ${route.date} has been updated.`,
  };

  // fetch request to simple back end application set up to take this message and deliver to driver's email
  // this needs to either be expanded on or removed in favor of future SMS service
  const response = await fetch(
    'https://sni3yb7m21.execute-api.us-east-1.amazonaws.com/dev/items',
    {
      method: 'POST',
      body: JSON.stringify(msg),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  let data = await response.json();
  return data;
}