import { useState, useEffect } from 'react';
import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import { Paper, Box, TextField, Button } from '@mui/material';
import { SuccessModal, ErrorModal, TextInput } from '../../components'
import { IDistrict } from '../../ts/interfaces';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getExactStart, dateObjToUTCTime } from '../../utils/dateTimeHelpers';
import { format } from 'date-fns';

type AdminDistrictProps = {
  tableNames: any;
};

// this page displays and updates district information - most of this needs to be rewritten to set most of this data per school of each district
const AdminDistrict = ({ tableNames }: AdminDistrictProps) => {
  const { user } = useAuth();
  const [districtInfo, setDistrictInfo] = useState<IDistrict>();
  const [startTime, setStartTime] = useState<Date>();
  const [endTime, setEndTime] = useState<Date>();

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const getExactTime = (time: string) => {
    let timeArray = time.split(':');
    let newTimeString = `${timeArray[0]}:${timeArray[1]}`;

    let today = new Date(Date.now());
    let formattedDate = format(today, 'MMMM d, yyyy');

    let dateStr = `${formattedDate} ${newTimeString} UTC`;
    let dateObj = new Date(dateStr);
    return dateObj;
  };

  const getDistrictInfo = async () => {
    let { data: district, error } = await supabase
      .from(tableNames.districts)
      .select('*')
      .eq('id', user.district_id)
      .single();

    console.log('response', district || error);
   
    if (district) {
      setStartTime(getExactTime(district.start_time));
      setEndTime(getExactTime(district.end_time));
      setDistrictInfo(district);
    }
    if (error) {
      console.log(error.message);
    }
  };

  const updateDistrict = async () => {
    console.log(districtInfo);

    if (districtInfo && startTime && endTime) {
      const { am_buffer, pm_buffer, depot, bus_precheck, bus_postcheck, van_precheck, van_postcheck, minivan_precheck, minivan_postcheck } = districtInfo;
      const { data, error } = await supabase
        .from(tableNames.districts)
        .update({
          am_buffer,
          pm_buffer,
          bus_precheck,
          bus_postcheck,
          van_precheck,
          van_postcheck,
          minivan_precheck,
          minivan_postcheck,
          depot,
          start_time: dateObjToUTCTime(startTime),
          end_time: dateObjToUTCTime(endTime),
        })
        .eq('id', user.district_id);

        console.log('response', data || error);

        if (data) {
          setSuccess('District updated');
        }
        if (error) {
          setError('Database Error: ' + error.message);
        }
    }

  }

  useEffect(() => {
    getDistrictInfo();
  }, []);

  return (
    <div style={{ marginTop: '5em' }}>
      {districtInfo && (
        <Paper
          elevation={5}
          className="district-details"
          sx={{
            borderRadius: '30px',
            textAlign: 'left',
            padding: 5,
            marginBottom: '2em',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>{districtInfo.name}</h1>
          <TextInput label='Address' value={districtInfo.depot} changeHandler={(event) => setDistrictInfo({...districtInfo, depot: event.target.value})}/>
          <Paper
            elevation={2}
            className="am-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              padding: 5,
              marginBottom: '2em',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Morning</h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                  display: 'flex',
                  alignItems: 'flex-end',
                  width: '300px',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="am-buffer"
                  label="AM Drop Off Window"
                  variant="standard"
                  value={districtInfo.am_buffer}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 2,
                  }}
                  onChange={(event) => {
                    let value = Number(event.target.value);
                    if (!isNaN(value)) {
                      setDistrictInfo({
                        ...districtInfo,
                        am_buffer: value,
                      });
                    }
                  }}
                />
                <p>minutes</p>
              </Box>
              <Box sx={{ marginTop: '2em' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newValue) => {
                      if (newValue) {
                        setStartTime(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </div>
          </Paper>
          <Paper
            elevation={2}
            className="pm-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              padding: 5,
              marginBottom: '2em',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Afternoon</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                  display: 'flex',
                  alignItems: 'flex-end',
                  width: '300px',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="pm-buffer"
                  label="PM Pick Up Window"
                  variant="standard"
                  value={districtInfo.pm_buffer}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 2,
                  }}
                  onChange={(event) => {
                    let value = Number(event.target.value);
                    if (!isNaN(value)) {
                      setDistrictInfo({
                        ...districtInfo,
                        pm_buffer: value,
                      });
                    }
                  }}
                />
                <p>minutes</p>
              </Box>
              <Box sx={{ marginTop: '2em' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(newValue) => {
                      if (newValue) {
                        setEndTime(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </div>
          </Paper>

          <Paper
            elevation={2}
            className="precheck-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              padding: 5,
              marginBottom: '2em',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Precheck Times (minutes)</h2>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                display: 'flex',
                justifyContent: 'space-around',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="bus-precheck"
                label="Bus"
                variant="standard"
                value={districtInfo.bus_precheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      bus_precheck: value,
                    });
                  }
                }}
              />
              <TextField
                id="van-precheck"
                label="Van"
                variant="standard"
                value={districtInfo.van_precheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      van_precheck: value,
                    });
                  }
                }}
              />
              <TextField
                id="minivan-precheck"
                label="Minivan"
                variant="standard"
                value={districtInfo.minivan_precheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      minivan_precheck: value,
                    });
                  }
                }}
              />
            </Box>
          </Paper>
          <Paper
            elevation={2}
            className="postcheck-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
              padding: 5,
              marginBottom: '2em',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Postcheck Times (minutes)</h2>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                display: 'flex',
                justifyContent: 'space-around',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="bus-postcheck"
                label="Bus"
                variant="standard"
                value={districtInfo.bus_postcheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      bus_postcheck: value,
                    });
                  }
                }}
              />
              <TextField
                id="van-postcheck"
                label="Van"
                variant="standard"
                value={districtInfo.van_postcheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      van_postcheck: value,
                    });
                  }
                }}
              />
              <TextField
                id="minivan-postcheck"
                label="Minivan"
                variant="standard"
                value={districtInfo.minivan_postcheck}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 2,
                }}
                sx={{ maxWidth: '100px' }}
                onChange={(event) => {
                  let value = Number(event.target.value);
                  if (!isNaN(value)) {
                    setDistrictInfo({
                      ...districtInfo,
                      minivan_postcheck: value,
                    });
                  }
                }}
              />
            </Box>
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={updateDistrict}>
              Update District
            </Button>
          </Box>
        </Paper>
      )}
      {success && (
        <SuccessModal open={success ? true : false} onClose={() => setSuccess('')} text={success}/>
      )}
      {error && (
        <ErrorModal open={error ? true : false} onClose={() => setError('')} text={error}/>
      )}
    </div>
  );
};

export default AdminDistrict;
