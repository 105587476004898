import { useState, useEffect } from 'react';
import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  Paper,
  Box,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Input,
  Button,
  Typography,
} from '@mui/material';

import { ErrorModal, SuccessModal } from '../../components';
import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input';
import { TextInput } from '../../components';

import {
  capitalize,
  phoneStringToNumber,
} from '../../utils/textFormattingHelpers';

type AdminNewStudentProps = {
  tableNames: any;
};

// page to add a new student to database

const AdminNewStudent = ({ tableNames }: AdminNewStudentProps) => {
  const { user } = useAuth();
  const [newStudent, setNewStudent] = useState<any>({
    // baseline_addresses is json field noting address student is picked up in am and pm for each day
    baseline_addresses: [
      {
        am: null,
        pm: null,
        day: 'monday',
      },
      {
        am: null,
        pm: null,
        day: 'tuesday',
      },
      {
        am: null,
        pm: null,
        day: 'wednesday',
      },
      {
        am: null,
        pm: null,
        day: 'thursday',
      },
      {
        am: null,
        pm: null,
        day: 'friday',
      },
      {
        am: null,
        pm: null,
        day: 'saturday',
      },
      {
        am: null,
        pm: null,
        day: 'sunday',
      },
    ],
    contact1_id: null,
    contact2_id: null,
    district_id: null,
    email: null,
    fname: '',
    grade: null,
    lname: '',
    resident_address: '',
    am_transportation: false,
    pm_transportation: false,
    transportation_address1: '',
    transportation_address2: '',
  });

  const [contact1, setContact1] = useState<any>(null);
  const [contact2, setContact2] = useState<any>(null);
  const [inputError, setInputError] = useState('');
  const [databaseError, setDatabaseError] = useState('');
  const [success, setSuccess] = useState(false);

  const addContact1Handler = () => {
    setContact1({
      fname: '',
      lname: '',
      email: '',
      phone: '+1 ',
      relation: '',
    });
  };

  const addContact2Handler = () => {
    setContact2({
      fname: '',
      lname: '',
      email: '',
      phone: '+1 ',
      relation: '',
    });
  };

  const addStudentHandler = async () => {
    // require this data
    if (
      !newStudent.fname ||
      !newStudent.lname ||
      !newStudent.resident_address ||
      !newStudent.grade
    ) {
      setInputError('Name, resident address, and grade are required fields');
      return;
    }

    // if emergency contacts were added, check for valid info
    if (
      (contact1 && (!contact1.fname || !contact1.lname)) ||
      (contact2 && (!contact2.fname || !contact2.lname))
    ) {
      setInputError(
        'Enter at least a first and last name and a valid phone number for emergency contacts'
      );
      return;
    }

    // validate phone number entered
    if (
      (contact1 && !isValidPhoneNumber(contact1.phone)) ||
      (contact2 && !isValidPhoneNumber(contact2.phone))
    ) {
      setInputError('Check for valid phone number');
      return;
    }

    let contact1ID = null;
    let contact2ID = null;

    // if contacts, create and set ids from returned row
    if (contact1) {
      let { fname, lname, email, phone, relation } = contact1;
      let insertStatement = [
        { fname, lname, email, relation, phone: phoneStringToNumber(phone) },
      ];

      if (contact2) {
          let { fname, lname, email, phone, relation } = contact2;
        insertStatement.push({ fname, lname, email, relation, phone: phoneStringToNumber(phone)});
      }
      const { data, error } = await supabase
        .from(tableNames.contacts)
        .insert(insertStatement);

      console.log('response from create new contact 1', data || error);
      if (data) {
        let newContact1ID = data[0].id;
        console.log('new id created for contact 1 from insert', newContact1ID);
        contact1ID = newContact1ID;

        if (data[1]) {
          let newContact2ID = data[1].id;
          console.log('new id created for contact 2 from insert', newContact2ID);
          contact2ID = newContact2ID;
        }
      }
      if (error) {
        setDatabaseError('New emergency contact could not be created');
        return;
      }
    }

    // create new row in students table, contacts will be null or have been set from creation
    const { data, error } = await supabase
      .from(tableNames.students)
      .insert([
        {
          baseline_addresses: newStudent.baseline_addresses,
          contact1_id: contact1ID,
          contact2_id: contact2ID,
          district_id: user.district_id,
          email: newStudent.email,
          fname: newStudent.fname,
          lname: newStudent.lname,
          grade: newStudent.grade,
          resident_address: newStudent.resident_address,
          am_transportation: newStudent.am_transportation,
          pm_transportation: newStudent.pm_transportation,
          transportation_address1: newStudent.transportation_address1,
          transportation_address2: newStudent.transportation_address2,
        },
      ]);

      console.log('response from create new student', data || error);

      if (data) {
        setSuccess(true);
        // reset to empty student
        setNewStudent({
          baseline_addresses: [
            {
              am: null,
              pm: null,
              day: 'monday',
            },
            {
              am: null,
              pm: null,
              day: 'tuesday',
            },
            {
              am: null,
              pm: null,
              day: 'wednesday',
            },
            {
              am: null,
              pm: null,
              day: 'thursday',
            },
            {
              am: null,
              pm: null,
              day: 'friday',
            },
            {
              am: null,
              pm: null,
              day: 'saturday',
            },
            {
              am: null,
              pm: null,
              day: 'sunday',
            },
          ],
          contact1_id: null,
          contact2_id: null,
          district_id: null,
          email: '',
          fname: '',
          grade: '',
          lname: '',
          resident_address: '',
          am_transportation: false,
          pm_transportation: false,
          transportation_address1: '',
          transportation_address2: '',
        });
        setContact1(null);
        setContact2(null);
      }

      if (error) {
        setDatabaseError('New student could not be created');
      }
  };

  // remove error text when values change
  useEffect(() => {
    setInputError('');
  }, [newStudent, contact1, contact2]);

  return (
    <div className="admin-app-parent" style={{ marginTop: '5em' }}>
      <Paper
        elevation={5}
        className="new-student-form"
        sx={{
          borderRadius: '30px',
          padding: '1em 0 1em',
          margin: '1em',
          textAlign: 'center',
        }}
      >
        <h1>New Student Form</h1>
        <FormControlLabel
          control={
            <Switch
              checked={newStudent.am_transportation}
              onChange={() => {
                setNewStudent({
                  ...newStudent,
                  am_transportation: !newStudent.am_transportation,
                });
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Rides AM"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <Switch
              checked={newStudent.pm_transportation}
              onChange={() => {
                setNewStudent({
                  ...newStudent,
                  pm_transportation: !newStudent.pm_transportation,
                });
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Rides PM"
        ></FormControlLabel>

        <Box sx={{ textAlign: 'left', marginInline: '2em' }}>
          <TextInput
            value={newStudent.fname}
            label="First Name"
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                fname: event.target.value,
              });
            }}
          />
          <TextInput
            value={newStudent.lname}
            label="Last Name"
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                lname: event.target.value,
              });
            }}
          />
          <TextInput
            value={newStudent.email}
            label="Email"
            changeHandler={(event) =>
              setNewStudent({ ...newStudent, email: event.target.value })
            }
          />
          <TextInput
            value={newStudent.resident_address}
            label="Resident Address"
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                resident_address: event.target.value,
              });
            }}
          />
          <TextInput
            value={newStudent.grade}
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                grade: event.target.value,
              });
            }}
            label="Grade"
          />
          <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
            Emergency Contacts
          </h2>
          {!contact1 && !contact2 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" onClick={addContact1Handler}>
                Add Emergency Contact
              </Button>
            </Box>
          )}
          {contact1 && (
            <Box>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>Contact 1</h3>
                {!contact2 && (
                  <Button onClick={() => setContact1(null)} sx={{color: 'red'}}>Remove</Button>
                )}
              </div>
              <TextInput
                value={contact1.fname}
                changeHandler={(event) => {
                  setContact1({
                    ...contact1,
                    fname: event.target.value,
                  });
                }}
                label="First Name"
              />
              <TextInput
                value={contact1.lname}
                changeHandler={(event) => {
                  setContact1({
                    ...contact1,
                    lname: event.target.value,
                  });
                }}
                label="Last Name"
              />
              <TextInput
                value={contact1.email}
                changeHandler={(event) => {
                  setContact1({
                    ...contact1,
                    email: event.target.value,
                  });
                }}
                label="Email"
              />
              <TextInput
                value={contact1.relation}
                changeHandler={(event) => {
                  setContact1({
                    ...contact1,
                    relation: event.target.value,
                  });
                }}
                label="relation"
              />

              <div style={{ margin: '1em auto' }}>
                <Typography color="primary" sx={{ fontSize: '1em' }}>
                  Phone Number (w/ area code)
                </Typography>
                <MuiTelInput
                  value={contact1.phone}
                  onChange={(value) => {
                    setContact1({
                      ...contact1,
                      phone: value,
                    });
                  }}
                  onlyCountries={['US']}
                  disableDropdown={true}
                />
              </div>

              {!contact2 && (
                <Box sx={{ display: 'flex', justifyContet: 'center' }}>
                  <Button variant="contained" onClick={addContact2Handler}>
                    Add Contact 2
                  </Button>
                </Box>
              )}
              {contact2 && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Contact 2</h3>
                    <Button onClick={() => setContact2(null)} sx={{color: 'red'}}>Remove</Button>
                  </div>
                  <TextInput
                    value={contact2.fname}
                    changeHandler={(event) => {
                      setContact2({
                        ...contact2,
                        fname: event.target.value,
                      });
                    }}
                    label="First Name"
                  />
                  <TextInput
                    value={contact2.lname}
                    changeHandler={(event) => {
                      setContact2({
                        ...contact2,
                        lname: event.target.value,
                      });
                    }}
                    label="Last Name"
                  />
                  <TextInput
                    value={contact2.email}
                    changeHandler={(event) => {
                      setContact2({
                        ...contact2,
                        email: event.target.value,
                      });
                    }}
                    label="Email"
                  />
                  <TextInput
                    value={contact2.relation}
                    changeHandler={(event) => {
                      setContact2({
                        ...contact2,
                        relation: event.target.value,
                      });
                    }}
                    label="relation"
                  />

                  <div style={{ margin: '1em auto' }}>
                    <Typography color="primary" sx={{ fontSize: '1em' }}>
                      Phone Number (w/ area code)
                    </Typography>
                    <MuiTelInput
                      value={contact2.phone}
                      onChange={(value) => {
                        setContact2({
                          ...contact2,
                          phone: value,
                        });
                      }}
                      onlyCountries={['US']}
                      disableDropdown={true}
                    />
                  </div>
                </>
              )}
            </Box>
          )}
          <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>
            Pickup Locations
          </h2>
          {newStudent.baseline_addresses.map((day: any, index: number) => {
            return (
              <>
                <p>
                  <b>{capitalize(day.day)}</b>
                </p>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>AM Address</InputLabel>
                  <Input
                    id={`baseline-${day.day}-am-input`}
                    value={newStudent.baseline_addresses[index].am}
                    onChange={(event) => {
                      let value: string | null = event.target.value;
                      if (value === '') value = null;
                      let newBaseline = [...newStudent.baseline_addresses];
                      newBaseline[index].am = value;
                      setNewStudent({
                        ...newStudent,
                        baseline_addresses: newBaseline,
                      });
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ margin: '1em 0 1em' }}
                >
                  <InputLabel>PM Address</InputLabel>
                  <Input
                    id={`baseline-${day.day}-pm-input`}
                    value={newStudent.baseline_addresses[index].pm}
                    onChange={(event) => {
                      let value: string | null = event.target.value;
                      if (value === '') value = null;
                      let newBaseline = [...newStudent.baseline_addresses];
                      newBaseline[index].pm = value;
                      setNewStudent({
                        ...newStudent,
                        baseline_addresses: newBaseline,
                      });
                    }}
                  />
                </FormControl>
              </>
            );
          })}
          <TextInput
            value={newStudent.transportation_address1}
            label="Transporation Address 1"
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                transportation_address1: event.target.value,
              });
            }}
          />
          <TextInput
            value={newStudent.transportation_address2}
            label="Transporation Address 2"
            changeHandler={(event) => {
              setNewStudent({
                ...newStudent,
                transportation_address2: event.target.value,
              });
            }}
          />
        </Box>
        {inputError && <p style={{ color: 'red' }}>{inputError}</p>}
        <Button variant="contained" onClick={addStudentHandler}>
          Add Student
        </Button>
      </Paper>
      {databaseError && (
        <ErrorModal
          open={databaseError ? true : false}
          onClose={() => setDatabaseError('')}
          text={databaseError}
        />
      )}
      {success && (
        <SuccessModal open={success} onClose={() => setSuccess(false)} text='New student created successfully' />
      )}
    </div>
  );
};

export default AdminNewStudent;
