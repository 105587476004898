import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

import { Box, TextField, Button } from '@mui/material';

export default function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState<string>('');

  const { logIn } = useAuth();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email && password) {
      // check input
      // send to login method from context, if error - error will display, if success session will be updated and user will be redirected from login method
      let { user, session, error } = await logIn({ email, password });
      if (error) {
        setErrorText(error.message);
        return;
      }
    } else {
      setErrorText('Enter your email and password');
    }
  };

  return (
    <div style={{ marginTop: '5vh' }} className='driver-app-parent'>
      <img
        src="/logo512.png"
        alt=""
        style={{ width: '100px', marginTop: '5vh' }}
      ></img>
      <h1>Log In</h1>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleLogin}
      >
        <TextField
          id="email"
          label="Email Address"
          name="email"
          variant="outlined"
          color="primary"
          value={email}
          autoFocus
          onChange={(event) => {
            setEmail(event.target.value.trim());
            setErrorText('');
          }}
        />
        <br />
        <TextField
          id="password"
          name="password"
          label="Password"
          variant="outlined"
          color="primary"
          value={password}
          type="password"
          onChange={(event) => {
            setPassword(event.target.value.trim());
            setErrorText('');
          }}
          sx={{
            margin: '1em 0',
          }}
        />
        <br />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ margin: '0 auto' }}
        >
          Log In
        </Button>
      </Box>
      <p style={{ fontSize: '1.5em' }}>
        Or{' '}
        <a
          href="/signup"
          style={{
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          Sign Up
        </a>
      </p>
      <p>
        <Link to="/password-recovery" style={{color: 'blue', textDecoration: 'underline'}}>Forgot your password?</Link>
      </p>
      {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
    </div>
  );
}
