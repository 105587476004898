import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

import { useAuth } from '../../contexts/Auth';

import { Header } from '../../components/Header';

import { Paper, Grid, CircularProgress } from '@mui/material';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';

import { format, add, startOfWeek, endOfWeek } from 'date-fns';
import { getExactStart, formatTime } from '../../utils/dateTimeHelpers';

import { IRoute } from '../../ts/interfaces'

import {
  getRouteName,
} from '../../utils/routeDataHandlers';

type RouteCardProps = {
  route: IRoute;
  index: number;
};

const getDaysToShow = () => {
  // week starts on Monday, show next 3 weeks
  let firstDay = startOfWeek(Date.now(), { weekStartsOn: 1 });
  let fullScheduleArray = [firstDay];
  for (let i = 1; i < 21; i++) {
    let newDate = add(fullScheduleArray[0], {
      days: i,
    });
    fullScheduleArray.push(newDate);
  }

  let indexOfToday = fullScheduleArray.findIndex(
    (date) => date.getDate() === new Date(Date.now()).getDate()
  );
  let daysToShow = fullScheduleArray.slice(indexOfToday);

  return daysToShow;
};

const daysToShow = getDaysToShow();

// card for each route, on click navigate to single route details
const RouteCard = ({ route, index }: RouteCardProps) => {
  const { id, start_time, date } = route;
  const navigate = useNavigate();

  const routeClickHandler = (e: React.MouseEvent) => {
    navigate(`/route/${id}`);
  };

  return (
    <Paper
      elevation={2}
      className="route-card"
      sx={{
        borderRadius: '30px',
        border: route.in_progress ? '1px solid red' : '',
      }}
      onClick={routeClickHandler}
    >
      <Grid container>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#004812',
              color: '#b29430',
              height: 50,
              width: 50,
              marginLeft: '1em',
              fontSize: '1.6em',
              outline: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={{ alignSelf: 'center', padding: 5 }}>
              {formatTime(getExactStart(date, start_time)).split(' ')[1]}
            </span>
          </div>
        </Grid>
        <Grid item xs={9} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <h2>{getRouteName(route)}</h2>
              {route.vehicles?.identifier && (
                <h3 style={{ fontSize: '1em' }}>{route.vehicles.identifier}</h3>
              )}
              {!route.in_progress && (
                <p>Check in - {formatTime(getExactStart(date, start_time))}</p>
              )}
              {route.in_progress && (
                <p style={{ color: 'red', fontWeight: 'bold' }}>IN PROGRESS</p>
              )}
              {route.type === 'event' && (
                <p style={{ color: 'green', fontWeight: 'bold' }}>EVENT</p>
              )}
              {route.type === 'field_trip' && (
                <p style={{ color: 'green', fontWeight: 'bold' }}>FIELD TRIP</p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Home = () => {
  const [routes, setRoutes] = useState<IRoute[]>();
  const [indexOfDaySelected, setIndexOfDaySelected] = useState(0);
  const [currentDayRoutes, setCurrentDayRoutes] = useState<IRoute[]>();
  const [pleaseRegister, setPleaseRegister] = useState(false);
  const [loading, setLoading] = useState(true);

  // user from context
  const { user } = useAuth();

  useEffect(() => {
      if (user.routes) {
        console.log(user);
        // filter for routes with only this drivers id in the event that logged in user is admin and their auth context pulled all routes
        let userRoutes = user.routes.filter( (route:any) => {
          return route.driver_id === user.id
        })
        
        console.log(userRoutes)

        setRoutes(userRoutes);
        setLoading(false);
      }
      // if user is not registered display link to register
      if (user.newUser) {
        setPleaseRegister(true);
        setLoading(false);
      }
  }, [user]);

  useEffect(() => {
    if (routes) {
      let currentDay = daysToShow[indexOfDaySelected];
      let formatCurrentDay = format(currentDay, 'iiii, MMMM dd');
      // filter routes to only routes with date field that matches current date
      let todaysRoutes = routes.filter((route: any) => {
        let timeArray = route.start_time.split(':');
        let newTimeString = `${timeArray[0]}:${timeArray[1]}`;
        let dateArray = route.date.split('-').map((date: string) => {
          return Number(date);
        });
        let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        let formattedDate = format(newDate, 'MMMM d, yyyy');
        let dateStr = `${formattedDate} ${newTimeString} UTC`;
        let dateOfRoute = new Date(dateStr);
        let formatDateOfRoute = format(dateOfRoute, 'iiii, MMMM dd');
        return formatDateOfRoute === formatCurrentDay;
      });
      
      // sort by start time
      todaysRoutes.sort((a: any, b: any) => {
        let aSplit = a.start_time.split(':');
        let bSplit = b.start_time.split(':');

        let aMinutes = Number(aSplit[0] * 60) + Number(aSplit[1]);
        let bMinutes = Number(bSplit[0] * 60) + Number(bSplit[1]);

        return aMinutes - bMinutes;
      });
      setCurrentDayRoutes(todaysRoutes);
    }
  }, [routes, indexOfDaySelected]);

  // move back one day, ahead one day
  const backOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected - 1;
    if (newIndex >= 0) {
      setIndexOfDaySelected(newIndex);
    }
  };

  const aheadOneDay = (event: React.MouseEvent) => {
    let newIndex = indexOfDaySelected + 1;
    if (newIndex < daysToShow.length) {
      setIndexOfDaySelected(newIndex);
    }
  };

  // method to jump back to current day
  const fabClicked = () => {
    setIndexOfDaySelected(0);
  };

  // show current week above current day
  const weekToShow = (date: Date) => {
    let startDay = format(startOfWeek(date, { weekStartsOn: 1 }), 'LLL d');
    let endDay = format(endOfWeek(date, { weekStartsOn: 1 }), 'LLL d');
    return `${startDay} to ${endDay}`;
  };

  return (
    <div className='driver-app-parent'>
      {loading ? (
        <CircularProgress size={100} sx={{ marginTop: '30vh' }} />
      ) : (
        <div className="routes-page">
          <Header />
          <div className="routes-box">
            { currentDayRoutes &&
              currentDayRoutes.length > 0 &&
              currentDayRoutes.map((route: IRoute, index: number) => {
                return (
                  <RouteCard route={route} index={index + 1} key={route.id} />
                );
              })}
            {currentDayRoutes && currentDayRoutes.length === 0 && (
              <p style={{ marginTop: '3em' }}>No routes scheduled</p>
            )}
            {pleaseRegister && (
              <p style={{ fontSize: '2.5em', marginTop: '2em' }}>
                Please{' '}
                <a
                  href="/register"
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  Register
                </a>
              </p>
            )}
          </div>

          {indexOfDaySelected !== 0 && (
            <button className="fab-button" onClick={fabClicked}>
              Jump to Today
            </button>
          )}
          <footer className="footer">
            <div>
              {indexOfDaySelected !== 0 && (
                <ArrowBackIosNew onClick={backOneDay} />
              )}
            </div>
            <p style={{ fontSize: '1.2em', lineHeight: 1 }}>
              <span style={{ fontSize: '.6em', color: 'gray' }}>
                {weekToShow(daysToShow[indexOfDaySelected])}
              </span>
              <br />
              {format(daysToShow[indexOfDaySelected], 'iiii, MMMM dd')}
            </p>
            <div>
              {indexOfDaySelected !== daysToShow.length - 1 && (
                <ArrowForwardIos onClick={aheadOneDay} />
              )}
            </div>
          </footer>
        </div>
      )}
    </div>
  );
};

export default Home;
