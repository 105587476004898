import { Modal, Box, Button } from '@mui/material';

// reusable error modal

type ErrorModalProps = {
  open: boolean;
  onClose: () => void;
  text: string;
};

const ErrorModal = ({ open, onClose, text }: ErrorModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
        }}
        textAlign="center"
      >
        <p>{text}</p>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ marginBottom: '1em' }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
