import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Box, TextField, Button } from '@mui/material';

import { supabase } from '../../db/client';

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [hash, setHash] = useState('');
  const [errorText, setErrorText] = useState('');
  const [success, setSuccess] = useState(false);

  // on page load grab hash from url, url is from email sent to user from recovery
  useEffect(() => {
    setHash(window.location.hash);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // if the user doesn't have accesstoken
      if (!hash) {
        setErrorText('No access token');
      } else if (hash) {
        // pull accessToken info from hash
        const hashArr = hash
          .substring(1)
          .split('&')
          .map((param) => param.split('='));

        let type;
        let accessToken: any;
        for (const [key, value] of hashArr) {
          if (key === 'type') {
            type = value;
          } else if (key === 'access_token') {
            accessToken = value;
          }
        }

        if (
          type !== 'recovery' ||
          !accessToken ||
          typeof accessToken === 'object'
        ) {
          setErrorText('Invalid access token type');
        }

        // send accessToken to update user with new password
        const { error } = await supabase.auth.api.updateUser(accessToken, {
          password: password,
        });

        // if error, password has not been updated, if no error new password has been set for user
        if (error) {
          console.log(error.message);
          setErrorText(error.message);
        } else if (!error) {
          setSuccess(true);
        }
      }
    } catch (error) {
      console.log('catch error', error);
    }
  };

  return (
    <div style={{ marginTop: '5vh' }} className='driver-app-parent'>
      <img
        src="/logo512.png"
        alt=""
        style={{ width: '100px', marginTop: '5vh' }}
      ></img>
      {success ? (
        <>
        <p style={{ marginTop: '5em' }}>Your password has been reset.</p>
        <Link to='/' style={{color: 'blue', textDecoration: 'underline', fontSize: '2em'}}>Home</Link>
        </>
      ) : (
        <>
          <h1 style={{ marginTop: '2em' }}>Enter a new password</h1>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '25ch' },
              marginTop: '2em',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              color="primary"
              value={password}
              type="password"
              onChange={(event) => {
                setPassword(event.target.value.trim());
                setErrorText('');
              }}
            />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ margin: '3em auto' }}
            >
              Submit
            </Button>
          </Box>

          {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
        </>
      )}
    </div>
  );
}
