import { Modal } from '@mui/material'

// reusable modal for driver checklists

type FinishCheckModalProps = {
  open: boolean;
  setOpen: () => void;
  children: JSX.Element;
};

export const FinishCheckModal = ({ open, setOpen, children }: FinishCheckModalProps) => {
  return (
    <Modal open={open} onClose={setOpen}>
      {children}
    </Modal>
  );
};