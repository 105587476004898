import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Avatar, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../../contexts/Auth';
import { supabase } from '../../db/client';

// Header component for driver pages

export const Header = () => {
  const { user, logOut } = useAuth();

  // state of user menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [logoURL, setLogoURL] = useState<string>();

  // methods to open and close menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  // method to log out current user, from authContext
  const handleLogOut = async () => {
    await logOut();
  };

  // fetches logo for user's district from supabase bucket - url structure set but mostly unused
  const getLogo = async () => {
    let districtID = user.district_id

    if (districtID) {
      const { data, error } = await supabase.storage
        .from('districts')
        .getPublicUrl(`district${districtID}/logo.png`);
  
        if (data) {
          setLogoURL(data.publicURL);
        }
        if (error) {
          console.log(error.message)
        }
    }
  }

  useEffect( () => {
    if (user) {
      getLogo();
    }
  }, [user])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          id="avatar-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ display: 'block', marginLeft: '1em' }}
        >
          <Avatar
            id="avatar"
            sx={{ color: '#b29430', backgroundColor: '#004812' }}
          />
        </Button>
        {logoURL && (
          <img
            alt="District Logo"
            src={logoURL}
            style={{ height: 50, marginRight: '3em' }}
          ></img>
        )}
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ margin: 0 }}
      >
        <NavLink to={'/schedule/baseline'}>
          <MenuItem
            onClick={handleClose}
            sx={{
              backgroundColor: '#004812',
              color: '#b29430',
              textShadow: '0px 0px 4px #000',
            }}
          >
            Availability
          </MenuItem>
        </NavLink>
        <NavLink to={'/schedule/weekly'}>
          <MenuItem
            onClick={handleClose}
            sx={{
              backgroundColor: '#b29430',
              color: '#004812',
              textShadow: '0px 0px 2px #FFF',
            }}
          >
            Weekly Schedule
          </MenuItem>
        </NavLink>
        <NavLink to={'/'}>
          <MenuItem
            onClick={handleClose}
            sx={{
              backgroundColor: '#004812',
              color: '#b29430',
              textShadow: '0px 0px 4px #000',
            }}
          >
            Routes
          </MenuItem>
        </NavLink>
        {user.role === 'admin' && (
          <NavLink to={'/admin/home'}>
            <MenuItem onClick={handleClose}>Admin Dashboard</MenuItem>
          </NavLink>
        )}
        <span onClick={handleLogOut}>
          <MenuItem onClick={handleClose}>Log Out</MenuItem>
        </span>
      </Menu>
    </>
  );
};
