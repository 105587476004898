import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

export function PrivateRoute() {
  const { user } = useAuth();
  // if user is authenticated, continue - if not, navigate to login page
  return (
    user ? <Outlet /> : <Navigate to='/login' />
  );
}