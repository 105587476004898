import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header, StaticMap } from '../../components';
import { Button, Paper, Box } from '@mui/material';
import './SingleRoute.css';

import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  differenceInMinutes,
  intervalToDuration,
  formatDuration,
} from 'date-fns';

import {
  getExactStart,
  formatTime,
  formatDate,
} from '../../utils/dateTimeHelpers';

import {
  createWaypointsArray,
  getStudentInfoFromRoute,
  getMapMarkerInfo,
  getRouteName,
  getEventMapMarker,
} from '../../utils/routeDataHandlers';

import {
  generateUrlForGoogleMaps,
  generateUrlForGoogleMapsDriverEvent,
} from '../../utils/generateUrlForGoogleMaps';

import { IRoute, IStudent, IStopInfo } from '../../ts/interfaces';

const SingleRoute = () => {
  const [position, setPosition] = useState<number[]>([]);
  const [destination, setDestination] = useState<number[]>([]);

  const [currentRoute, setCurrentRoute] = useState<IRoute>();
  const [routeLoaded, setRouteLoaded] = useState(false);
  const [studentInfoArray, setStudentInfoArray] = useState<IStudent[]>();
  const [vehicleInfo, setVehicleInfo] = useState<any>();
  const [eventInfo, setEventInfo] = useState<any>();
  const [fieldTripInfo, setFieldTripInfo] = useState<any>();

  const [waypointsArray, setWaypointsArray] = useState<number[][]>();
  const [markerArray, setMarkerArray] = useState<any[]>([]);
  const [exactStart, setExactStart] = useState<Date>();

  const [minutesAway, setMinutesAway] = useState<number>();
  const [routeName, setRouteName] = useState<string>();

  const [mapsUrl, setMapsUrl] = useState<string>();

  const id = useParams().id;
  const { user, watchUserLocation } = useAuth();

  const getRoute = async () => {
    let { data: routes, error } = await supabase
      .from('routes')
      .select()
      .eq('id', id);
    setCurrentRoute(routes?.[0]);

    // if standard route, get student info to populate map
    if (routes?.[0].type === 'standard') {
      let studentInfo = await getStudentInfoFromRoute(routes?.[0], 'students');
      if (studentInfo) setStudentInfoArray(studentInfo);
    }

    // if event or field trip get info and create markers for map
    if (routes?.[0].type === 'event') {
      let { data: event, error } = await supabase
        .from('events')
        .select(`*, extracurricularInfo: extracurriculars ( title )`)
        .eq('id', routes?.[0].event_id)
        .single();

      console.log('ReSPONSE TO eVENT CALL', event || error);

      if (event) {
        setMarkerArray(getEventMapMarker(routes?.[0], event));
        setEventInfo(event);
      }
      if (error) console.log(error.message);
    }

    if (routes?.[0].type === 'field_trip') {
      console.log('THIS IS A FIELD TRIP');

      let { data: fieldTrip, error} = await supabase
        .from('field_trips')
        .select()
        .eq('id', routes?.[0].field_trip_id)
        .single();

      console.log('response to field trip call', fieldTrip || error);

      if (fieldTrip) {
        setMarkerArray(getEventMapMarker(routes?.[0], fieldTrip));
        setFieldTripInfo(fieldTrip);
      }
      if (error) console.log(error.message);
    }

    let { data: vehicles, error: vehicleError } = await supabase
      .from('vehicles')
      .select('*')
      .eq('id', routes?.[0].vehicle_id)
      .single();

    if (vehicles) setVehicleInfo(vehicles);
    // set destination and position from route start and end, last element of route.data array
    setDestination(
      routes?.[0].data?.[routes?.[0].data.length - 1].end.lat_long
    );
    setPosition(routes?.[0].data?.[routes?.[0].data.length - 1].start.lat_long);
    setRouteLoaded(true);
  };

  useEffect(() => {
    getRoute();
  }, []);

  useEffect(() => {
    if (currentRoute) {
      // get js date object from route date and time
      setExactStart(getExactStart(currentRoute.date, currentRoute.start_time));
      // route name dynamic based on cities in list of stops
      setRouteName(getRouteName(currentRoute));
      if (currentRoute.in_progress) {
        // if route has already been started and driver closed app to return to this page, noted
        if (currentRoute.type === 'standard') {
          let standardMapsUrl = generateUrlForGoogleMaps(currentRoute.data);
          setMapsUrl(standardMapsUrl);
        } else if (currentRoute.type === 'event' || currentRoute.type === 'field_trip') {
          let eventMapsUrl = generateUrlForGoogleMapsDriverEvent(
            currentRoute.data
          );
          setMapsUrl(eventMapsUrl);
        }
      }
      setWaypointsArray(createWaypointsArray(currentRoute, position));
      if (studentInfoArray) {
        setMarkerArray(getMapMarkerInfo(currentRoute, studentInfoArray));
      }
    }
  }, [position, currentRoute, studentInfoArray]);

  // useEffect set up to prevent starting route until 10 minutes prior to route start - updates every second
  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesAway(checkStart());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentRoute]);

  const navigate = useNavigate();

  const startRouteHandler = () => {
    navigate(`/route/${id}/precheck`);
  };

  // compares current time to time route starts and returns difference in minutes
  const checkStart = () => {
    if (currentRoute) {
      let dateObj = getExactStart(currentRoute.date, currentRoute.start_time);
      let currentTime = new Date(Date.now());
      let difference = differenceInMinutes(dateObj, currentTime);
      return difference;
    }
  };

  const customFormatDuration = (minutes: number) => {
    // takes minutes away and creates duration of time for date-fns (milliseconds * 60 * minutes)
    const duration = intervalToDuration({ start: 0, end: 1000 * 60 * minutes });
    // formats to N years, n weeks, n days etc. as needed
    const formatted = formatDuration(duration);
    return formatted;
  };

  // reopens navigation if driver has returned back to this page accidentally, opens google maps and navigates browser to in progress page
  const handleRestartNavigation = () => {
    watchUserLocation();
    setTimeout(() => {
      navigate(`/route/${id}/inprogress`);
    }, 500);
  };

  return (
    <div className="driver-app-parent">
      <Header />
      {routeLoaded && exactStart && currentRoute ? (
        <div style={{ lineHeight: 1 }}>
          <h2>Route ID: {currentRoute.id}</h2>
          <h3>{formatDate(exactStart)}</h3>
          <p>{routeName}</p>
          {currentRoute.in_progress && (
            <p
              style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}
            >
              IN PROGRESS
            </p>
          )}
          {currentRoute.complete && (
            <p
              style={{ color: 'green', textAlign: 'center', fontWeight: 'bold'}}
            >
              Complete
            </p>
          )}
          <Paper
            elevation={5}
            className="route-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
            }}
          >
            <p>
              <b>Vehicle:</b> {vehicleInfo.identifier}
            </p>
            {currentRoute.distance ? (
              <p>
                <b>Total Distance:</b> {currentRoute.distance.toFixed(1)} mi
              </p>
            ) : ('')}
            <p>
              <b>Stops:</b> {currentRoute.stop_count}
            </p>
            <p>
              <b>
                {currentRoute.type === 'standard'
                  ? 'Students: '
                  : 'Total Passengers: '}
              </b>
              {currentRoute.students_count}
            </p>
            <p>
              <b>Start Time:</b> {formatTime(exactStart)}
            </p>
            {currentRoute.est_duration ? (
              <p>
                <b>Estimated Time:</b> {currentRoute.est_duration} minutes
              </p>
            ) : ('')}
          </Paper>
          {minutesAway && minutesAway > 1200 && (
            <p style={{ color: 'red' }}>
              Precheck is {customFormatDuration(minutesAway)} away
            </p>
          )}
          {user.id === currentRoute.driver_id && !currentRoute.in_progress && !currentRoute.complete && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: '2em 0' }}
              onClick={startRouteHandler}
              disabled={minutesAway && minutesAway <= 1200 ? false : true}
            >
              Start Pre-Check
            </Button>
          )}
          {currentRoute.in_progress && (
            <a href={mapsUrl} target="__blank">
              <Button
                variant="contained"
                sx={{
                  margin: '2em 0',
                  backgroundColor: 'red',
                  ':hover': {
                    bgcolor: 'maroon',
                    color: 'white',
                  },
                }}
                onClick={handleRestartNavigation}
              >
                Restart Navigation
              </Button>
            </a>
          )}
          {waypointsArray && markerArray && destination && (
            <StaticMap
              driverLocation={position}
              waypointsArray={waypointsArray}
              markerArray={markerArray}
              destination={destination}
              vehicleType={vehicleInfo.model === 'bus' ? 'bus' : 'van'}
            />
          )}
          {eventInfo && (
            <Box sx={{ textAlign: 'center' }}>
              <h3
                style={{
                  fontSize: '2em',
                  textDecoration: 'underline',
                  marginBottom: 0,
                }}
              >
                Event Info
              </h3>
              <p style={{ fontWeight: 'bold' }}>
                {eventInfo.extracurricularInfo.title}
              </p>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: '10px',
                  textAlign: 'left',
                  outline: '1px solid grey',
                  padding: '.5em',
                  margin: '.5em',
                }}
              >
                <p>
                  <b>{eventInfo.title}</b>
                </p>
                <p>
                  <b>Event Start Time: </b>
                  {formatTime(
                    getExactStart(eventInfo.date, eventInfo.start_time)
                  )}
                </p>
                <p>
                  <b>Location: </b>
                  {eventInfo.location}
                </p>
                <p>
                  <b>Stop Instructions: </b>
                  {eventInfo.stop_instructions}
                </p>
              </Paper>
            </Box>
          )}
          {fieldTripInfo && (
            <Box sx={{ textAlign: 'center' }}>
              <h3
                style={{
                  fontSize: '2em',
                  textDecoration: 'underline',
                  marginBottom: 0,
                }}
              >
                Field Trip Info
              </h3>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: '10px',
                  textAlign: 'left',
                  outline: '1px solid grey',
                  padding: '.5em',
                  margin: '.5em',
                }}
              >
                <p>
                  <b>{fieldTripInfo.title}</b>
                </p>
                <p>
                  <b>Event Start Time: </b>
                  {formatTime(
                    getExactStart(fieldTripInfo.date, fieldTripInfo.start_time)
                  )}
                </p>
                <p>
                  <b>Location: </b>
                  {fieldTripInfo.location}
                </p>
                <p>
                  <b>Stop Instructions: </b>
                  {fieldTripInfo.stop_instructions}
                </p>
                {fieldTripInfo.chaperones.length > 0 && <p style={{fontWeight: 'bold', textAlign: 'center'}}>Chaperones</p>}
                {fieldTripInfo.chaperones.length > 0 && fieldTripInfo.chaperones.map( (chaperone: any) => {
                  return (
                    <p>{chaperone.name}</p>
                  )
                })}
              </Paper>
            </Box>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SingleRoute;
