import {
  FormControlLabel,
  Checkbox,
} from '@mui/material';

// used in setting driver availability

type CheckListItemProps = {
  itemText: string;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckListItem = ({itemText, checked, handleChange} : CheckListItemProps) => {
  return (
    <FormControlLabel
      label={itemText}
      sx={{ textAlign: 'left', margin: '.5em 0' }}
      control={
        <Checkbox value={itemText} checked={checked} onChange={handleChange} />
      }
    />
  );
};
