import { Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// pages
import { Home, NoMatch, SingleRoute, PreCheck, PostCheck, BaselineSchedule, WeeklySchedule, Login, Signup, AdminDashboard, Register, PasswordRecovery, ResetPassword, InProgress } from './pages';

// auth to protect routes
import { AuthProvider } from './contexts/Auth';
import { PrivateRoute } from './components';

// Global css/theme imports
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import moment from 'moment';

// Theme for Theme Provider
// Set for SMCCs colors, future development will include styles per district/user
const theme = createTheme({
  palette: {
    primary: {
      main: '#004812',
    },
    secondary: {
      main: '#b29430',
    },
  },
});

// imported to use moment to handle time in admin single route time picker
let launchMoment = require('moment');

function App() {
  return (
    <div className="app">
      <CssBaseline>
        <ThemeProvider theme={theme}>
          <Router>
            {/* context to authenticate users and fetch user data on log in */}
            <AuthProvider>
              <Fragment>
                <Routes>
                  {/* PrivateRoute component used to require users to be authenticated */}
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Home />} />
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/route/:id" element={<SingleRoute />}></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route
                      path="/route/:id/precheck"
                      element={<PreCheck />}
                    ></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route
                    path="/route/:id/inprogress"
                    element={<InProgress />}
                    ></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route
                      path="/route/:id/postcheck"
                      element={<PostCheck />}
                    ></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route
                      path="/schedule/baseline"
                      element={<BaselineSchedule />}
                    ></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route
                      path="/schedule/weekly"
                      element={<WeeklySchedule />}
                    ></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/admin/*" element={<AdminDashboard />}></Route>
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/register" element={<Register />} />
                  </Route>
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/password-recovery"
                    element={<PasswordRecovery />}
                  />
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  />

                  <Route path="*" element={<NoMatch />}></Route>
                </Routes>
              </Fragment>
            </AuthProvider>
          </Router>
        </ThemeProvider>
      </CssBaseline>
    </div>
  );
}

export default App;
