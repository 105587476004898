import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { supabase } from '../../db/client';
import { IDriver } from '../../ts/interfaces';

import {
  Paper,
  Box,
  TextField,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Button,
  Modal,
  FormGroup,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input';
import { DayRadioButton, CheckBoxTimeSlot } from '../../components';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { capitalize } from '../../utils/textFormattingHelpers';
import { formatDate } from '../../utils/dateTimeHelpers';

import { IWeeklyAvailabilityDay, IAvailabilityDay } from '../../ts/interfaces';

type AdminSingleDriverProps = {
  drivers: IDriver[];
  tableNames: any;
  updateAllDrivers: () => void;
};

const AdminSingleDriver = ({ drivers, tableNames, updateAllDrivers }: AdminSingleDriverProps) => {
  const [currentDriver, setCurrentDriver] = useState<any>();
  const [phoneInput, setPhoneInput] = useState<string>('+1 ');
  const [isValidPhone, setIsValidPhone] = useState<boolean>();
  const [isValidLicense, setIsValidLicense] = useState<boolean>();
  const [date, setDate] = useState<Date>(new Date());
  const [weeklyAvailAsDates, setWeeklyAvailAsDates] = useState<Date[]>();
  const [weeklyAvailDateSelected, setWeeklyAvailDateSelected] =
    useState<IWeeklyAvailabilityDay | null>();

  const [baselineModalOpen, setBaselineModalOpen] = useState(false);
  const [baselineAvailability, setBaselineAvailability] = useState<
    IAvailabilityDay[]
  >([]);
  const [daySelected, setDaySelected] = useState('monday');

  const [weeklyModalOpen, setWeeklyModalOpen] = useState(false);
  const [weeklyAvailability, setWeeklyAvailability] = useState<
    IWeeklyAvailabilityDay[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [licenseError, setLicenseError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // pull driver id from url params
  const driverID = useParams().id;

  useEffect(() => {
    // on load pull driver id from params, find in array of drivers pulled on admin log in, set to state
    const thisDriver = drivers.find((driver: IDriver) => {
      return driver.id === Number(driverID);
    });

    if (thisDriver) setCurrentDriver(thisDriver);
    // handle conversion from number stored for phone to string for input
    if (thisDriver?.phone) {
      let phoneStringSplit = String(thisDriver.phone).split('');
      let phoneFormat = `${phoneStringSplit[0]}${phoneStringSplit[1]}${phoneStringSplit[2]} ${phoneStringSplit[3]}${phoneStringSplit[4]}${phoneStringSplit[5]} ${phoneStringSplit[6]}${phoneStringSplit[7]}${phoneStringSplit[8]}${phoneStringSplit[9]}`;
      setPhoneInput('+1 ' + phoneFormat);
    }

    // store availabilities in state
    if (thisDriver?.baseline_availability) {
      setBaselineAvailability(thisDriver.baseline_availability);
    }

    if (thisDriver?.weekly_availability) {
      let availDates: Date[] = [];
      for (let i = 0; i < thisDriver.weekly_availability?.length; i++) {
        let dateStrSplit = thisDriver.weekly_availability[i].date.split('-');
        let year = Number(dateStrSplit[0]);
        let month = Number(dateStrSplit[1]);
        let day = Number(dateStrSplit[2]);

        let newDate = new Date(year, month - 1, day);

        availDates.push(newDate);
        setWeeklyAvailAsDates(availDates);
        setWeeklyAvailability(thisDriver.weekly_availability);
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (currentDriver && date) {
      let currentDateStr = formatDate(date);

      let indexOfDate = -1;

      let dayFromAvail = weeklyAvailAsDates?.filter(
        (date: Date, index: number) => {
          let formatted = formatDate(date);
          if (formatted === currentDateStr) {
            indexOfDate = index;
          }
          return formatted === currentDateStr;
        }
      );

      // when date selected on date picker, change which day of availability is being edited
      if (dayFromAvail) {
        setWeeklyAvailDateSelected(
          currentDriver?.weekly_availability[indexOfDate]
        );
      } else {
        setWeeklyAvailDateSelected(null);
      }
    }
  }, [date, currentDriver]);

  // when phone input is changed attempt to validate
  useEffect(() => {
    setIsValidPhone(isValidPhoneNumber(phoneInput));
  }, [phoneInput]);

  // when license is changed, validate
  useEffect(() => {
    if (currentDriver) {
      if (
        currentDriver.license === 'standard' ||
        currentDriver.license === 'cdl' ||
        currentDriver.license === 'chauffer'
      ) {
        setIsValidLicense(true);
      }
    }
  }, [currentDriver]);

  // reset error when phone input changes
  const phoneChange = (value: string) => {
    setPhoneError('');
    setPhoneInput(value);
  };

  // dynamic color on availability
  const slotColor = (avail: boolean) => {
    if (avail) return 'lightgreen';
    return '#FFCCCB';
  };

  const weeklyAvailDateColor = (slot: string) => {
    if (weeklyAvailDateSelected) {
      if (slot === 'morning') {
        if (weeklyAvailDateSelected?.morning) {
          return 'lightgreen';
        } else {
          return '#FFCCCB';
        }
      }
      if (slot === 'midday') {
        if (weeklyAvailDateSelected?.midday) {
          return 'lightgreen';
        } else {
          return '#FFCCCB';
        }
      }
      if (slot === 'afternoon') {
        if (weeklyAvailDateSelected?.afternoon) {
          return 'lightgreen';
        } else {
          return '#FFCCCB';
        }
      }
      if (slot === 'evening') {
        if (weeklyAvailDateSelected?.evening) {
          return 'lightgreen';
        } else {
          return '#FFCCCB';
        }
      }
    }
  };

  const dayOptions = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const checkOptions = ['morning', 'midday', 'afternoon', 'evening'];

  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaySelected(event.target.value);
  };

  // simple way to visualize availability per day per block - green and O means available, red and X means unavailable
  const baseAvailCheck = (value: boolean) => {
    if (value) return 'O';
    return 'X';
  };

  const setNewBaselineAvail = async () => {
    let updatedDriver = {
      ...currentDriver,
      baseline_availability: baselineAvailability,
    };
    setCurrentDriver(updatedDriver);
    setBaselineModalOpen(false);
  };

  const setNewWeeklyAvail = async () => {
    let updatedDriver = {
      ...currentDriver,
      weekly_availability: weeklyAvailability,
    };
    setCurrentDriver(updatedDriver);
    setWeeklyModalOpen(false);
  };

  const checkBaselineValue = (timeslot: string) => {
    if (baselineAvailability) {
      let currentDay = baselineAvailability.filter(
        (day) => day.day === daySelected
      )[0];
      // typescript trickery to allow dynamic access to nested properties of object
      let value = currentDay[timeslot as keyof typeof currentDay];
      return value as boolean;
    }
    return false;
  };

  const checkWeeklyValue = (timeslot: string) => {
    if (weeklyAvailDateSelected) {
      let value = weeklyAvailDateSelected[timeslot as keyof typeof weeklyAvailDateSelected];
      return value as boolean;
    }
    return false;
  };

  const handleBaselineCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let slot = event.target.value;
    let dayOfWeek = daySelected;
    let newAvailability = [...baselineAvailability];
    let index = newAvailability.findIndex((day) => {
      return day.day === dayOfWeek;
    });

    let newDay = {
      day: dayOfWeek,
      morning: newAvailability[index]['morning'],
      midday: newAvailability[index]['midday'],
      afternoon: newAvailability[index]['afternoon'],
      evening: newAvailability[index]['evening'],
    };

    if (slot === 'morning') {
      newDay.morning = !newAvailability[index].morning;
    } else if (slot === 'midday') {
      newDay.midday = !newAvailability[index].midday;
    } else if (slot === 'afternoon') {
      newDay.afternoon = !newAvailability[index].afternoon;
    } else if (slot === 'evening') {
      newDay.evening = !newAvailability[index].evening;
    }

    newAvailability[index] = newDay;
    setBaselineAvailability(newAvailability);
  };

  const handleWeeklyCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let slot = event.target.value;
    let newAvailability = [...weeklyAvailability];
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let currentDateStr = formatDate(date);

    let indexOfDate = -1;

    let dayFromAvail = weeklyAvailAsDates?.filter(
      (date: Date, index: number) => {
        let formatted = formatDate(date);
        if (formatted === currentDateStr) {
          indexOfDate = index;
        }
        return formatted === currentDateStr;
      }
    );

    let newDay = {
      date: `${year}-${month}-${day}`,
      morning: newAvailability[indexOfDate]['morning'],
      midday: newAvailability[indexOfDate]['midday'],
      afternoon: newAvailability[indexOfDate]['afternoon'],
      evening: newAvailability[indexOfDate]['evening'],
    };

    if (slot === 'morning') {
      newDay.morning = !newAvailability[indexOfDate].morning;
    } else if (slot === 'midday') {
      newDay.midday = !newAvailability[indexOfDate].midday;
    } else if (slot === 'afternoon') {
      newDay.afternoon = !newAvailability[indexOfDate].afternoon;
    } else if (slot === 'evening') {
      newDay.evening = !newAvailability[indexOfDate].evening;
    }

    newAvailability[indexOfDate] = newDay;
    setWeeklyAvailability(newAvailability);
    setWeeklyAvailDateSelected(newDay);
  };

  const updateDriver = async () => {
    // validate, set error if needed, return if not valid
    if (!isValidPhone) {
      setPhoneError('Not a valid phone number');
    }

    if (!isValidLicense) {
      setLicenseError("License must be 'standard', 'cdl' or 'chauffer'");
    }

    if (!isValidPhone || !isValidLicense) return;

    // handle phone input for update
    let phoneSplit = phoneInput.split(' ');
    let phoneFormat = Number(
      `${phoneSplit[1]}${phoneSplit[2]}${phoneSplit[3]}`
    );
    const { data, error } = await supabase
      .from(tableNames.drivers)
      .update({
        baseline_availability: baselineAvailability,
        home_address: currentDriver.home_address,
        license: currentDriver.license,
        phone: phoneFormat,
        weekly_availability: weeklyAvailability,
      })
      .eq('id', driverID);

    console.log('response', data || error);
    if (data) {
      setSuccess(true);
      // update all drivers to reflect change immediately
      updateAllDrivers();
    }
    if (error) setError(true);
  };

  return (
    <>
      <div
        style={{
          marginTop: '5em',
          textAlign: 'center',
        }}
      >
        {currentDriver && !loading && (
          <>
            <h1 style={{ margin: 0, padding: 0 }}>
              {currentDriver.fname} {currentDriver.lname}
            </h1>
            <p style={{ margin: 0, padding: 0 }}>{currentDriver.email}</p>
            <Paper
              elevation={5}
              className="route-details"
              sx={{
                borderRadius: '30px',
                textAlign: 'left',
                padding: 5,
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>Address</InputLabel>
                <Input
                  id="address-input"
                  value={currentDriver.home_address}
                  onChange={(event) => {
                    setCurrentDriver({
                      ...currentDriver,
                      home_address: event.target.value,
                    });
                  }}
                />
              </FormControl>
              <br />
              <FormControl
                fullWidth
                variant="standard"
                sx={{ margin: '1em 0 1em' }}
              >
                <InputLabel>License</InputLabel>
                <Input
                  id="license-input"
                  value={currentDriver.license}
                  onChange={(event) => {
                    setLicenseError('');
                    if (
                      event.target.value !== 'standard' &&
                      event.target.value !== 'cdl' &&
                      event.target.value !== 'chauffer'
                    )
                      setIsValidLicense(false);
                    setCurrentDriver({
                      ...currentDriver,
                      license: event.target.value,
                    });
                  }}
                />
              </FormControl>
              {!isValidLicense && (
                <p style={{ fontSize: '.8em', color: 'red', margin: 0 }}>
                  License must be 'standard', 'cdl' or 'chauffer'
                </p>
              )}
              <br />
              <div style={{ margin: '1em auto' }}>
                <Typography color="primary" sx={{ fontSize: '1em' }}>
                  Phone Number (w/ area code)
                </Typography>
                <MuiTelInput
                  value={phoneInput}
                  onChange={phoneChange}
                  onlyCountries={['US']}
                  disableDropdown={true}
                />
              </div>
            </Paper>
            <h2 style={{ textDecoration: 'underline' }}>
              Baseline Availability
            </h2>
            <Button
              variant="contained"
              onClick={() => setBaselineModalOpen(true)}
              sx={{ marginBottom: '1em' }}
            >
              Edit
            </Button>
            <Box
              sx={{
                display: 'flex',
                width: 'fit-content',
                margin: '0 auto',
              }}
            >
              <div
                id="blocks-container"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '4.1em',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Morning
                </p>
                <p
                  style={{
                    margin: 0,
                    padding: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Midday
                </p>
                <p
                  style={{
                    margin: 0,
                    padding: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Afternoon
                </p>
                <p
                  style={{
                    margin: 0,
                    padding: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Evening
                </p>
              </div>
              {currentDriver.baseline_availability.map((day: any) => {
                return (
                  <div id="day" key={day.day}style={{}}>
                    <h3
                      style={{
                        textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      {capitalize(day.day)[0]}
                      {capitalize(day.day)[1]}
                    </h3>
                    <div
                      id="blocks-container"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <p
                        style={{
                          outline: '1px solid black',
                          margin: 0,
                          padding: 10,
                          backgroundColor: `${slotColor(day.morning)}`,
                          fontWeight: 'bold',
                        }}
                      >
                        {baseAvailCheck(day.morning)}
                      </p>
                      <p
                        style={{
                          outline: '1px solid black',
                          margin: 0,
                          padding: 10,
                          backgroundColor: `${slotColor(day.midday)}`,
                          fontWeight: 'bold',
                        }}
                      >
                        {baseAvailCheck(day.midday)}
                      </p>
                      <p
                        style={{
                          outline: '1px solid black',
                          margin: 0,
                          padding: 10,
                          backgroundColor: `${slotColor(day.afternoon)}`,
                          fontWeight: 'bold',
                        }}
                      >
                        {baseAvailCheck(day.afternoon)}
                      </p>
                      <p
                        style={{
                          outline: '1px solid black',
                          margin: 0,
                          padding: 10,
                          backgroundColor: `${slotColor(day.evening)}`,
                          fontWeight: 'bold',
                        }}
                      >
                        {baseAvailCheck(day.evening)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Box>
            {baselineModalOpen && (
              <Modal
                open={baselineModalOpen}
                onClose={() => setBaselineModalOpen(false)}
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    paddingInline: '1em',
                  }}
                  textAlign="center"
                >
                  <Box textAlign="right" sx={{ margin: 0 }}>
                    <Button
                      sx={{
                        fontSize: '1em',
                        marginTop: '.5em',
                        marginBottom: '.5em',
                      }}
                      onClick={() => setBaselineModalOpen(false)}
                    >
                      X
                    </Button>
                  </Box>
                  <FormControl onChange={dayChangeHandler}>
                    <FormLabel id="baseline-form-label">
                      Baseline Availability
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="form-control-label-placement"
                      name="base-line-schedule"
                      value={daySelected}
                    >
                      {dayOptions.map((day) => (
                        <DayRadioButton day={day} key={day} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      width: '200px',
                      margin: '1em auto',
                    }}
                  >
                    <FormGroup
                      sx={{
                        margin: '0 auto',
                      }}
                    >
                      {checkOptions.map((timeSlot) => (
                        <CheckBoxTimeSlot
                          timeSlot={timeSlot}
                          checkValue={checkBaselineValue(timeSlot)}
                          handleCheck={handleBaselineCheck}
                          key={timeSlot}
                        ></CheckBoxTimeSlot>
                      ))}
                    </FormGroup>
                  </div>
                  <Button
                    variant="contained"
                    onClick={setNewBaselineAvail}
                    color="secondary"
                    sx={{ marginBottom: '1em' }}
                  >
                    Update Availability
                  </Button>
                </Box>
              </Modal>
            )}
            <h2 style={{ textDecoration: 'underline' }}>Weekly Availability</h2>
            <Box
              id="weekly-avail-display"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'center',
                marginBottom: '1em',
              }}
            >
              <div id="date-picker">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => {
                      if (newValue) setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ display: { sm: 'none' } }} />
                    )}
                  />
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      justifySelf: 'center',
                    }}
                  >
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      value={date}
                      onChange={(newValue) => {
                        if (newValue) setDate(newValue as Date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </LocalizationProvider>
              </div>
              <Box
                id="weekly-avail-shown"
                sx={{
                  width: 300,
                  minHeight: 402,
                  margin: { xs: '0 auto', sm: '0' },
                }}
              >
                {date && (
                  <div id="date" style={{}}>
                    <h3
                      style={{
                        textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      {formatDate(date)}
                    </h3>
                    {!weeklyAvailDateSelected ? (
                      <p>No availability set for this day.</p>
                    ) : (
                      <div
                        id="blocks-container"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <p
                          style={{
                            outline: '1px solid black',
                            margin: 0,
                            padding: 20,
                            backgroundColor: `${weeklyAvailDateColor(
                              'morning'
                            )}`,
                            fontWeight: 'bold',
                          }}
                        >
                          Morning
                        </p>
                        <p
                          style={{
                            outline: '1px solid black',
                            margin: 0,
                            padding: 20,
                            backgroundColor: `${weeklyAvailDateColor(
                              'midday'
                            )}`,
                            fontWeight: 'bold',
                          }}
                        >
                          Midday
                        </p>
                        <p
                          style={{
                            outline: '1px solid black',
                            margin: 0,
                            padding: 20,
                            backgroundColor: `${weeklyAvailDateColor(
                              'afternoon'
                            )}`,

                            fontWeight: 'bold',
                          }}
                        >
                          Afternoon
                        </p>
                        <p
                          style={{
                            outline: '1px solid black',
                            margin: 0,
                            padding: 20,
                            backgroundColor: `${weeklyAvailDateColor(
                              'evening'
                            )}`,
                            fontWeight: 'bold',
                          }}
                        >
                          Evening
                        </p>
                        <Button
                          variant="contained"
                          onClick={() => setWeeklyModalOpen(true)}
                        >
                          Edit Date
                        </Button>
                      </div>
                    )}
                    {weeklyModalOpen && (
                      <Modal
                        open={weeklyModalOpen}
                        onClose={() => setWeeklyModalOpen(false)}
                      >
                        <Box
                          sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            paddingInline: '1em',
                          }}
                          textAlign="center"
                        >
                          <Box textAlign="right" sx={{ margin: 0 }}>
                            <Button
                              sx={{
                                fontSize: '1em',
                                marginTop: '.5em',
                                marginBottom: '.5em',
                              }}
                              onClick={() => setWeeklyModalOpen(false)}
                            >
                              X
                            </Button>
                          </Box>
                          <h3>{formatDate(date)}</h3>
                          <div
                            style={{
                              width: '200px',
                              margin: '1em auto',
                            }}
                          >
                            <FormGroup
                              sx={{
                                margin: '0 auto',
                              }}
                            >
                              {checkOptions.map((timeSlot) => (
                                <CheckBoxTimeSlot
                                  timeSlot={timeSlot}
                                  checkValue={checkWeeklyValue(timeSlot)}
                                  handleCheck={handleWeeklyCheck}
                                  key={timeSlot}
                                ></CheckBoxTimeSlot>
                              ))}
                            </FormGroup>
                          </div>
                          <Button
                            variant="contained"
                            onClick={setNewWeeklyAvail}
                            color="secondary"
                            sx={{ marginBottom: '1em' }}
                          >
                            Update Availability
                          </Button>
                        </Box>
                      </Modal>
                    )}
                  </div>
                )}
              </Box>
            </Box>
            {licenseError && <p style={{ color: 'red' }}>{licenseError}</p>}
            {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
            <Button
              variant="contained"
              onClick={updateDriver}
              style={{ marginBottom: '5em', fontSize: '1.5em' }}
            >
              Update Driver
            </Button>
          </>
        )}
        {!currentDriver && !loading && <h1>No driver with this ID</h1>}
        {success && (
          <Modal open={success} onClose={() => setSuccess(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 1,
              }}
              textAlign="center"
            >
              <p>Driver Updated</p>
              <Button
                variant="contained"
                onClick={() => setSuccess(false)}
                sx={{ marginBottom: '1em' }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        )}
        {error && (
          <Modal open={error} onClose={() => setError(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 1,
              }}
              textAlign="center"
            >
              <p>Something went wrong...</p>
              <Button
                variant="contained"
                onClick={() => setError(false)}
                sx={{ marginBottom: '1em' }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

export default AdminSingleDriver;
