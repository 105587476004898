import { useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';

import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Modal,
  TextField,
} from '@mui/material';

// custom components
import { FinishCheckModal, CheckListItem } from '../../components';

import {
  generateUrlForGoogleMaps,
  generateUrlForGoogleMapsDriverEvent,
} from '../../utils/generateUrlForGoogleMaps';

import { IRoute, IStopInfo } from '../../ts/interfaces';

const PreCheck = () => {
  // all checklist items are currently required but system in place to allow for some to be optional
  const standardCheckListItems = [
    {
      text: 'Complete Walkaround Inspection',
      required: true,
    },
    {
      text: 'Open hood and check for leaks, fill up windshield fluid if necessary',
      required: true,
    },
    {
      text: 'Verify that tire pressure is acceptable',
      required: true,
    },
    {
      text: 'Verify that emergency equipment is present and in proper condition',
      required: true,
    },
    {
      text: 'Verify that the fuel level is at least 1/2',
      required: true,
    },
    {
      text: 'Verify that sensors and cameras are clean and unobstructed',
      required: true,
    },
  ];

  // bus drivers currently use daily paper checklist so are only required 
  const busCheckListItems = [
    {
      text: 'Verify that you have filled out the standard paper pre check',
      required: true,
    },
  ];

  // route id pulled from url params
  const id = useParams().id;
  const { user, watchUserLocation } = useAuth();

  const [currentRoute, setCurrentRoute] = useState<IRoute>();
  const [routeInfo, setRouteInfo] = useState<any>();
  const [mapsUrl, setMapsUrl] = useState<string>();

  const [routeLoaded, setRouteLoaded] = useState(false);
  const [routeType, setRouteType] = useState<string>();
  // full list of all items clicked
  const [precheck, setPrecheck] = useState<string[]>([]);
  const [checkListItems, setCheckListItems] = useState<
    { text: string; required: boolean }[]
  >([]);
  // required items that have been checked
  const [requiredListItems, setRequiredListItems] = useState<string[]>([]);
  // length against which to check for all required items
  const [requiredLength, setRequiredLength] = useState(0);

  // states to signify readiness of driver, for simplicity
  const [allChecked, setAllChecked] = useState(false);
  const [allRequired, setAllRequired] = useState(false);

  // states for modals
  const [reportModalState, setReportModalState] = useState(false);
  const [errorModalState, setErrorModalState] = useState(false);

  // value of message to send to admin
  const [issueText, setIssueText] = useState('');

  // get route info from user context, if not present driver is not assigned, navigate to driver home
  const getRoute = async () => {
    let currentRouteFromUser = user.routes.filter((route: IRoute) => {
      return route.id === Number(id);
    })[0];

    if (!currentRouteFromUser) {
      navigate('/');
      return;
    }

    setCurrentRoute(currentRouteFromUser.data);
    let currentRouteInfo = currentRouteFromUser;

    // check type, if standard get student info - precheck items are conditional on student grade
    if (currentRouteInfo.type === 'standard') {
      let students = currentRouteInfo.data.slice(0, -1);

      let arrayOfStudentIDs = students.map((student: IStopInfo) => {
        return student.student_id;
      });

      let studentQueryString = '';

      for (let i = 0; i < arrayOfStudentIDs.length; i++) {
        studentQueryString += `id.eq.${arrayOfStudentIDs[i]}`;
        if (i < arrayOfStudentIDs.length - 1) studentQueryString += ',';
      }

      let { data: studentInfo, error: error2 } = await supabase
        .from('students')
        .select()
        .or(studentQueryString);

      currentRouteInfo.students = studentInfo;

      let carSeatCount = 0;

      // add one to car seat count for every student that is in grade 1 or Y5/K - will need to be adjusted to work for districts with
      // different grade levels
      if (studentInfo) {
        for (let i = 0; i < studentInfo.length; i++) {
          if (studentInfo[i].grade === '1' || studentInfo[i].grade === 'Y5/K') {
            carSeatCount++;
          }
        }
      }

      // if there are students that need a car seat, add this item to checklist
      if (carSeatCount) {
        standardCheckListItems.push({
          text: `Verify that appropriate child restraint systems are present: ${carSeatCount} car seat`,
          required: true,
        });
      }

      setMapsUrl(generateUrlForGoogleMaps(currentRouteFromUser.data));
    }

    let vehicleID = currentRouteInfo.vehicle_id;

    let { data: vehicleInfo, error: vehicleError } = await supabase
      .from('vehicles')
      .select()
      .eq('id', vehicleID);

    currentRouteInfo.vehicleInfo = vehicleInfo?.[0];

    if (currentRouteInfo.vehicleInfo.license_required === 'cdl') {
      setCheckListItems(busCheckListItems);
      setRequiredLength(
        busCheckListItems.filter((item) => item.required === true).length
      );
    }
    if (currentRouteInfo.vehicleInfo.license_required !== 'cdl') {
      setCheckListItems(standardCheckListItems);
      setRequiredLength(
        standardCheckListItems.filter((item) => item.required === true).length
      );
    }
    setRouteInfo(currentRouteInfo);
    if (currentRouteInfo.type === 'event' || currentRouteInfo.type === 'field_trip')
      setMapsUrl(
        generateUrlForGoogleMapsDriverEvent(currentRouteFromUser.data)
      );
    setRouteLoaded(true);
  };

  // navigating to precheck page means driver is starting route - so run watch location once user is validated, watch location will continue
  // to update user location in context until cancelled after finishing post check

  useEffect(() => {
    if (user.routes) {
      watchUserLocation();
      getRoute();
    }
  }, [user]);

  useEffect(() => {
    if (precheck.length) {
      if (precheck.length === checkListItems.length) setAllChecked(true);
      else setAllChecked(false);
      if (requiredListItems.length === requiredLength) setAllRequired(true);
      else setAllRequired(false);
    }
  }, [precheck]);

  const generateChecklistForAdmin = (checklist: typeof checkListItems[0][]) => {
    let checklistForAdmin = checklist.map((item) => {
      let newItem = {
        item: item,
        checked: precheck.includes(item.text),
      };
      return newItem;
    });
    return checklistForAdmin;
  };
  // error state, shows if all items required to start the route are not checked and start button clicked
  const [errorState, setErrorState] = useState<boolean>(false);

  // to redirect on success
  const navigate = useNavigate();

  // whenever a checkbox is clicked, either add it or remove it to precheck and if required add it to required
  const handlePrecheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState(false);
    const index = precheck.indexOf(event.target.value);
    if (index === -1) {
      setPrecheck([...precheck, event.target.value]);
      let item = checkListItems.filter(
        (item) => item.text === event.target.value && item.required
      )[0];
      if (item)
        setRequiredListItems([...requiredListItems, event.target.value]);
    } else {
      setPrecheck(
        precheck.filter((precheck) => precheck !== event.target.value)
      );
      setRequiredListItems(
        requiredListItems.filter((item) => item !== event.target.value)
      );
    }
  };

  const handleStartClick = (event: React.MouseEvent) => {
    if (allRequired) {
      setReportModalState(true);
    } else {
      setErrorState(true);
    }
  };

  const handleReportClick = (event: React.MouseEvent) => {
    setErrorModalState(true);
  };

  const handleIssueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssueText(event.target.value);
  };

  // report is not created unless user includes a message - since all checklist items are required continuing from here implies all items
  // were checked
  const sendIssueToAdmin = async () => {
    if (issueText !== '') {
      const newAdminReport = {
        reporter: routeInfo.driver_id,
        vehicle: routeInfo.vehicle_id,
        message: issueText,
        checklist: JSON.stringify(generateChecklistForAdmin(checkListItems)),
        route: routeInfo.id,
      };

      const { data, error } = await supabase
        .from('reports')
        .insert([newAdminReport], { returning: 'minimal' });
    }

    if (errorState) navigate('/');
    else {
      startNavigationHandler();
    }
  };

  // when navigation is started, route is updated to in progress and time stamp is set as navigation start to track actual length of routes
  // and used for driver time card
  const startNavigationHandler = async () => {
    let timeStamp = new Date(Date.now()).toISOString();
    
    const { data, error } = await supabase
      .from('routes')
      .update({ in_progress: false, navigation_start: timeStamp })
      .eq('id', id);

    console.log('response', data || error);

    // time out to navigate to next page to avoid flash before turn by turn navigation opens in google maps
    setTimeout(() => {
      navigate(`/route/${id}/inprogress`);
    }, 500);
  };

  return (
    <div className="driver-app-parent">
      <Header />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ margin: '0 1em' }}
      >
        <FormControl>
          <FormLabel id="form-label-heading">Pre-Check</FormLabel>
          {routeInfo && (
            <FormGroup>
              {checkListItems.map((item) => {
                return (
                  <CheckListItem
                    itemText={item.text}
                    checked={precheck.includes(item.text)}
                    handleChange={handlePrecheckChange}
                    key={item.text}
                  ></CheckListItem>
                );
              })}
            </FormGroup>
          )}
        </FormControl>
        {errorState && (
          <p style={{ color: 'red', margin: 0 }}>
            If you are not able to check all required items you will not be able
            to start the route and must send a report to administrator.
          </p>
        )}
        {errorState && (
          // required unchecked main page
          <Button
            variant="contained"
            color="error"
            onClick={handleReportClick}
            id="check-list-button"
            sx={{ marginBottom: '1em' }}
          >
            Report
          </Button>
        )}
        {!errorState && (
          // main page
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStartClick}
            id="check-list-button"
          >
            Start Route
          </Button>
        )}

        {errorModalState && (
          <FinishCheckModal
            open={errorModalState}
            setOpen={() => setErrorModalState(false)}
          >
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: '1em',
                boxShadow: 24,
                p: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                sx={{
                  color: 'black',
                  marginBottom: '1em',
                  alignSelf: 'flex-end',
                }}
                onClick={() => setErrorModalState(false)}
              >
                X
              </Button>
              <p>
                Please describe the situation. Your message will be sent to the
                administator and you will be returned to the home page.
              </p>
              <TextField
                id="outlined-textarea"
                label="Message to admin"
                placeholder="Include a message to admin"
                value={issueText}
                multiline
                rows={5}
                onChange={handleIssueChange}
                color="error"
              />
              {issueText !== '' && (
                <Button
                  // required unchecked popup window
                  variant="contained"
                  onClick={() => sendIssueToAdmin()}
                  color="error"
                  sx={{ marginTop: '1.5em' }}
                >
                  Send Message and Exit
                </Button>
              )}
            </Box>
          </FinishCheckModal>
        )}

        {reportModalState && (
          <Modal
            open={reportModalState}
            onClose={() => setReportModalState(false)}
          >
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: '1em',
                boxShadow: 24,
                p: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                sx={{
                  color: 'black',
                  marginBottom: '1em',
                  alignSelf: 'flex-end',
                }}
                className="modal-x-button"
                onClick={() => setReportModalState(false)}
              >
                X
              </Button>
              {allChecked && (
                // all checked popup window
                <p>
                  You're all set. Include a message to admin (optional). Click
                  Start to begin the route.
                </p>
              )}
              {!allChecked && allRequired && (
                // optional unchecked popup window
                <p>
                  You have not checked all items. Check all items or send a note
                  to administrator before beginning the route.
                </p>
              )}
              <TextField
                id="outlined-textarea"
                label="Message to admin"
                placeholder="Message to admin"
                value={issueText}
                multiline
                rows={5}
                onChange={handleIssueChange}
              />
              {allRequired && !allChecked && issueText && (
                // optional unchecked popup window
                <a href={mapsUrl} target="__blank">
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendIssueToAdmin();
                    }}
                    color="secondary"
                    sx={{ marginTop: '1.5em' }}
                  >
                    Send Message and Start Route
                  </Button>
                </a>
              )}
              {allChecked && issueText === '' && (
                // all checked popup window no issue
                <a href={mapsUrl} target="__blank">
                  <Button
                    variant="contained"
                    onClick={startNavigationHandler}
                    color="secondary"
                    sx={{ marginTop: '1.5em' }}
                  >
                    Start Route
                  </Button>
                </a>
              )}
              {allChecked && issueText && (
                // all checked popup window with issue
                <a href={mapsUrl} target="__blank">
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendIssueToAdmin();
                    }}
                    color="secondary"
                    sx={{ marginTop: '1.5em' }}
                  >
                    Send Message and Start Route
                  </Button>
                </a>
              )}
            </Box>
          </Modal>
        )}
      </Box>
    </div>
  );
};

export default PreCheck;
