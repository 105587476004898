// method to create google maps url on routes to open turn by turn navigation for drivers
// format is base url/<lat-long>/<lat-long>/...
export const generateUrlForGoogleMaps = (routeData: any[]): string => {
  let googleMapsUrl = 'https://www.google.com/maps/dir/your+location/';
  let stops = routeData.slice(0, -1);

  let allWaypointsStrings = stops.map((stop) => {
    return JSON.stringify(stop.lat_long);
  });

  let filteredStringArray = allWaypointsStrings.filter(
    (waypoint: string, index: number) => {
      return allWaypointsStrings.indexOf(waypoint) === index;
    }
  );

  let uniqueWaypointsArray = filteredStringArray.map((waypoint: string) => {
    return JSON.parse(waypoint);
  });

  for (let i = 0; i < uniqueWaypointsArray.length; i++) {
    googleMapsUrl += uniqueWaypointsArray[i].toString() + '/';
  }

  let destination = routeData[routeData.length - 1].end.lat_long;
  googleMapsUrl += destination.toString();
  return googleMapsUrl;
};

// admin version will start from start of route rather than driver location
export const generateUrlForGoogleMapsAdmin = (routeData: any[]): string => {
  let googleMapsUrl = 'https://www.google.com/maps/dir/';

  let start = routeData[routeData.length - 1].start.lat_long;
  googleMapsUrl += start.toString() + '/';

  let stops = routeData.slice(0, -1);

  let allWaypointsStrings = stops.map((stop) => {
    return JSON.stringify(stop.lat_long);
  });

  let filteredStringArray = allWaypointsStrings.filter(
    (waypoint: string, index: number) => {
      return allWaypointsStrings.indexOf(waypoint) === index;
    }
  );

  let uniqueWaypointsArray = filteredStringArray.map((waypoint: string) => {
    return JSON.parse(waypoint);
  });

  for (let i = 0; i < uniqueWaypointsArray.length; i++) {
    googleMapsUrl += uniqueWaypointsArray[i].toString() + '/';
  }

  let destination = routeData[routeData.length - 1].end.lat_long;
  googleMapsUrl += destination.toString();
  return googleMapsUrl;
};

// takes single location for events/field trips - starts at start of route
export const generateUrlForGoogleMapsAdminEvent = (routeData: any[]) => {
  let googleMapsUrl = 'https://www.google.com/maps/dir/';

  let start = routeData[routeData.length - 1].start.lat_long;
  googleMapsUrl += start.toString() + '/';

  let eventLocation = routeData[0].lat_long;
  googleMapsUrl += eventLocation;

  return googleMapsUrl;
};

// takes single location for events/field trips - starts at driver location
export const generateUrlForGoogleMapsDriverEvent = (routeData: any[]) => {
  let googleMapsUrl = 'https://www.google.com/maps/dir/your+location/';

  let eventLocation = routeData[0].lat_long;
  googleMapsUrl += eventLocation;

  return googleMapsUrl;
};