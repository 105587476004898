import { useState, useEffect } from 'react';

import { supabase } from '../../db/client';
import MultiMap from '../../components/MultiMap';
import { getMapMarkerInfo, getStudentInfoFromRoute, getEventMapMarker } from '../../utils/routeDataHandlers';

type AdminLiveMapProps = {
  routes: any;
  tableNames: any;
  drivers: any;
  districtInfo: any;
}

// Live map currently checks for routes marked in progress, then displays just those routes in multimap with marker for driver's last location
const AdminLiveMap = ({ routes, tableNames, drivers, districtInfo }: AdminLiveMapProps) => {
  const [routesInProgress, setRoutesInProgress] = useState<any>();
  const [noCurrentRoutes, setNoCurrentRoutes] = useState<boolean>();

  let colors = ['blue', 'indigo', 'violet', 'green', 'orange', 'yellow', 'red'];

  const addMarkerArray = async (route: any) => {
    if (route.type === 'standard') {
      let studentInfoArray = await getStudentInfoFromRoute(
        route,
        tableNames.students
      );
      if (studentInfoArray) {
        let markerInfoArray = getMapMarkerInfo(route, studentInfoArray);
        route.markerInfoArray = markerInfoArray;
      }
    }
    if (route.type === 'event') {
      let { data: event, error } = await supabase
        .from('events')
        .select()
        .eq('id', route.event_id)
        .single();

      if (event) {
        let markerInfoArray = getEventMapMarker(route, event);
        route.markerInfoArray = markerInfoArray;
      }
    }

    if (route.type === 'field_trip') {
      let { data: fieldTrip, error } = await supabase
        .from('field_trips')
        .select()
        .eq('id', route.field_trip_id)
        .single();

      if (fieldTrip) {
        let markerInfoArray = getEventMapMarker(route, fieldTrip);
        route.markerInfoArray = markerInfoArray;
      }
    }
  };

  useEffect( () => {
    let currentRoutes = routes.filter( (route: any) => {
      return route.in_progress;
    });

    if (currentRoutes.length > 0) {
      currentRoutes.forEach((route: any, index: number) => {
        addMarkerArray(route);
        route.color = colors[index];
      });
      setRoutesInProgress(currentRoutes);
    } else {
      setNoCurrentRoutes(true);
    }
  }, []);

  return (
    <div className='admin-app-parent' style={{marginTop: '5em', textAlign: 'center'}}>
      <h1>Trips In Progress</h1>
      {noCurrentRoutes && <p>No trips currently in progress</p>}
      {routesInProgress && (
        <MultiMap routes={routesInProgress} tableNames={tableNames} districtInfo={districtInfo}/>
      )}
    </div>
  );
}

export default AdminLiveMap
