import { Modal, Box, Button } from '@mui/material';

type SuccessModalProps = {
  open: boolean;
  onClose: () => void;
  text: string;
}

// reusable success modal takes state, method to close, and text to display

const SuccessModal = ({ open, onClose, text }: SuccessModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
        }}
        textAlign="center"
      >
        <p>{text}</p>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ marginBottom: '1em' }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default SuccessModal
