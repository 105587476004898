import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { supabase } from '../../db/client';
import { IRoute, IDriver, IStudent } from '../../ts/interfaces';

import {
  Paper,
  Button,
  Modal,
  Box,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { StaticMap } from '../../components';

import { sub, add, format, intervalToDuration } from 'date-fns';

import {
  getStudentInfoFromRoute,
  createWaypointsArray,
  getMapMarkerInfo,
  getEventMapMarker,
  getRouteName,
} from '../../utils/routeDataHandlers';

import {
  generateUrlForGoogleMapsAdmin,
  generateUrlForGoogleMapsAdminEvent,
} from '../../utils/generateUrlForGoogleMaps';

import {
  formatDate,
  formatTime,
  getExactStart,
  dateObjToUTCTime,
} from '../../utils/dateTimeHelpers';

import { updateDriverByEmail } from '../../utils/sendGridMethods';
import {
  directionsCall,
  distanceMatrixCall,
  metersToMiles,
  streetAddressToLatLong,
} from '../../utils/geolocation';

type ReportChecklistProps = {
  checklist: any;
};

// if reports are stored for this route, they are pulled and displayed and they can be marked resolved
const ReportChecklist = ({ checklist }: ReportChecklistProps) => {
  console.log(checklist);

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>Checklist</h3>
      {checklist.map((checklistItem: any) => {
        return (
          <div
            style={{ display: 'block', marginLeft: '1em' }}
            key={checklistItem.item.text}
          >
            <FormControlLabel
              control={<Checkbox checked={checklistItem.checked} />}
              label={checklistItem.item.text}
            />
          </div>
        );
      })}
    </>
  );
};

type ReportBlockProps = {
  reports: any;
  tableNames: any;
  updateReports: (reportID: number) => void;
};

const ReportBlock = ({
  reports,
  tableNames,
  updateReports,
}: ReportBlockProps) => {
  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Reports</h2>
      {reports.map((report: any) => {
        return (
          <ReportCard
            report={report}
            tableNames={tableNames}
            updateReports={updateReports}
            key={report.id}
          />
        );
      })}
    </>
  );
};

type ReportCardProps = {
  report: any;
  tableNames: any;
  updateReports: (reportID: number) => void;
};

const ReportCard = ({ report, tableNames, updateReports }: ReportCardProps) => {
  const resolveReport = async () => {
    const { data, error } = await supabase
      .from(tableNames.reports)
      .update({ resolved: true })
      .eq('id', report.id);

    console.log('response from update report', data || error);

    if (data) {
      updateReports(report.route);
    }
  };
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: '10px',
        textAlign: 'left',
        outline: '1px solid grey',
        padding: '.5em',
        margin: '.5em',
      }}
    >
      {report.resolved && (
        <p style={{ color: 'green', textAlign: 'center', fontWeight: 'bold' }}>
          Resolved
        </p>
      )}
      <p style={{ marginLeft: '1em' }}>
        <b>Message: </b>
        {report.message}
      </p>
      <ReportChecklist checklist={report.checklist} />
      {!report.resolved && (
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={resolveReport}>
            Mark Resolved
          </Button>
        </Box>
      )}
    </Paper>
  );
};

type AdminSingleRouteProps = {
  routes: IRoute[];
  drivers: IDriver[];
  districtTimes: any;
  vehicles: any;
  tableNames: any;
  districtInfo: any;
  updateAllRoutes: () => void;
};

// single route page has become very cumbersome, from amount of data pulled and interactivity, should be broken out further
// as of 11/3/22 uses google maps apis to recalculate route metrics when changes are made - errors out on large routes
// needs major work in refactoring how we are defining and storing route data and then changing front end to match

const AdminSingleRoute = ({
  routes,
  drivers,
  vehicles,
  districtTimes,
  tableNames,
  updateAllRoutes,
  districtInfo,
}: AdminSingleRouteProps) => {
  const [currentRoute, setCurrentRoute] = useState<any>();
  const [assignedDriver, setAssignedDriver] = useState<any>();
  const [assignedVehicle, setAssignedVehicle] = useState<any>();
  const [studentSelected, setStudentSelected] = useState<any>();

  const [studentInfo, setStudentInfo] = useState<IStudent[]>();
  const [waypointsArray, setWaypointsArray] = useState<number[][]>();
  const [markerArray, setMarkerArray] = useState<any>();
  const [routeName, setRouteName] = useState('');
  const [loading, setLoading] = useState(true);
  const [mapLoaded, setMapLoaded] = useState(false);

  // modals
  const [studentOpen, setStudentOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const [vehicleOpen, setVehicleOpen] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [startLocation, setStartLocation] = useState<number[]>();
  const [endLocation, setEndLocation] = useState<number[]>();

  const [eventInfo, setEventInfo] = useState<any>();
  const [fieldTripInfo, setFieldTripInfo] = useState<any>();
  const [reportInfo, setReportInfo] = useState<any>();

  const [addStudentModal, setAddStudentModal] = useState(false);
  const [allStudents, setAllStudents] = useState<any>();
  const [studentToAdd, setStudentToAdd] = useState<any>();
  const [studentToAddAddresses, setStudentToAddAddresses] = useState<any[]>();
  const [studentAddressSelected, setStudentAddressSelected] = useState<any>();

  // pull route id from params
  const routeID = useParams().id;
  const navigate = useNavigate();

  // all students pulled to be able to add them to route, order by last name
  const getAllStudents = async (districtID: any) => {
    let { data: students, error } = await supabase
      .from(tableNames.students)
      .select()
      .eq('district_id', districtID)
      .order('lname');

    if (students) {
      // store students in state
      setAllStudents(students);
      // set first student pulled as student selected, first students addresses, to set up for select component in add student modal
      setStudentToAdd(students[0]);
      setStudentToAddAddresses(getStudentAddresses(students[0]));
    }
  };

  // get district id from district info passed, based on district id on user profile
  useEffect(() => {
    if (districtInfo) {
      getAllStudents(districtInfo.id);
    }
  }, [districtInfo]);

  // if type of route is event, get event info and store in state
  const getEventInfo = async (eventID: number) => {
    let { data: event, error } = await supabase
      .from(tableNames.events)
      .select(
        `*, extracurricularInfo: ${tableNames.extracurriculars} ( title )`
      )
      .eq('id', eventID)
      .single();

    console.log('response from event call', event || error);

    if (event) {
      const thisRoute = routes.find((route: IRoute) => {
        return route.id === Number(routeID);
      });
      // get marker to pass to static map
      let markerInfoArray = getEventMapMarker(thisRoute, event);
      setMarkerArray(markerInfoArray);
      setEventInfo(event);
    }
  };

  // if type is field_trip, get field trip info
  const getFieldTripInfo = async (fieldTripID: number) => {
    let { data: fieldTrip, error } = await supabase
      .from(tableNames.field_trips)
      .select()
      .eq('id', fieldTripID)
      .single();

    console.log('response from field trip call', fieldTrip || error);

    if (fieldTrip) {
      const thisRoute = routes.find((route: IRoute) => {
        return route.id === Number(routeID);
      });
      let markerInfoArray = getEventMapMarker(thisRoute, fieldTrip);
      // get marker info to pass to static map
      setMarkerArray(markerInfoArray);

      setFieldTripInfo(fieldTrip);
    }
  };

  // check for reports on this route, if any set to state
  const getReportsInfo = async (routeID: number) => {
    let { data: reports, error } = await supabase
      .from(tableNames.reports)
      .select('*')
      .eq('route', routeID);

    console.log('response for reports', reports || error);

    if (reports && reports.length > 0) {
      setReportInfo(reports);
    }
  };

  useEffect(() => {
    const thisRoute = routes.find((route: IRoute) => {
      return route.id === Number(routeID);
    });

    console.log(thisRoute);

    let driverId = thisRoute?.driver_id;
    let thisDriver = drivers.find((driver: any) => {
      return driver.id === driverId;
    });

    let vehicleId = thisRoute?.vehicle_id;
    let thisVehicle = vehicles.find((vehicle: any) => {
      return vehicle.id === vehicleId;
    });

    if (thisRoute?.event_id) {
      getEventInfo(thisRoute?.event_id);
    }

    if (thisRoute?.field_trip_id) {
      getFieldTripInfo(thisRoute?.field_trip_id);
    }

    if (thisRoute?.in_progress || thisRoute?.complete) {
      getReportsInfo(thisRoute.id);
    }

    setCurrentRoute(thisRoute);
    setAssignedDriver(thisDriver);
    setAssignedVehicle(thisVehicle);
  }, []);

  useEffect(() => {
    // when current route assignedDriver and assignedVehicle are set, get rest of route details
    if (currentRoute && assignedDriver && assignedVehicle) {
      getRouteDetails(currentRoute);
      setLoading(false);
    }
  }, [currentRoute, assignedDriver, assignedVehicle]);

  useEffect(() => {
    if (markerArray && waypointsArray) {
      setMapLoaded(true);
    }
  }, [markerArray, waypointsArray]);

  const getRouteDetails = async (route: any) => {
    if (route.type === 'standard') {
      // if standard route get details for each student on route
      let studentInfoArray = await getStudentInfoFromRoute(
        route,
        tableNames.students
      );
      if (studentInfoArray) {
        // slice to just stops, all but last index of route.data array
        let dataArray = route.data.slice(0, -1);
        let arrayOfIds = dataArray.map((stop: any) => {
          return stop.student_id;
        });

        // order student details in same order as route.data
        let orderedStudentInfoArray: any = [];
        for (let i = 0; i < arrayOfIds.length; i++) {
          let matchingStudent = studentInfoArray.find((student: any) => {
            return student.id === arrayOfIds[i];
          });
          orderedStudentInfoArray.push(matchingStudent);
        }
        setStudentInfo(orderedStudentInfoArray);

        // get info for map markers from student info
        let markerInfoArray = getMapMarkerInfo(route, studentInfoArray);
        setMarkerArray(markerInfoArray);
      }
    }

    // set start and end position from last element of route.data array
    let startPosition = route.data.slice(-1)[0].start.lat_long;
    setStartLocation(startPosition);

    let endPosition = route.data.slice(-1)[0].end.lat_long;
    setEndLocation(endPosition);
    if (route.data[0].lat_long) {
      let waypointsArray = createWaypointsArray(route, startPosition);
      setWaypointsArray(waypointsArray);
    }

    // dynamic route name assigned by city name of addresses of stops
    let routeName = getRouteName(route);
    setRouteName(routeName);
  };

  const handleStudentOpen = (event: any) => {
    event.stopPropagation();
    let studentID =
      event.target.attributes.getNamedItem('data-studentid').value;

    let thisStudent = studentInfo?.find((student: IStudent) => {
      return student.id === Number(studentID);
    });

    setStudentSelected(thisStudent);
    setStudentOpen(true);
  };

  const handleStudentClose = () => {
    setStudentOpen(false);
    setStudentSelected(null);
  };

  const handleDriverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDriverOpen(true);
  };
  const handleDriverClose = () => setDriverOpen(false);

  const handleVehicleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVehicleOpen(true);
  };
  const handleVehicleClose = () => setVehicleOpen(false);

  // remove student from route
  const removeStudentHandler = () => {
    let currentRouteData = currentRoute?.data;
    let thisStudentInRouteData = currentRouteData?.find((stop: any) => {
      return stop.student_id === studentSelected.id;
    });

    // method to check if stop address is listed more than once - if so, stop count will not change
    const checkForDuplicateStreetAddress = (address: string) => {
      let filteredAddresses = currentRouteData?.filter((stop: any) => {
        return stop.street_address === address;
      });

      if (filteredAddresses.length === 1) return true;
      return false;
    };

    let stops = currentRoute.stop_count;
    // lower students count by one, only lower stops count by one if this student is only one at address
    let students = currentRoute.students_count - 1;
    if (checkForDuplicateStreetAddress(thisStudentInRouteData.street_address)) {
      stops--;
    }

    // remove student from route data and student info array
    let filteredRouteData = currentRouteData?.filter((stop: any) => {
      return stop.student_id !== studentSelected.id;
    });

    let filteredStudentInfo = studentInfo?.filter((student: any) => {
      return student.id !== studentSelected.id;
    });

    setStudentInfo(filteredStudentInfo);

    let updatedRoute = {
      ...currentRoute,
      data: filteredRouteData,
      stop_count: stops,
      students_count: students,
    };

    setCurrentRoute(updatedRoute);
    // add student back to all students since they could now be added back to the route
    setAllStudents([...allStudents, studentSelected]);
    handleStudentClose();
  };

  const handleDriverChange = (event: SelectChangeEvent) => {
    let selectedDriver = drivers.find((driver: any) => {
      return driver.id === event.target.value;
    });

    setAssignedDriver(selectedDriver);
  };

  const handleVehicleChange = (event: SelectChangeEvent) => {
    let selectedVehicle = vehicles.find((vehicle: any) => {
      return vehicle.id === event.target.value;
    });

    setAssignedVehicle(selectedVehicle);
  };

  // when student is selected, set that student to state and grab their possible addresses to be selectable
  const handleStudentChange = (event: SelectChangeEvent) => {
    let thisStudent = allStudents.find((student: any) => {
      return student.id === event.target.value;
    });

    setStudentToAdd(thisStudent);
    let studentAddressList = getStudentAddresses(thisStudent);

    setStudentToAddAddresses(studentAddressList);
    setStudentAddressSelected(studentAddressList[0]);
  };

  // when selected students address is selected, set to state
  const handleStudentAddressChange = (event: SelectChangeEvent) => {
    let thisAddress = studentToAddAddresses?.find((address: string) => {
      return address === event.target.value;
    });

    setStudentAddressSelected(thisAddress);
  };

  const updateRoute = async () => {
    let newDistance = currentRoute.distance;
    let newEstimatedTime = currentRoute.est_duration;
    let newCumulative: number | null = currentRoute.cumulative_rider_time;
    let newLongest: number | null = currentRoute.longest_rider_time;

    // get metrics back from Google Maps Directions API
    let routeMetrics = await directionsCallHandler();
    console.log(routeMetrics);
    if (routeMetrics) {
      // convert distance to miles
      newDistance = Number(metersToMiles(routeMetrics.totalDistanceInMeters));
      // convert seconds to minutes
      newEstimatedTime = Number(
        (routeMetrics.totalDurationInSeconds / 60).toFixed(0)
      );
      newCumulative = Number(
        (routeMetrics.cumulativeRiderTimeInSeconds / 60).toFixed(0)
      );
      newLongest = Number(
        (routeMetrics.longestRiderTimeInSeconds / 60).toFixed(0)
      );
    }

    // start with currently stored times
    let newStartTime = currentRoute.start_time;
    let newEndTime = currentRoute.end_time;

    // get exact time am routes and and pm routes start based on times set per district (will need to change to time per school)
    let amRouteEnd = getAmEndTime(districtTimes);
    let pmRouteStart = getPmStartTime(districtTimes);

    if (currentRoute.type === 'standard') {
      // if am route
      if (currentRoute.end_time === amRouteEnd) {
        // convert to JS Date object
        let amEndAsDate = getExactStart(currentRoute.date, amRouteEnd);
        // subtract estimated time from end time
        let adjustedStartTime = sub(amEndAsDate, {
          minutes: newEstimatedTime,
        });
        // convert to format needed for supabase
        let isoStartTime = adjustedStartTime.toISOString();
        let timeFormatted = new Date(isoStartTime as string).toLocaleTimeString(
          'en',
          {
            timeStyle: 'short',
            hour12: false,
            timeZone: 'UTC',
          }
        );
        newStartTime = timeFormatted += ':00';
      }

      // if pm route, adjust end time of route based on when pm routes start and add estimated duration
      if (currentRoute.start_time === pmRouteStart) {
        let pmStartAsDate = getExactStart(currentRoute.date, pmRouteStart);
        let adjustedEndTime = add(pmStartAsDate, { minutes: newEstimatedTime });
        let isoEndTime = adjustedEndTime.toISOString();
        let timeFormatted = new Date(isoEndTime as string).toLocaleTimeString(
          'en',
          {
            timeStyle: 'short',
            hour12: false,
            timeZone: 'UTC',
          }
        );
        newEndTime = timeFormatted += ':00';
      }

      // if start and end times don't match am or pm start, TBD basically
      if (
        currentRoute.start_time !== pmRouteStart &&
        currentRoute.end_time !== amRouteEnd
      )
        console.log('not am or pm');
    }

    // if not a standard route don't set cumulative or longest rider time
    if (currentRoute.type !== 'standard') {
      newCumulative = null;
      newLongest = null;
    }

    let arrayOfIds = studentInfo?.map((student: any) => {
      return student.id;
    });

    // reconstruct data field based on any changes user has made to students on route
    let newDataField: any[] = [];

    if (currentRoute.type === 'standard') {
      for (let i = 0; i < currentRoute.data.length; i++) {
        if (arrayOfIds?.includes(currentRoute.data[i].student_id)) {
          newDataField.push(currentRoute.data[i]);
        }
        if (i === currentRoute.data.length - 1) {
          newDataField.push(currentRoute.data[i]);
        }
      }
    } else {
      newDataField = currentRoute.data;
    }

    // method intended to ensure route data is sorted in a way that will clump students with same stop address together, 
    // as of 11/3/22 probably still buggy but this all needs to change in the database so will not be relevant

    const orderRouteData = (routeData: any) => {
      let copy = [...routeData];
      for (let i = 0; i < routeData.length; i++) {
        let thisStop = routeData[i];

        let thisAddress = thisStop.street_address;

        let firstIndexOfThisAddress = routeData.findIndex((route: any) => {
          return thisAddress === route.street_address;
        });

        if (firstIndexOfThisAddress !== i) {
          copy.splice(firstIndexOfThisAddress, 0, thisStop);
        }
      }

      for (let i = 0; i < copy.length; i++) {
        let firstIndex = copy.indexOf(copy[i]);
        if (firstIndex !== i && firstIndex !== -1) {
          delete copy[i];
        }
      }

      let removeNull = copy.filter((item: any) => {
        return item !== null;
      });
      return removeNull;
    };

    newDataField = orderRouteData(newDataField);

    let updatedRouteData = {
      driver_id: assignedDriver?.id,
      stop_count: currentRoute.stop_count,
      students_count: currentRoute.students_count,
      vehicle_id: assignedVehicle.id,
      data: newDataField,
      distance: newDistance,
      cumulative_rider_time: newCumulative,
      longest_rider_time: newLongest,
      est_duration: newEstimatedTime,
      start_time: newStartTime,
      end_time: newEndTime,
    };

    const { data, error } = await supabase
      .from(tableNames.routes)
      .update(updatedRouteData)
      .eq('id', routeID);

    console.log('updated route returned from sb', data || error);

    if (data) {
      let response = await updateDriverByEmail(currentRoute, assignedDriver);
      console.log(response);
      setSuccess(true);
      setCurrentRoute(data[0]);
      updateAllRoutes();
    }
    if (error) setError(true);
  };

  // takes district times, returns js date object at exact time am routes should end
  const getAmEndTime = (districtTimes: any) => {
    let schoolStart = getExactStart(
      currentRoute.date,
      districtTimes.start_time
    );
    let localEndTime = sub(schoolStart, { minutes: districtTimes.am_buffer });
    return dateObjToUTCTime(localEndTime);
  };

  // takes district times, returns js date object at exact time pm routes should start
  const getPmStartTime = (districtTimes: any) => {
    let schoolEnd = getExactStart(currentRoute.date, districtTimes.end_time);
    let localStartTime = add(schoolEnd, { minutes: districtTimes.pm_buffer });
    return dateObjToUTCTime(localStartTime);
  };

  // takes an end time for a route and returns true if comparison indicates it is an am route
  const isAm = (endTime: any) => {
    let amRouteEnd = getAmEndTime(districtTimes);
    if (endTime === amRouteEnd) return true;
    return false;
  };

  // makes call to google maps directions api, handles output to attempt to accurately recalculate route metrics based on any changes made
  const directionsCallHandler = async () => {
    let response = await directionsCall(currentRoute.data);
    console.log(response);
    // check if am route, stored differently based on am or pm
    let amTrue = isAm(currentRoute.end_time);
    // check that response was successful before continuing, much potential for errors that needs further exploration
    if (response.status === 'OK') {
      if (currentRoute.type === 'standard') {
        // pull legs, set inital value for each metric
        let routeLegsArray = response.routes[0].legs;
        let totalDistanceInMeters = 0;
        // as of 11/3/2022 hardcoded 2 minutes per stop added to total duration
        let totalDurationInSeconds = 120 * (routeLegsArray.length - 1);
        let longestRiderTimeInSeconds = 0;
        let cumulativeRiderTimeInSeconds = 0;

        let studentsRiding = 0;
        // only way to calculate cumulative and longest as of 11/3/22 assumes am routes start empty and end full && pm routes start full and end empty - often won't be true
        if (!amTrue) studentsRiding = currentRoute.data.length - 1;
        // loop through legs array, add distance and duration per leg to total
        routeLegsArray.forEach((route: any, index: number) => {
          totalDistanceInMeters += route.distance.value;
          totalDurationInSeconds += route.duration.value;
          if (amTrue) {
            cumulativeRiderTimeInSeconds =
              cumulativeRiderTimeInSeconds +
              route.duration.value * studentsRiding;
            if (index === 1) {
              cumulativeRiderTimeInSeconds = cumulativeRiderTimeInSeconds + 120;
            }
            if (index > 1 && index < routeLegsArray.length - 1) {
              cumulativeRiderTimeInSeconds =
                cumulativeRiderTimeInSeconds + 120 * studentsRiding;
            }

            // track number of students at each stop to accurately total cumulative time
            let studentsAtStop = currentRoute.data.filter((stop: any) => {
              if (stop.street_address) {
                return (
                  stop.street_address.split(',')[0] ===
                  route.end_address.split(',')[0]
                );
              }
            });
            studentsRiding += studentsAtStop.length;
          }

          if (!amTrue && index < routeLegsArray.length) {
            cumulativeRiderTimeInSeconds =
              cumulativeRiderTimeInSeconds +
              route.duration.value * studentsRiding;

            let studentsAtStop = currentRoute.data.filter((stop: any) => {
              if (stop.street_address) {
                return (
                  stop.street_address.split(',')[0] ===
                  route.end_address.split(',')[0]
                );
              }
            });
            studentsRiding -= studentsAtStop.length;

            if (index < routeLegsArray.length - 2) {
              cumulativeRiderTimeInSeconds += 120 * studentsRiding;
            }
          }
        });

        // if am, longest rider is first student to board, if pm, longest rider is last to be dropped - again not accurate in complex routes
        if (amTrue) {
          longestRiderTimeInSeconds =
            totalDurationInSeconds - routeLegsArray[0].duration.value;
        }
        if (!amTrue) {
          longestRiderTimeInSeconds =
            totalDurationInSeconds -
            routeLegsArray[routeLegsArray.length - 1].duration.value;
        }

        return {
          totalDistanceInMeters,
          totalDurationInSeconds,
          cumulativeRiderTimeInSeconds,
          longestRiderTimeInSeconds,
        };
      }
      // if NOT standard, route will be to one destination and back
      if (currentRoute.type !== 'standard') {
        let oneWay = response.routes[0].legs[0];
        let totalDistanceInMeters = oneWay.distance.value;
        let totalDurationInSeconds = oneWay.duration.value;
        let longestRiderTimeInSeconds = oneWay.duration.value * 2;
        let cumulativeRiderTimeInSeconds = oneWay.duration.value * 2;

        return {
          totalDistanceInMeters,
          totalDurationInSeconds,
          cumulativeRiderTimeInSeconds,
          longestRiderTimeInSeconds,
        };
      }
    }
  };

  const handleAddStudent = async () => {
    if (studentToAdd && studentAddressSelected) {
      let currentRouteData = currentRoute.data;

      // get lat long from student address selected
      const newStudentLatLong = await streetAddressToLatLong(
        studentAddressSelected
      );

      let indexOfNewAddress = -1;

      // check if that address is already among stops
      for (let i = 0; i < currentRouteData.length; i++) {
        if (currentRouteData[i].street_address === studentAddressSelected) {
          indexOfNewAddress = i;
        }
      }

      // if it is, splice the data field to drop added student next to existing students at that address
      if (indexOfNewAddress >= 0) {
        currentRouteData.splice(indexOfNewAddress, 0, {
          street_address: studentAddressSelected,
          student_id: studentToAdd.id,
          lat_long: newStudentLatLong,
        });
      } else {
        // if not add to end of student array
        currentRouteData.splice(currentRoute.data.length - 1, 0, {
          street_address: studentAddressSelected,
          student_id: studentToAdd.id,
          lat_long: newStudentLatLong,
        });
      }

      // similar logic to add this student to student info array, separate from route data field
      let newStudentInfoArray: any = studentInfo;
      if (indexOfNewAddress === -1) {
        newStudentInfoArray = [...newStudentInfoArray, studentToAdd];
      } else {
        newStudentInfoArray.splice(indexOfNewAddress, 0, studentToAdd);
      }

      const checkForDuplicateStreetAddress = (address: string) => {
        let filteredAddresses = currentRouteData?.filter((stop: any) => {
          return stop.street_address === address;
        });

        if (filteredAddresses.length === 1) return true;
        return false;
      };

      let stops = currentRoute.stop_count;

      if (checkForDuplicateStreetAddress(studentAddressSelected)) stops++;

      let currentRouteCopy = {
        ...currentRoute,
        data: currentRouteData,
        students_count: currentRoute.students_count + 1,
        stop_count: stops,
      };

      setStudentInfo(newStudentInfoArray);
      setCurrentRoute(currentRouteCopy);
      setAddStudentModal(false);
      setStudentToAdd(null);
      setStudentAddressSelected(null);
      getRouteDetails(currentRouteCopy);
    }
  };

  // takes student, returns unique addresses among transportation address fields and baseline_addresses as options for where to pick up that student
  const getStudentAddresses = (student: any) => {
    let addressArray: any[] = [];

    let baselineAddresses = student.baseline_addresses;
    for (let i = 0; i < baselineAddresses.length; i++) {
      if (
        !addressArray.includes(baselineAddresses[i].am) &&
        baselineAddresses[i].am
      ) {
        addressArray.push(baselineAddresses[i].am);
      }
      if (
        !addressArray.includes(baselineAddresses[i].pm) &&
        baselineAddresses[i].pm
      ) {
        addressArray.push(baselineAddresses[i].pm);
      }
    }

    if (
      !addressArray.includes(student.transportation_address1) &&
      student.transportation_address1
    ) {
      addressArray.push(student.transportation_address1);
    }

    if (
      !addressArray.includes(student.transportation_address2) &&
      student.transportation_address2
    ) {
      addressArray.push(student.transportation_address2);
    }
    return addressArray;
  };

  const openAddStudentModal = () => {
    let arrayOfIdsOnRoute = studentInfo?.map((student: any) => {
      return student.id;
    });

    let allStudentsFiltered = allStudents.filter((student: any) => {
      return !arrayOfIdsOnRoute?.includes(student.id);
    });

    setAllStudents(allStudentsFiltered);
    setAddStudentModal(true);
  };

  useEffect(() => {
    console.log('current route has changed');
  }, [currentRoute]);

  // moves student up in stop order
  const handleUp = (event: any) => {
    // stop propagation prevents redirect to student record when other buttons are clicked
    event.stopPropagation();

    let newStudentInfoArray: any = studentInfo;
    let currentRouteData = currentRoute.data;

    let studentID =
      event.target.attributes.getNamedItem('data-studentid').value;

    let indexOfThisStudent = -1;
    let thisStudent = newStudentInfoArray?.find(
      (student: any, index: number) => {
        if (student.id === Number(studentID)) {
          indexOfThisStudent = index;
        }
        return student.id === Number(studentID);
      }
    );

    let thisStudentInRouteData = currentRouteData?.find((stop: any) => {
      return stop.student_id === Number(studentID);
    });

    newStudentInfoArray[indexOfThisStudent] =
      newStudentInfoArray[indexOfThisStudent - 1];
    newStudentInfoArray[indexOfThisStudent - 1] = thisStudent;

    currentRouteData[indexOfThisStudent] =
      currentRouteData[indexOfThisStudent - 1];
    currentRouteData[indexOfThisStudent - 1] = thisStudentInRouteData;

    setStudentInfo(newStudentInfoArray);
    setCurrentRoute({
      ...currentRoute,
      data: currentRouteData,
    });
  };

  // moves student down in stop order
  const handleDown = (event: any) => {
    event.stopPropagation();

    let newStudentInfoArray: any = studentInfo;
    let currentRouteData = currentRoute.data;

    let studentID =
      event.target.attributes.getNamedItem('data-studentid').value;

    let indexOfThisStudent = -1;
    let thisStudent = newStudentInfoArray?.find(
      (student: any, index: number) => {
        if (student.id === Number(studentID)) {
          indexOfThisStudent = index;
        }
        return student.id === Number(studentID);
      }
    );

    let thisStudentInRouteData = currentRouteData?.find((stop: any) => {
      return stop.student_id === Number(studentID);
    });

    newStudentInfoArray[indexOfThisStudent] =
      newStudentInfoArray[indexOfThisStudent + 1];
    newStudentInfoArray[indexOfThisStudent + 1] = thisStudent;

    currentRouteData[indexOfThisStudent] =
      currentRouteData[indexOfThisStudent + 1];
    currentRouteData[indexOfThisStudent + 1] = thisStudentInRouteData;

    setStudentInfo(newStudentInfoArray);
    setCurrentRoute({
      ...currentRoute,
      data: currentRouteData,
    });
  };

  return (
    <div className="admin-app-parent">
      {currentRoute && routeName && assignedDriver && (
        <div style={{ marginTop: '5em', marginLeft: 0 }}>
          <h2 style={{ textAlign: 'center' }}>Route ID: {currentRoute.id}</h2>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{routeName}</p>

          <h3 style={{ textAlign: 'center' }}>
            {formatDate(
              getExactStart(currentRoute.date, currentRoute.start_time)
            )}
          </h3>
          {eventInfo && (
            <p
              style={{
                color: 'green',
                margin: '0 auto',
                outline: '1px solid green',
                width: 'fit-content',
                padding: '.5em',
              }}
            >
              EVENT
            </p>
          )}
          {fieldTripInfo && (
            <p
              style={{
                color: 'green',
                margin: '0 auto',
                outline: '1px solid green',
                width: 'fit-content',
                padding: '.5em',
              }}
            >
              FIELD TRIP
            </p>
          )}
          {(currentRoute.in_progress || currentRoute.complete) && (
            <Box sx={{ textAlign: 'center' }}>
              <Paper
                elevation={2}
                sx={{
                  width: 'fit-content',
                  padding: '2em',
                  margin: '0 auto',
                }}
              >
                <p
                  style={{
                    color: currentRoute.complete ? 'green' : 'red',
                    textAlign: 'center',
                    marginTop: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {currentRoute.complete ? 'COMPLETE' : 'IN PROGRESS'}
                </p>
                <p>
                  Navigation Start:{' '}
                  {format(
                    new Date(currentRoute.navigation_start + 'Z'),
                    'h:mm a'
                  )}
                </p>
                {currentRoute.complete && (
                  <>
                    <p>
                      Navigation End:{' '}
                      {format(
                        new Date(currentRoute.navigation_end + 'Z'),
                        'h:mm a'
                      )}
                    </p>
                    <p>
                      Actual Duration:{' '}
                      {
                        intervalToDuration({
                          start: new Date(currentRoute.navigation_start),
                          end: new Date(currentRoute.navigation_end),
                        }).minutes
                      }{' '}
                      minutes
                    </p>
                  </>
                )}
              </Paper>
            </Box>
          )}
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            <a
              href={
                currentRoute.type === 'standard'
                  ? generateUrlForGoogleMapsAdmin(currentRoute.data)
                  : generateUrlForGoogleMapsAdminEvent(currentRoute.data)
              }
              style={{
                textDecoration: 'underline',
                color: 'blue',
                margin: '0 auto',
              }}
              target="_blank"
              rel="noreferrer"
            >
              Navigation Preview
            </a>
          </p>
          {reportInfo && (
            <ReportBlock
              reports={reportInfo}
              tableNames={tableNames}
              updateReports={getReportsInfo}
            />
          )}
          <Paper
            elevation={5}
            className="route-details"
            sx={{
              borderRadius: '30px',
              textAlign: 'left',
            }}
          >
            <Paper
              elevation={2}
              sx={{
                borderRadius: '10px',
                textAlign: 'left',
                outline: '1px solid grey',
                padding: '.5em',
                margin: '.5em',
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/admin/drivers/${assignedDriver.id}`)}
            >
              <Box
                sx={{
                  display: { xs: 'block', md: 'flex' },
                  justifyContent: { md: 'space-between' },
                  margin: 0,
                }}
              >
                <p style={{ marginLeft: 0 }}>
                  <b>Driver: </b>
                  {`${assignedDriver.fname} ${assignedDriver.lname}`} <b>ID:</b>{' '}
                  {assignedDriver.id}
                </p>
                <Box
                  sx={{
                    textAlign: { xs: 'center' },
                    display: { md: 'flex' },
                    alignItems: { md: 'center' },
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ marginRight: { md: '3em' } }}
                    onClick={handleDriverOpen}
                    disabled={currentRoute.in_progress || currentRoute.complete}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Paper>
            {driverOpen && (
              <Modal open={driverOpen} onClose={handleDriverClose}>
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    paddingInline: '1em',
                  }}
                >
                  <Box
                    textAlign="right"
                    sx={{ margin: 0 }}
                    onClick={handleDriverClose}
                  >
                    <Button
                      sx={{
                        fontSize: '1em',
                        marginTop: '.5em',
                        marginBottom: '.5em',
                      }}
                    >
                      X
                    </Button>
                  </Box>
                  <FormControl fullWidth sx={{ margin: '0 auto' }}>
                    <InputLabel id="driver-select">Driver</InputLabel>

                    <Select
                      labelId="driver-select"
                      id="driver-select-input"
                      value={assignedDriver.id}
                      label="Driver"
                      onChange={handleDriverChange}
                      sx={{ marginBottom: '2em' }}
                      MenuProps={{ color: 'red' }}
                    >
                      {drivers.map((driver: any) => {
                        return (
                          <MenuItem key={driver.id} value={driver.id}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <p>
                                {driver.fname} {driver.lname}
                              </p>
                              <p>ID: {driver.id}</p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Modal>
            )}
            <Paper
              elevation={2}
              sx={{
                borderRadius: '10px',
                textAlign: 'left',
                outline: '1px solid grey',
                padding: '.5em',
                margin: '.5em',
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/admin/vehicles/${assignedVehicle.id}`)}
            >
              <Box
                sx={{
                  display: { xs: 'block', md: 'flex' },
                  justifyContent: { md: 'space-between' },
                  margin: 0,
                }}
              >
                <p style={{ marginLeft: 0 }}>
                  <b>Vehicle: </b>
                  {assignedVehicle.identifier}
                  <b>ID:</b> {assignedVehicle.id}
                </p>
                <Box
                  sx={{
                    textAlign: { xs: 'center' },
                    display: { md: 'flex' },
                    alignItems: { md: 'center' },
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleVehicleOpen}
                    sx={{ marginRight: { md: '3em' } }}
                    disabled={currentRoute.in_progress || currentRoute.complete}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Paper>
            {vehicleOpen && (
              <Modal open={vehicleOpen} onClose={handleVehicleClose}>
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    paddingInline: '1em',
                  }}
                >
                  <Box
                    textAlign="right"
                    sx={{ margin: 0 }}
                    onClick={handleVehicleClose}
                  >
                    <Button
                      sx={{
                        fontSize: '1em',
                        marginTop: '.5em',
                        marginBottom: '.5em',
                      }}
                    >
                      X
                    </Button>
                  </Box>
                  <FormControl fullWidth sx={{ margin: '0 auto' }}>
                    <InputLabel id="vehicle-select">Vehicle</InputLabel>

                    <Select
                      labelId="vehicle-select"
                      id="vehicle-select-input"
                      value={assignedVehicle.id}
                      label="Vehicle"
                      onChange={handleVehicleChange}
                      sx={{ marginBottom: '2em' }}
                    >
                      {vehicles.map((vehicle: any) => {
                        return (
                          <MenuItem key={vehicle.id} value={vehicle.id}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <p>{vehicle.identifier}</p>
                              <p>ID: {vehicle.id}</p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Modal>
            )}
            {currentRoute.type !== 'standard' && (
              <p>
                <b>Destination Address: </b>
                {currentRoute.data[0].street_address}
              </p>
            )}
            <p>
              <b>Stops:</b> {currentRoute.stop_count}
            </p>
            <p>
              <b>
                {currentRoute.type === 'standard'
                  ? 'Students: '
                  : 'Total Passengers: '}
              </b>{' '}
              {currentRoute.students_count}
            </p>
            {currentRoute.type === 'standard' && (
              <Box style={{ textAlign: 'right' }}>
                <Button onClick={openAddStudentModal}>Add Student</Button>
              </Box>
            )}
            {addStudentModal && (
              <Modal
                open={addStudentModal}
                onClose={() => setAddStudentModal(false)}
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    paddingInline: '1em',
                    textAlign: 'center',
                  }}
                >
                  <FormControl fullWidth sx={{ margin: '1em auto' }}>
                    <InputLabel id="student-select">Student</InputLabel>

                    <Select
                      labelId="student-select"
                      id="student-select-input"
                      value={studentToAdd ? studentToAdd.id : null}
                      label="Student"
                      onChange={handleStudentChange}
                      sx={{ margin: '0' }}
                      MenuProps={{ color: 'red' }}
                    >
                      {allStudents.map((student: any) => {
                        return (
                          <MenuItem key={student.id} value={student.id}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <p>
                                {student.fname} {student.lname}
                              </p>
                              <p>ID: {student.id}</p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ margin: '1em auto' }}>
                    <InputLabel id="studentAddress-select">Address</InputLabel>

                    <Select
                      labelId="studentAddress-select"
                      id="studentAddress-select-input"
                      value={studentAddressSelected}
                      label="Student"
                      onChange={handleStudentAddressChange}
                      sx={{ margin: '0' }}
                      MenuProps={{ color: 'red' }}
                    >
                      {studentToAddAddresses &&
                        studentToAddAddresses.map(
                          (address: any, index: number) => {
                            return (
                              <MenuItem key={index} value={address}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <p>{address}</p>
                                </div>
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    sx={{ marginBottom: '1em' }}
                    onClick={handleAddStudent}
                  >
                    Add
                  </Button>
                </Box>
              </Modal>
            )}
            {studentInfo?.map((student: IStudent, index: number) => {
              return (
                <Paper
                  elevation={2}
                  sx={{
                    borderRadius: '10px',
                    textAlign: 'left',
                    outline: '1px solid grey',
                    padding: '.5em',
                    margin: '.5em',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/admin/students/${student.id}`)}
                  key={student.id}
                >
                  <Box
                    sx={{
                      display: { xs: 'block', md: 'flex' },
                      justifyContent: { md: 'space-between' },
                      margin: 0,
                    }}
                  >
                    <p style={{ margin: '0 0 0 1em' }}>
                      {student.fname} {student.lname}
                      <br />
                      Grade: {student.grade}
                      <br />
                      {currentRoute.data[index].street_address}
                    </p>
                    <Box
                      sx={{
                        textAlign: { xs: 'center' },
                        display: { md: 'flex' },
                        alignItems: { md: 'center' },
                      }}
                    >
                      <Box sx={{ width: '2em', marginRight: '1em' }}>
                        {index > 0 && (
                          <Button
                            data-studentid={student.id}
                            onClick={handleUp}
                          >
                            UP
                          </Button>
                        )}
                      </Box>
                      <Box sx={{ width: '2em', marginRight: '2em' }}>
                        {index < studentInfo.length - 1 && (
                          <Button
                            data-studentid={student.id}
                            onClick={handleDown}
                          >
                            DOWN
                          </Button>
                        )}
                      </Box>
                      {studentInfo.length > 1 && (
                        <Button
                          variant="contained"
                          onClick={handleStudentOpen}
                          data-studentid={student.id}
                          sx={{
                            marginRight: '3em',
                            backgroundColor: 'red',
                            ':hover': {
                              bgcolor: 'maroon',
                              color: 'white',
                            },
                          }}
                          disabled={
                            currentRoute.in_progress || currentRoute.complete
                          }
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Paper>
              );
            })}
            {studentOpen && (
              <Modal open={studentOpen} onClose={handleStudentClose}>
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    paddingInline: '1em',
                  }}
                  textAlign="center"
                >
                  <Box
                    textAlign="right"
                    sx={{ margin: 0 }}
                    onClick={handleStudentClose}
                  >
                    <Button
                      sx={{
                        fontSize: '1em',
                        marginTop: '.5em',
                        marginBottom: '.5em',
                      }}
                    >
                      X
                    </Button>
                  </Box>
                  <p style={{ marginTop: 0 }}>
                    Are you sure you would like to remove{' '}
                    <b>
                      {studentSelected.fname} {studentSelected.lname}
                    </b>{' '}
                    from this trip?
                  </p>
                  <Button
                    variant="contained"
                    onClick={removeStudentHandler}
                    sx={{ marginBottom: '1em' }}
                  >
                    Yes
                  </Button>
                </Box>
              </Modal>
            )}
            <p>
              <b>Start Time: </b>
              {formatTime(
                getExactStart(currentRoute.date, currentRoute.start_time)
              )}
            </p>
            <p>
              <b>
                {currentRoute.type === 'standard'
                  ? 'End Time: '
                  : 'Estimated Return Time: '}
              </b>
              {currentRoute.end_time
                ? formatTime(
                    getExactStart(currentRoute.date, currentRoute.end_time)
                  )
                : 'Not set'}
            </p>
            <p>
              <b>Estimated Duration: </b>
              {currentRoute.est_duration} minutes
            </p>

            <p>
              <b>Total Distance: </b>
              {currentRoute.distance} mi
            </p>
          </Paper>
          {eventInfo && (
            <Box sx={{ textAlign: 'center' }}>
              <h3
                style={{
                  fontSize: '2em',
                  textDecoration: 'underline',
                  marginBottom: 0,
                }}
              >
                Event Info
              </h3>
              <Button
                style={{ fontSize: '1.5em', cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/admin/sports/${eventInfo.extracurricular_id}`)
                }
              >
                {eventInfo.extracurricularInfo.title}
              </Button>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: '10px',
                  textAlign: 'left',
                  outline: '1px solid grey',
                  padding: '.5em',
                  margin: '.5em',
                }}
              >
                <p>
                  <b>{eventInfo.title}</b>
                </p>
                <p>
                  <b>Event Start Time: </b>
                  {formatTime(
                    getExactStart(eventInfo.date, eventInfo.start_time)
                  )}
                </p>
                <p>
                  <b>Event End Time: </b>
                  {eventInfo.end_time
                    ? formatTime(
                        getExactStart(eventInfo.date, eventInfo.end_time)
                      )
                    : 'Not set'}
                </p>
                <p>
                  <b>{eventInfo.arrive_by ? 'Arrive by: ' : 'Depart at: '}</b>
                  {eventInfo.route_time
                    ? formatTime(
                        getExactStart(eventInfo.date, eventInfo.route_time)
                      )
                    : 'Not set'}
                </p>
                <p>
                  <b>Location: </b>
                  {eventInfo.location}
                </p>
                <p>
                  <b>Stop Instructions: </b>
                  {eventInfo.stop_instructions}
                </p>
              </Paper>
            </Box>
          )}
          {fieldTripInfo && (
            <Box sx={{ textAlign: 'center' }}>
              <h3
                style={{
                  fontSize: '2em',
                  textDecoration: 'underline',
                  marginBottom: 0,
                }}
              >
                Field Trip Info
              </h3>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: '10px',
                  textAlign: 'left',
                  outline: '1px solid grey',
                  padding: '.5em',
                  margin: '.5em',
                }}
              >
                <p>
                  <b>{fieldTripInfo.title}</b>
                </p>
                <p>
                  <b>Start Time: </b>
                  {formatTime(
                    getExactStart(fieldTripInfo.date, fieldTripInfo.start_time)
                  )}
                </p>
                <p>
                  <b>End Time: </b>
                  {fieldTripInfo.end_time
                    ? formatTime(
                        getExactStart(
                          fieldTripInfo.date,
                          fieldTripInfo.end_time
                        )
                      )
                    : 'Not listed'}
                </p>
                <p>
                  <b>
                    {fieldTripInfo.arrive_by ? 'Arrive by: ' : 'Depart at: '}
                  </b>
                  {formatTime(
                    getExactStart(fieldTripInfo.date, fieldTripInfo.route_time)
                  )}
                </p>
                <p>
                  <b>Location: </b>
                  {fieldTripInfo.location}
                </p>
                <p>
                  <b>Stop Instructions: </b>
                  {fieldTripInfo.stop_instructions}
                </p>
                {fieldTripInfo.chaperones.length > 0 && (
                  <>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Chaperones
                    </p>
                    {fieldTripInfo.chaperones.map(
                      (chaperone: any, index: number) => {
                        return <p key={index}>{chaperone.name}</p>;
                      }
                    )}
                  </>
                )}
              </Paper>
            </Box>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={updateRoute}
              sx={{ margin: '2em' }}
              disabled={currentRoute.in_progress || currentRoute.complete}
            >
              Update Route
            </Button>
          </div>
          {mapLoaded && waypointsArray && startLocation && endLocation && (
            <Box
              sx={{
                p: { xs: 1 },
              }}
            >
              <StaticMap
                driverLocation={startLocation}
                waypointsArray={waypointsArray}
                markerArray={markerArray}
                destination={endLocation}
                vehicleType={assignedVehicle.model === 'bus' ? 'bus' : 'van'}
              />
            </Box>
          )}
          {success && (
            <Modal open={success} onClose={() => setSuccess(false)}>
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 1,
                }}
                textAlign="center"
              >
                <p>Trip Updated</p>
                <Button
                  variant="contained"
                  onClick={() => setSuccess(false)}
                  sx={{ marginBottom: '1em' }}
                >
                  Close
                </Button>
              </Box>
            </Modal>
          )}
          {error && (
            <Modal open={error} onClose={() => setError(false)}>
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 1,
                }}
                textAlign="center"
              >
                <p>Something went wrong...</p>
                <Button
                  variant="contained"
                  onClick={() => setError(false)}
                  sx={{ marginBottom: '1em' }}
                >
                  Close
                </Button>
              </Box>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminSingleRoute;
