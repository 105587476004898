import { useState, useEffect } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression, icon } from 'leaflet';

import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

import { Button, Checkbox, FormControlLabel } from '@mui/material';

import './MultiMap.css';

import { IStop } from '../../ts/interfaces';
import {
  createWaypointsArray,
  getStudentInfoFromRoute,
  getMapMarkerInfo,
} from '../../utils/routeDataHandlers';

import { formatDate, formatTime } from '../../utils/dateTimeHelpers';

// Static map cobbled together from leaflet, react-leaflet, and leaflet-routing-machine
// Buggy - dependent on OSRM demo server for drawing routes - requests periodically blocked - needs overhaul with new library

const busIcon = icon({
  iconUrl: '/bus.svg',
  iconSize: [30, 30],
});

const driverIcon = icon({
  iconUrl: '/driver.svg',
  iconSize: [30,30]
})

type StopComponentProps = {
  latLong: LatLngExpression;
  popupText?: string;
  markerIcon?: any;
  stopInfo?: any;
  stopNumber?: number;
  color?: string;
};

// component to render markers
const StopComponent = ({
  latLong,
  popupText,
  markerIcon,
  stopInfo,
  stopNumber,
  color,
}: StopComponentProps) => {

  // if no marker icon passed, use house icon for stop, render with stop information
  if (!markerIcon) {
    let stopIcon = icon({
      iconUrl: '/house-solid.svg',
      iconSize: [30, 30],
      className: `${color}Icon`,
    });

    return (
      <>
        <Marker position={latLong} icon={stopIcon}>
          {stopInfo && (
            <Tooltip
              direction="center"
              opacity={1}
              className={'multiMapTooltip'}
              permanent
            >
              {stopNumber && <>{stopNumber}</>}
            </Tooltip>
          )}

          <Popup>
            {stopInfo ? (
              <p>
                {stopInfo.title}
                {stopInfo.studentName}
                <br />
                {stopInfo.streetAddress}
                <br />
              </p>
            ) : (
              <>{popupText}</>
            )}
          </Popup>
        </Marker>
      </>
    );
  }

  return (
    <>
      <Marker position={latLong} icon={markerIcon}>
        {stopInfo && (
          <Tooltip
            direction="center"
            opacity={0}
            className={'tooltip'}
            permanent
          >
            {stopNumber && <>{stopNumber}</>}
          </Tooltip>
        )}

        <Popup>
          {stopInfo ? (
            <p>
              {stopInfo.studentName}
              <br />
              {stopInfo.streetAddress}
              <br />
            </p>
          ) : (
            <>{popupText}</>
          )}
        </Popup>
      </Marker>
    </>
  );
};

type RoutingLayerProps = {
  route: any;
  index: number;
};

// takes route data field, creates waypoints for each stop, determines route and renders on map
const RoutingLayer = ({ route, index }: RoutingLayerProps) => {
  const map = useMap();

  // format lat long from route data field
  const formatWaypointsArray = (arr: number[][]) => {
    let wayPointsArray = arr.map((stop: number[]) => {
      return L.latLng(stop[0], stop[1]);
    });

    return wayPointsArray;
  };

  let waypointsArray = createWaypointsArray(
    route,
    route.data.slice(-1)[0].start.lat_long
  );

  let fitSelectedRoutes = false;
  if (index === 0) fitSelectedRoutes = true;

  let formattedWaypoints = formatWaypointsArray(waypointsArray);

  const planOptions = {
    waypoints: formattedWaypoints,
    addWaypoints: false,
    draggableWaypoints: false,
    show: false,
    routeWhileDragging: false,
    fitSelectedRoutes,
    showAlternatives: false,
    lineOptions: {
      styles: [{ color: `${route.color}`, weight: 5 }],
      extendToWaypoints: true,
      missingRouteTolerance: 0,
      addWaypoints: false,
    },
    units: 'imperial',
    createMarker: function () {
      return null;
    },
  };

  // add routing layer to map
  const routingControl = L.Routing.control(planOptions).addTo(map);

  return null;
};

type MultiMapProps = {
  routes: any[];
  tableNames: any;
  districtInfo: any;
};

// Multimap used for in progress page and modal for each route block on a given day
const MultiMap = ({ routes, tableNames, districtInfo }: MultiMapProps) => {
  const [routeMarkerOptions, setRouteMarkerOptions] = useState<any[]>();
  const [routeMarkersVisible, setRouteMarkersVisible] = useState<any[]>();

  const [routeLineOptions, setRouteLineOptions] = useState<any[]>();
  const [routeLinesVisible, setRouteLinesVisible] = useState<any[]>();

  useEffect(() => {
    // default to hide markers
    let routeMarkerState = routes.map((route: any) => {
      return {
        id: route.id,
        visible: false,
      };
    });

    let routeLineState = routes.map( (route: any) => {
      return {
        id: route.id,
        visible: true
      }
    })
    setRouteMarkerOptions(routeMarkerState);
    setRouteLineOptions(routeLineState);
  }, []);

  useEffect(() => {
    if (routeMarkerOptions) {
      let filteredRoutes = routes.filter((route: any) => {
        let matchingOption = routeMarkerOptions.find((option: any) => {
          return route.id === option.id;
        });

        return matchingOption.visible;
      });

      console.log('Filtered routes by options', filteredRoutes);
      setRouteMarkersVisible(filteredRoutes);
    }
  }, [routeMarkerOptions]);

  useEffect(() => {
    // route layer is not removable with this set up :(
    if (routeLineOptions) {
      let filteredRoutes = routes.filter( (route: any) => {
        let matchingOption = routeLineOptions.find( (option: any) => {
          return route.id === option.id
        });

        return matchingOption.visible;
      });

      console.log('Filtered routes for route lines', filteredRoutes);
      setRouteLinesVisible(filteredRoutes);
    }
  }, [routeLineOptions])

  const showAll = () => {
    if (routeMarkerOptions) {
      let markerOptions = [...routeMarkerOptions];
      markerOptions.forEach((option: any) => (option.visible = true));
      setRouteMarkerOptions(markerOptions);
    }
    if (routeLineOptions) {
      let lineOptions = [...routeLineOptions];
      lineOptions.forEach( (option: any) => option.visible = true);
      setRouteLineOptions(lineOptions);
    }
  };

  const handleShowLines = (routeID: number) => {
    if (routeLineOptions && routeLineOptions.length) {
      let duplicate = [...routeLineOptions];
      let routeToChange = duplicate?.find( (option: any) => {
        return option.id === routeID;
      });
      let index = duplicate.indexOf(routeToChange);
      routeToChange.visible = !routeToChange.visible;
      duplicate[index] = routeToChange;
      setRouteLineOptions(duplicate);
    }
  }

  return (
    <>
      <div>
        <MapContainer
          center={
            routes[0].data.slice(-1)[0].start
              .lat_long as LatLngExpression
          }
          zoom={10}
          scrollWheelZoom={false}
          style={{ height: '80vh', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <StopComponent
            latLong={
              routes[0].data.slice(-1)[0].start.lat_long as LatLngExpression
            }
            popupText={'Depot'}
            markerIcon={busIcon}
          />
          {routeLinesVisible &&
            routeLinesVisible.map((route: any, index) => {

              return (
                <>
                  <RoutingLayer route={route} index={index} />
                  {/* if last location is stored on driver, display marker */}
                  {route.drivers.last_location && (
                    <Marker
                      position={
                        [
                          route.drivers.last_location.coords.latitude,
                          route.drivers.last_location.coords.longitude,
                        ] as LatLngExpression
                      }
                      icon={driverIcon}
                    >
                      <Tooltip
                        direction="center"
                        opacity={0}
                        className={'tooltip'}
                        permanent
                      >
                        anything
                      </Tooltip>

                      <Popup>
                        <p>
                          {route.drivers.fname} {route.drivers.lname}
                          <br />
                          As of{' '}
                          {formatTime(
                            new Date(route.drivers.last_location?.timestamp)
                          )}
                          <br />
                          {formatDate(
                            new Date(route.drivers.last_location?.timestamp)
                          )}
                        </p>
                      </Popup>
                    </Marker>
                  )}
                </>
              );
            })}
          {routeMarkersVisible &&
            routeMarkersVisible.map((route: any, index) => {
              return (
                <>
                  {route.markerInfoArray &&
                    route.markerInfoArray.map((stop: IStop, index: number) => {
                      return (
                        <StopComponent
                          latLong={stop.latLong}
                          key={stop.streetAddress}
                          // markerIcon={stopIcon}
                          stopInfo={stop}
                          stopNumber={index + 1}
                          color={route.color}
                        />
                      );
                    })}
                </>
              );
            })}
        </MapContainer>
        <Button onClick={showAll}>Show all</Button>
        {routeMarkerOptions &&
          routeMarkerOptions.map((option: any) => {
            let route = routes.find((route: any) => {
              return route.id === option.id;
            });
            let driverInfo = route.drivers || route.drivers_duplicate;
            return (
              <>
                {route && (
                  <>
                    <Button
                      onClick={() => handleShowLines(option.id)}
                      sx={{ color: route.color }}
                    >
                      {driverInfo.fname} {driverInfo.lname}
                    </Button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.visible}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            let duplicate = [...routeMarkerOptions];
                            let routeToChange = duplicate?.find(
                              (option: any) => {
                                return option.id === route.id;
                              }
                            );
                            let index = duplicate.indexOf(routeToChange);
                            routeToChange.visible = event.target.checked;
                            duplicate[index] = routeToChange;
                            setRouteMarkerOptions(duplicate);
                          }}
                        />
                      }
                      label={'Stops'}
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    </>
  );
};

export default MultiMap;
