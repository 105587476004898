import { format } from 'date-fns';

// takes date and time format from supabase and returns javascript date object for that exact time UTC
export const getExactStart = (date: string, time: string) => {
  let timeArray = time.split(':');
  let newTimeString = `${timeArray[0]}:${timeArray[1]}`;

  let dateArray = date.split('-').map((date: string) => {
    return Number(date);
  });

  let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  let formattedDate = format(newDate, 'MMMM d, yyyy');

  let dateStr = `${formattedDate} ${newTimeString} UTC`;
  let dateObj = new Date(dateStr);
  return dateObj;
};

// takes js date object and returns formatted time
export const formatTime = (dateObj: Date) => {
  let formattedTime = format(dateObj, 'p');
  return formattedTime;
};

// takes js date object and returns formatted date
export const formatDate = (dateObj: Date) => {
  let formattedDate = format(dateObj, 'EEEE MMMM do');
  return formattedDate;
};

// takes js date object and returns UTC time
export const dateObjToUTCTime = (dateObj: Date) => {
  let isoStartTime = dateObj.toISOString();
  let timeFormatted = new Date(isoStartTime as string).toLocaleTimeString(
    'en-GB',
    {
      timeStyle: 'short',
      hour12: false,
      timeZone: 'UTC',
    }
  );

  return timeFormatted += ':00';
}

// takes js date object and returns date format used for supabase
export const dateObjToSBFormat = (dateObj: Date) => {
    return (
      dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate()
    );
}