// these methods all involve calls to different Google Maps APIs - as of 11/3/22 we need to move away from google to another service
// maps api key from .env - visible in requests - restrict to fleet-lab domain or remove when not needed
const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY;

// basic fetch request sends street address with api key, returns an object with lat long for that address, this method returns lat long in array or error
export const streetAddressToLatLong = async (address: string) => {
  let response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${mapsApiKey}`
  );

  let json = await response.json();
  if (json.status === 'OK') {
    return [
      json.results[0].geometry.location.lat,
      json.results[0].geometry.location.lng,
    ];
  }
  return json.error_message;
};

// takes lat long number array - distance matrix takes two points and returns distance/time between
export const distanceMatrixCall =  async (origin: any, destination: any) => {
  let windowCopy: any = { ...window };
  // pulled from window - script tag in public/index.html makes this google object available on all pages
  // google object contains properties and methods for calls
  const { google } = windowCopy;

  // convert number array to maps latlng object
  const origin1 = new google.maps.LatLng(origin[0], origin[1]);
  const destinationA = new google.maps.LatLng(destination[0], destination[1]);

  // method to create service
  const service = new google.maps.DistanceMatrixService();
  // call to distance matrix api with necessary options
  let response = await service.getDistanceMatrix({
    origins: [origin1],
    destinations: [destinationA],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
  });

  // return entire response to handle where needed
  return response;
};

// maps api returns meters for all distances
export const metersToMiles = (meters: number) => {
  return (meters / 1609.34).toFixed(2);
};

// method to use directions api - limited to 23 waypoints so needs upgrade
export const directionsCall = async (routeData: any) => {
  let windowCopy: any = { ...window };
  const { google } = windowCopy;
  
  // similar to above - more than 23 waypoints will return error
  const directionsService = new google.maps.DirectionsService();
  const startLocation = routeData.slice(-1)[0].start.street_address;
  const endLocation = routeData.slice(-1)[0].end.street_address;

  let studentArray = routeData.slice(0, -1);

  let studentAddressArray = studentArray.map( (student: any) => {
    return student.street_address
  });

  // remove duplicate addresses in route data
  let filteredStudentAddresses = studentAddressArray.filter( (address: string, index: number) => {
    return studentAddressArray.indexOf(address) === index
  });

  // stopover option must be set to true - if false request is to drive by but not stop and will lead to routes that can't be calculated
  let formattedWaypoints = filteredStudentAddresses.map((address: any) => {
    return {
      location: address,
      stopover: true
    };
  });

  let request = {
    origin: startLocation,
    destination: endLocation,
    waypoints: formattedWaypoints,
    // if optimizeWaypoints is set to true google will attempt to reorder the route
    optimizeWaypoints: false,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  let response = await directionsService.route(request);
  // return entire response from google to handle elsewhere
  return response;
};

