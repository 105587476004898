import React, { useState, useEffect } from 'react';
import './BaseLineSchedule.css';

import { useAuth } from '../../contexts/Auth';
import { pushBaselineAvailabilityUpdate, findDriverBaselineAvailability } from '../../db/supabase-calls/driver_availability';

import {
  FormGroup,
  Button,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';

// custom components
import { Header, CheckBoxTimeSlot, DayRadioButton, UpdateScheduleModal } from '../../components'

// types
import { TDriverAvailabilityArray } from '../../ts/types'

const BaselineSchedule = () => {

  const { user } = useAuth();

  const [driverAvailability, setDriverAvailability] = useState<TDriverAvailabilityArray>([]);
  const [driverLoaded, setDriverLoaded] = useState(false);
  const [daySelected, setDaySelected] = useState('monday');
  const [pleaseRegister, setPleaseRegister] = useState(false)

  // modal controls
  const [modalState, setModalState] = useState(false);
  const handleOpen = () => setModalState(true);
  const handleClose = () => setModalState(false);

  useEffect(() => {
    // based on user id of driver, stored in user session
    const driverId = user.identities[0].id;
    const getDriver = async () => {
      let availArray = await findDriverBaselineAvailability(driverId);
      // if availArray not returned, display link to register
      if (!availArray) {
        setPleaseRegister(true);
      }
      setDriverAvailability(availArray);
      setDriverLoaded(true);
    };
    getDriver();
  }, []);

  const dayOptions = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const checkOptions = ['morning', 'midday', 'afternoon', 'evening'];

  // determines if time slot should be checked or not based on availability
  const checkValue = (timeslot: string) => {
    if (driverLoaded) {
      let currentDay = driverAvailability.filter(
        (day) => day.day === daySelected
      )[0];
      let value = currentDay[timeslot as keyof typeof currentDay];
      return value as boolean;
    }

    return false;
  };


  // switches day selected
  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaySelected(event.target.value);
  };

  // when check box clicked cycle through to change value and update state
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (driverLoaded) {
      let slot = event.target.value;
      let dayOfWeek = daySelected;
      let newAvailability = [...driverAvailability];
      let index = newAvailability.findIndex(day => {
        return day.day === dayOfWeek;
      });

      let newDay = {
        day: dayOfWeek,
        morning: newAvailability[index]['morning'],
        midday: newAvailability[index]['midday'],
        afternoon: newAvailability[index]['afternoon'],
        evening: newAvailability[index]['evening'],
      };

      if (slot === 'morning') {
        newDay.morning = !newAvailability[index].morning;
      } else if (slot === 'midday') {
        newDay.midday = !newAvailability[index].midday;
      } else if (slot === 'afternoon') {
        newDay.afternoon = !newAvailability[index].afternoon;
      } else if (slot === 'evening') {
        newDay.evening = !newAvailability[index].evening;
      }

      newAvailability[index] = newDay;
      setDriverAvailability(newAvailability);
    }
  };

  const setNewAvail = async () => {
    const driverId = user.identities[0].id;
    let response = await pushBaselineAvailabilityUpdate(driverId, driverAvailability);
    handleOpen();
  };

  return (
    <div className='driver-app-parent'>
      {!pleaseRegister ? (
        <>
          <Header />
          <FormControl onChange={dayChangeHandler}>
            <FormLabel id="baseline-form-label">
              Baseline Availability
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="form-control-label-placement"
              name="base-line-schedule"
              value={daySelected}
            >
              {dayOptions.map((day) => (
                <DayRadioButton day={day} key={day} />
              ))}
            </RadioGroup>
          </FormControl>
          <div
            style={{
              width: '200px',
              margin: '1em auto',
            }}
          >
            <FormGroup
              sx={{
                margin: '0 auto',
              }}
            >
              {checkOptions.map((timeSlot) => (
                <CheckBoxTimeSlot
                  timeSlot={timeSlot}
                  checkValue={checkValue(timeSlot)}
                  handleCheck={handleCheck}
                  key={timeSlot}
                ></CheckBoxTimeSlot>
              ))}
            </FormGroup>
          </div>
          <Button variant="contained" onClick={setNewAvail} color="secondary">
            Update Availability
          </Button>
          <UpdateScheduleModal
            modalState={modalState}
            handleClose={handleClose}
          ></UpdateScheduleModal>
        </>
      ) : (
        <p style={{ fontSize: '2.5em', marginTop: '2em' }}>
          Please{' '}
          <a
            href="/register"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Register
          </a>
        </p>
      )}
    </div>
  );
};

export default BaselineSchedule;