import { useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';

import { supabase } from '../../db/client';
import { useAuth } from '../../contexts/Auth';

import {
  generateUrlForGoogleMaps,
  generateUrlForGoogleMapsDriverEvent,
} from '../../utils/generateUrlForGoogleMaps';

import { Button } from '@mui/material';

import { IRoute } from '../../ts/interfaces';

const InProgress = () => {
  const id = useParams().id;
  const { user } = useAuth();
  const navigate = useNavigate();

  const [currentRoute, setCurrentRoute] = useState<IRoute>();
  const [mapsURL, setMapsUrl] = useState<string>();

  const getRoute = () => {
    // currently pulled from user context, all routes assigned pulled on log in
    let currentRouteFromUser = user.routes.filter((route: IRoute) => {
      return route.id === Number(id);
    })[0];
    // if user navigates to a single route page they are not assigned, route will not be in context and user will be redirected to home
    if (!currentRouteFromUser) {
      navigate('/');
      return;
    }

    setCurrentRoute(currentRouteFromUser);
    // get url for google maps navigation for standard or non standard route
    if (currentRouteFromUser.type === 'standard') {
      setMapsUrl(generateUrlForGoogleMaps(currentRouteFromUser.data));
    } else {
      setMapsUrl(generateUrlForGoogleMapsDriverEvent(currentRouteFromUser.data));
    }
  }

  useEffect( () => {
    if (user.routes) {
      getRoute();
    }
  }, [user])

  const postCheckHandler = async () => {
    // get time stamp, send to supabase as navigation end
    let timeStamp = new Date(Date.now()).toISOString();

    const { data, error } = await supabase
      .from('routes')
      .update({ navigation_end: timeStamp })
      .eq('id', id);

    console.log('response from send navigation_end', data || error)

    // take to post check page for route
    navigate(`/route/${id}/postcheck`);
  }

  return (
    <div className="driver-app-parent">
      <Header />
      {currentRoute && currentRoute.in_progress && (
        <>
          <h1 style={{ fontSize: '2em' }}>This route is in progress</h1>
          <p style={{marginBottom: '0'}}>Click here to </p> <br />
          <a href={mapsURL} target="__blank" style={{marginTop: '0'}}>
            <Button
              variant="contained"
              sx={{ marginTop: '0', marginBottom: '5em' }}
            >
              Reopen Navigation
            </Button>
          </a>
          <p style={{marginBottom: '0'}}>When you've reached your destination, click here to end the route and</p> <br />
          <Button
            variant='contained'
            color='secondary'
            onClick={postCheckHandler}
            sx={{marginTop: '0'}}
          >
            Begin Postcheck
          </Button>
        </>
      )}
    </div>
  );
};

export default InProgress;
